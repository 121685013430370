import dayjs from "dayjs";

export const buildPayload = ({ activeTab, filters }) => {
  const {
    title,
    from_date,
    location,
    experience_level,
    workplace_type,
    work_schedule,
  } = filters;
  if (activeTab !== "myJobs") return {};

  return {
    ...(title && { title }),
    ...(location && { location }),
    ...(from_date && { from_date }),
    ...(experience_level && { experience_level }),
    ...(workplace_type && { workplace_type }),
    ...(work_schedule && { work_schedule }),
  };
};

export const formatTimeAgo = (date) => {
  const daysAgo = dayjs().diff(dayjs(date), "day");
  const hoursAgo = dayjs().diff(dayjs(date), "hour");

  if (daysAgo > 0) {
    return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
  }
  if (hoursAgo > 0) {
    return `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
  }
  return "Just now";
};

export const validateSelectValue = (value, options) => {
  console.log(value, options,'codebyyahsanahsan')
  const valueFound = options.find((option) => option.value === value)?.value
  console.log('valueFound',valueFound)
  return valueFound;
};

export const workplaceOptions = [
  { value: "remote", label: "Remote" },
  { value: "hybrid", label: "HYBRID" },
  { value: "onsite", label: "On-site" },
];

export const experienceOptions = [
  { value: "beginner", label: "Entry Level" },
  { value: "intermediate", label: "Intermediate" },
  { value: "expert", label: "Senior" },
  { value: "director", label: "Director" },
  { value: "internship", label: "Internship" },
];

export const fromDateOtions = [
  { value: "any_time", label: "Any Time" },
  { value: "past_month", label: "Past Month" },
  { value: "past_week", label: "Past Week" },
  { value: "past_3_days", label: "Past 3 days" },
  { value: "past_24_hours", label: "Past 24 hours" },
];

const workScheduleArr = [
  {
    label: "Full Time",
    value: "full-time",
  },
  {
    label: "Part Time",
    value: "part-time",
  },
  {
    label: "Contract",
    value: "contract",
  },
  {
    label: "Freelance",
    value: "freelance",
  },
];

export const createSearchPayload = (formData, paramsObjects) => {
  console.log(paramsObjects, "paramsObjects");

  const matchedExperienceLevel = experienceOptions.find(
    (option) => option.value === paramsObjects?.experience_level
  );
  const matchedFromDate = fromDateOtions.find(
    (option) => option.value === paramsObjects?.from_date
  );
  const matchedWorkplaceType = workplaceOptions.find(
    (option) => option.value === paramsObjects?.workplace_type
  );
  const matchedWorkSchedule = workScheduleArr.find(
    (option) => option.value === paramsObjects?.work_schedule
  );

  const {
    experience_level,
    workplace_type,
    from_date,
    work_schedule,
    ...restParams
  } = paramsObjects;
  const payload = {
    ...restParams,
    ...(matchedExperienceLevel && {
      experience_level: matchedExperienceLevel.value,
    }),
    ...(matchedWorkplaceType && { workplace_type: matchedWorkplaceType.value }),
    ...(matchedFromDate && { from_date: matchedFromDate.value }),
    ...(matchedWorkSchedule && { work_schedule: matchedWorkSchedule.value }),
  };

  console.log(payload, "payloabyahsand");
  const requiredFields = [
    "title",
    "from_date",
    "workplace_type",
    "location",
    "experience_level",
    "work_schedule",
  ];

  return requiredFields.reduce((acc, field) => {
    if (payload?.[field] !== undefined) {
      acc[field] = payload[field];
    } else if (formData?.[field] !== undefined) {
      acc[field] = formData[field];
    }
    return acc;
  }, {});
};

export const formatJobDescription = (description) => {
  return description
    .replace(/\*\*(.*?)\*\*/g, "<br /><br /><strong>$1</strong>")
    .replace(/:(?=\S)/g, ":<br />")
    .replace(/^-\s(.*)/gm, "<li>• $1</li>")
    .replace(/(<li>.*<\/li>)/gm, "<ul>$1</ul>")
    .replace(/^(<br \/>)+/, "")
    .replace(/<\/li>(<br \/>)+<\/ul>/g, "</li></ul>");
};
