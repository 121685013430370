import React, { memo } from "react";
import { Tag } from "antd";
import { MdCancel } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setPreference,
  updatePreferenceforJobs,
} from "../../../../Redux/UserProfileSlice";

const PreferenceTag = memo(({ label, value, onClick }) => (
  <Tag
    className="relative inline-flex items-center gap-2 w-full sm:w-fit"
    color="processing"
  >
    <span className="truncate">
      {label}: {value}
    </span>
    {onClick && (
      <MdCancel
        color="red"
        size={18}
        className="absolute -top-2 -right-2 cursor-pointer bg-white rounded-full hover:opacity-80 transition-opacity"
        onClick={onClick}
      />
    )}
  </Tag>
));

PreferenceTag.displayName = "PreferenceTag";

const PREFERENCE_FIELDS = {
  location: "Location",
  workplace_type: "Workplace Type",
  work_schedule: "Work Schedule",
};

const IQPreference = ({ isOpen, setIsLoading }) => {
  const dispatch = useDispatch();
  const { jobsPreference } = useSelector((state) => state.UserProfile);
  if (!jobsPreference?.title || Object.keys(jobsPreference).length === 0) {
    return null;
  }

  const handleChangePreference = (name) => {
    const { id, customer_id, ...payload } = jobsPreference;

    payload[name] = "";
    if (name === "location") {
      payload.location_type = "";
    }
    setIsLoading((prev) => ({ ...prev, isDefaultLoading: true }));
    dispatch(
      updatePreferenceforJobs({
        id: jobsPreference?.id,
        payload,
        onSuccess: (data) => {
          data && dispatch(setPreference(payload));
          setIsLoading((prev) => ({ ...prev, isDefaultLoading: false }));
        },
        onError: () => {
          setIsLoading((prev) => ({ ...prev, isDefaultLoading: false }));
        },
      })
    );
  };

  return (
    <div className="flex flex-col nexaIqTab-container bg-transparent border rounded-lg">
      <div className="flex items-center justify-between border-b px-4 py-3">
        <h2 className="text-xl font-bold">
          Job Preference
          <button
            onClick={isOpen}
            className="ml-2 text-xs cursor-pointer font-medium text-primary hover:underline"
          >
            (manage)
          </button>
        </h2>
      </div>

      <div className="flex flex-wrap gap-2 capitalize p-4">
        <PreferenceTag label="Title" value={jobsPreference.title} />

        {Object.entries(PREFERENCE_FIELDS).map(
          ([key, label]) =>
            jobsPreference[key] && (
              <PreferenceTag
                key={key}
                label={label}
                value={jobsPreference[key]}
                onClick={() => handleChangePreference(key)}
              />
            )
        )}
      </div>
    </div>
  );
};

export default memo(IQPreference);
