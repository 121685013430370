import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import backIcon from "../../assets/icons/backIcon.svg";
import Header from "../../Components/header";
import octoStack from "../../DummyData/octoStack.json";
import { OctaCatCards, OctagonCards } from "./octagonCards";
import QuotaExceeded from "../../Components/quotaExceeded";

const Octagon = () => {
  const navigate = useNavigate();
  const { category, subcategory } = useParams();
  const location = useLocation();
  const { user } = useSelector((state) => state.authenticate);
  const [showIntro, setShowIntro] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(true);
  const [projects, setProjects] = useState([]);

  const cards = [
    {
      label: "Software Projects",
      delay: 300,
      image: "/images/icon.png",
      link: "/codingdojo",
    },
    {
      label: "Sales Simulations",
      delay: 500,
      image: "/images/icon.png",
      link: "/salesoptions",
    },
    {
      label: "Customer Hospitality",
      delay: 700,
      image: "/images/icon.png",
      link: "/hospitality",
    },
    {
      label: "Management & Communications",
      delay: 900,
      image: "/images/icon.png",
      link: "/management",
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setShowIntro(queryParams.get("intro") !== "false");
  }, [location.search]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    if (!category && !subcategory) {
      setShowIntro(true);
    }
  }, [category, subcategory]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem("auth");
        if (!token) return console.log("No auth token found");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/octagon/projects`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status_code === 200) {
          setProjects(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const transformProjectIdForDisplay = (projectId) => {
    return projectId
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="h-screen flex flex-col pb-20 md:pb-0">
      <Header />
      <div className="flex-1 overflow-y-scroll pb-2">
        <div className="ic-interviews voyce py-0 h-full">
          <div className="container pt-32">
            <h1 className="text-center font-extrabold text-3xl mb-10">
              Enter Nexa's Octagon & Face Real-World Scenarios
            </h1>
            <br />

            {showIntro && (
              <div className="leading-relaxed transition-all duration-300 ease-in-out transform hover:scale-105">
                <Heading className="text-center mx-auto text-gray-800">
                  Nexa's Octagon allows you to gain experience in a specific
                  role, making you stand out as a better candidate. Our engine
                  uses AI to simulate hundreds of on-the-job scenarios based on
                  specific companies.
                </Heading>
                <Heading className="text-center mx-auto text-gray-800 mt-8">
                  Let's get to work!
                </Heading>
              </div>
            )}

            {!category && !subcategory && (
              <div>
                <div className="ic-interview-card-container">
                  {cards.map((card, index) => (
                    <OctagonCards
                      index={index}
                      key={index}
                      card={card}
                      onCardClick={() => {
                        setShowIntro(false);
                        navigate(`/octagon${card.link}`, { replace: true });
                      }}
                    />
                  ))}
                </div>
              </div>
            )}

            {category && !subcategory && (
              <div>
                <div className="mt-6 flex justify-between items-center">
                  <button
                    onClick={() => {
                      setShowIntro(true);
                      navigate("/octagon", { replace: true });
                    }}
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    <img src={backIcon} alt="Back" />
                  </button>
                  <select
                    onChange={(e) => {
                      const path = e.target.value?.replace(/-/g, "/");
                      if (path)
                        navigate(`/intervieworcustom/codingdojo/${path}`);
                    }}
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    <option value="" style={{ color: "gray" }}>
                      Select Saved Project
                    </option>
                    {projects.map((project) => (
                      <option
                        key={project.id}
                        value={project.project_id}
                        style={{ color: "black" }}
                      >
                        {transformProjectIdForDisplay(project.project_id)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="ic-interview-card-container">
                  {octoStack[category].map((card, index) => (
                    <OctaCatCards
                      index={index}
                      key={index}
                      card={card}
                      onCardClick={() => {
                        setShowIntro(false);
                        navigate(`/octagon/${category}${card.link}`, {
                          replace: true,
                        });
                      }}
                    />
                  ))}
                </div>
              </div>
            )}

            {category && subcategory && (
              <div>
                <div className="flex justify-start">
                  <button
                    onClick={() => {
                      setShowIntro(false);
                      navigate(`/octagon/${category}`, { replace: true });
                    }}
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    <img src={backIcon} alt="Back" />
                  </button>
                </div>

                <div className="ic-interview-card-container flex flex-wrap justify-center">
                  {octoStack["techstacks"].map((card, index) => (
                    <OctagonCards
                      index={index}
                      key={index}
                      card={card}
                      onCardClick={() => {
                        setShowIntro(false);
                        navigate(
                          `/intervieworcustom/${category}/${subcategory}${card.link}`
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {isQuoteModalOpen && ( 
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2 className="text-2xl font-bold text-gray-800 text-center mb-4">
              Enhance Your Experience
            </h2>
            <p className="text-black-600 text-center mb-6">
              You are currently using a basic version of our simulator that has
              delays. You can upgrade for our <b>REALTIME</b> simulator
              experience.
            </p>
            <div className="flex flex-col gap-3">
              <button
                onClick={() => navigate("/pricing")}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg font-semibold transition-all duration-200"
              >
                Upgrade
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="w-full bg-gray-100 hover:bg-gray-200 text-gray-800 px-6 py-3 rounded-lg font-semibold transition-all duration-200"
              >
                Continue Anyway
              </button>
            </div>
          </div>
        </div>
      )} */}
      {isQuoteModalOpen && (
        <QuotaExceeded
          isQuoteModalOpen={isQuoteModalOpen}
          setIsQuoteModalOpen={setIsQuoteModalOpen}
        />
      )}
    </div>
  );
};

export default Octagon;

const Heading = styled.p`
  font-size: 22px;
  font-family: "Libre Baskerville", serif;
  max-width: 720px;
  letter-spacing: 0.3px;
  color: #3a3a3a;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
`;
