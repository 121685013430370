import React from "react";
import ReusableSection from "./ReusableSection";
import "./Section6.css";

const sectionData = [
  {
    imageUrl: "images/Bootcamp/BootcampSection61.png",
    text1: "ATS Magnet Resume Hub",
    text2: "Build ATS-compliant resumes with AI-driven feedback for optimization.",
    imagePosition: "right",
  },
  {
    imageUrl: "images/Bootcamp/BootcampSection62.png",
    text1: "Interview Practice and Simulations",
    text2: "Lifelike AI-driven interview practice with actionable feedback.",
    imagePosition: "left",
  },
  {
    imageUrl: "images/Bootcamp/BootcampSection63.png",
    text1: "Nexa Octagon Job Simulations:",
    text2: "Students gain real-world experience by solving company-specific scenarios.",
    imagePosition: "right",
  },
  {
    imageUrl: "images/Bootcamp/BootcampSection64.png",
    text1: "AI Career Advisor (Nexa Voyce):",
    text2: "Personalized career guidance based on skill assessments and goals.",
    imagePosition: "left",
  },
  {
    imageUrl: "images/Bootcamp/BootcampSection65.png",
    text1: "Nexa IQ and Autopilot",
    text2: "Intelligent job search and automated applications to maximize opportunities.",
    imagePosition: "right",
  },
  {
    imageUrl: "images/Bootcamp/BootcampSection66.png",
    text1: "Employer Integration via Talent Hub:",
    text2: "Direct connections with hiring companies for immediate visibility.",
    imagePosition: "left",
  },
];

const Section6 = () => {
  return (
    <div className="outermost-container-section6">
      <div className="default-container-section6">
        <div className="text-wrapper-section6">
          <div className="narrow-text-section6">
            <p className="text-default-blue-bold-section6 text-center mb-8 FiraFontFamily bold-family">
              Key{" "}
              <span className="text-default-blue-bold-section6 highlighted-word FiraFontFamily bold-family">
                Features
              </span>{" "}
              for Bootcamps & Vocational Centers
            </p>
          </div>
          <div className="wide-text-section6">
            <p className="text-default-black-section6 text-center FiraFontFamily">
              Nexa is purpose-built to simplify and enhance career services with:
            </p>
          </div>
        </div>
      </div>
      <div className="reusable-container-section6">
        {sectionData.map((section, index) => (
          <ReusableSection
            key={index} // Unique key for each component
            text1={section.text1}
            text2={section.text2}
            imageUrl={section.imageUrl}
            imagePosition={section.imagePosition}
            extraClass={index % 2 === 0 ? "section-container-even" : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default Section6;
