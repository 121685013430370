import React from "react";

const IQSidebarShimmer = () => {
  return (
    <div className="py-6 border flex h-[100vh] overflow-y-scroll justify-center">
      <div className="w-[65%] lg:w-[65%] xl:w-[70%] h-[100%]  flex flex-col">
        <div className="animate-pulse space-y-6 p-6">
          {/* Header Section */}
          <div className="h-10 bg-gray-300 rounded w-1/3"></div>

          {/* Subheader Section */}
          <div className="h-6 bg-gray-300 rounded w-2/4"></div>

          {/* Main Content Section */}
          <div className="space-y-4">
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
            <div className="h-8 bg-gray-300 rounded w-full" />
          </div>
        </div>
      </div>
      <div className="w-[35%] lg:w-[35%] xl:w-[30%] h-[100%] flex flex-col px-3">
        <div className="flex justify-center w-full flex-col gap-3">
          <div className="h-10 bg-gray-300 rounded w-3/4"></div>
          <div className="h-10 bg-gray-300 rounded w-3/4"></div>
          <div className="h-10 bg-gray-300 rounded w-3/4"></div>
          {/* About the Company Section */}
          <div className="space-y-2 mt-2">
            <div className="h-6 bg-gray-300 rounded w-1/4"></div>
            <div className="h-4 bg-gray-300 rounded w-3/5"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IQSidebarShimmer;
