import { useEffect, useMemo, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space, Spin } from "antd";
import debounce from "lodash/debounce";
import { error } from "../../Utilities/toast";
import { useDispatch } from "react-redux";
import { addNewJobTitle, deleteMyJobRole } from "../../Redux/NexaIqSlice";
import { TiDeleteOutline } from "react-icons/ti";

let index = 0;
const AdditionSelect = ({
  data,
  fetchOptions,
  disabled,
  currentPage,
  totalPages,
  placeholder,
  placeholderAdd,
  debounceTimeout = 1500,
  value,
  onChange,
  setIsLoading,
  ...props
}) => {
  console.log(value, "valuebyahsan");
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [items, setItems] = useState(data);
  const [searchValue, setSearchValue] = useState("");
  const [searched, setSearched] = useState(false);
  const [defaultOptionsChanged, setDefaultOptionsChanged] = useState(false);
  const [name, setName] = useState({});
  const [isAddJobRoleLoading, setIsAddJobRoleLoading] = useState(false);
  const [loader, setLoader] = useState({
    data: false,
    btn: false,
    jobRole: false,
  });

  const fetchData = (value, page) => {
    setLoader((prev) => ({ ...prev, data: true }));
    fetchOptions(value, page).then(() => {
      setLoader((prev) => ({ ...prev, data: false }));
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      if (value === "" && defaultOptionsChanged) {
        fetchData("", 1);
        setSearchValue("");
        setSearched(false);
        setDefaultOptionsChanged(false);
        return;
      }
      if (!value?.trim()) {
        return;
      }
      const isValuePresent = data?.some((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      );
      if (isValuePresent) {
        return;
      }
      setSearchValue(value);
      fetchData(value, 1);
      setSearched(true);
      setDefaultOptionsChanged(true);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const handleClear = () => {
    if (defaultOptionsChanged) {
      fetchData("", 1);
      setDefaultOptionsChanged(false);
    }
    setSearchValue("");
    setSearched(false);
  };

  const handleLoadmore = () => {
    const page = currentPage + 1;
    const value = searchValue ? searchValue : "";
    setLoader((prev) => ({ ...prev, btn: true }));
    fetchOptions(value, page).then(() => {
      setLoader((prev) => ({ ...prev, btn: false }));
    });
  };

  const onNameChange = (event) => {
    const value = event.target.value;
    setName({ value: value, label: value });
  };

  const addItem = (e) => {
    e.preventDefault();
    if (!name?.value?.trim()) return;
    const isDuplicate = items?.find(
      (item) =>
        item?.label?.trim().toLowerCase() === name?.value?.trim().toLowerCase()
    );
    if (isDuplicate) {
      error("Already Exists");
      return;
    }
    setLoader((prev) => ({ ...prev, jobRole: true }));
    dispatch(
      addNewJobTitle({
        name,
        onSuccess: () => {
          fetchData("", 1);
          setName("");
          setLoader((prev) => ({ ...prev, jobRole: false }));
        },
        onError: () => {
          setLoader((prev) => ({ ...prev, jobRole: false }));
        },
      })
    );
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleDelete = (id, label) => {
    if (value?.split("#")[0]?.trim() === label) {
      return error("Selected job role cannot be deleted")
    }
    setIsLoading((prev) => ({ ...prev, isDefaultLoading: true }));
    dispatch(
      deleteMyJobRole({
        id,
        onSuccess: () => {
          setIsLoading((prev) => ({ ...prev, isDefaultLoading: false }));
          fetchData("", 1);
        },
        onError: () => {
          setIsLoading((prev) => ({ ...prev, isDefaultLoading: false }));
        },
      })
    );
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleBlur = () => {
    if (searched) {
      fetchData("", 1);
      setSearched(false);
    }
  };

  useEffect(() => {
    fetchData("", 1);
  }, []);

  return (
    <Select
      showSearch
      filterOption={true}
      disabled={disabled}
      onSearch={debounceFetcher}
      onBlur={handleBlur}
      allowClear
      onClear={handleClear}
      value={value}
      onChange={onChange}
      notFoundContent={
        loader.data ? (
          <p className="flex text-xs justify-center w-full">Loading...</p>
        ) : (
          <p className="flex text-xs justify-center w-full">no results found</p>
        )
      }
      {...props}
      dropdownRender={(menu) => (
        <>
          <Space className="flex items-center w-full py-1 px-2">
            <Input
              placeholder={placeholderAdd}
              className="w-full"
              ref={inputRef}
              value={name.value}
              onChange={onNameChange}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Button
              type="text"
              disabled={!name?.value?.trim() || loader.jobRole}
              icon={<PlusOutlined />}
              onClick={addItem}
              className={`flex items-center justify-center h-full w-full ${
                !isAddJobRoleLoading
                  ? "bg-primary cursor-pointer"
                  : "bg-[#1e508d] cursor-default"
              } hover:bg-[#1e508d] text-white text-center font-bold`}
            >
              Add
              {loader.jobRole && <Spin size="small" className="ml-2" />}
            </Button>
          </Space>
          <Divider className="my-2" />
          {menu}
        </>
      )}
    >
      {items?.length > 0 && (
        <Select.Option value="">{placeholder}</Select.Option>
      )}
      {items?.length > 0 &&
        items?.map((option, ind) => (
          <Select.Option
            className="capitalize flex justify-between items-center w-full"
            key={ind}
            value={option.value}
          >
            {option.label}
            {option?.user_id && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(option?.id, option?.label);
                }}
              >
                <TiDeleteOutline color="red" size={18} />
              </button>
            )}
          </Select.Option>
        ))}

      {currentPage < totalPages && (
        <Select.Option
          value="load_more"
          disabled
          className="p-0 m-0 bg-red-500"
        >
          <div
            className={`flex items-center justify-center h-full w-full ${
              !loader.btn
                ? "bg-primary cursor-pointer"
                : "bg-[#1e508d] cursor-default"
            } hover:bg-[#1e508d] text-white text-center font-bold`}
            onClick={!loader.btn ? handleLoadmore : null}
          >
            Load More {loader.btn && <Spin size="small" className="ml-2" />}
          </div>
        </Select.Option>
      )}
    </Select>
  );
};
export default AdditionSelect;
