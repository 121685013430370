import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error } from "../Utilities/toast";

const customerRepo = RepositoryFactory.get("customer");

// Async actions
export const getThemeColors = createAsyncThunk(
  "customer/get_theming",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await customerRepo.getTheming();
      return data;
    } catch (err) {
      error(err.response);
      return rejectWithValue(err.message);
    }
  }
);
const initialState = {
  interViewMode: null,
  previousInterviewMode: null,
  interviewSession: false,
  techStack: null,
  interviewCompleted: null,
  sidebar_colorBg: "#ffffff",
  sidebar_colorBtn: "#007bff",
  file_url: "",
  tasks: [],
};

const globalStatesSlice = createSlice({
  name: "globalStates",
  initialState,
  reducers: {
    setinterviewMode: (state, action) => {
      state.interViewMode = action.payload;
    },
    setPreviousMode: (state, action) => {
      state.previousInterviewMode = action.payload;
    },
    setInterviewSession(state, action) {
      state.interviewSession = action.payload;
    },
    setTechStack: (state, action) => {
      state.techStack = action.payload;
    },
    setInterviewCompleted: (state, action) => {
      state.interviewCompleted = action.payload;
    },
    addTask: (state, action) => {
      if (!Array.isArray(state.tasks)) {
        // Ensure it's always an array
        console.error("Tasks array is undefined");
        state.tasks = []; // Initialize tasks as an empty array if undefined
      }
      const { id, title, status, createdAt } = action.payload;
      state.tasks.push({ id, title, status, createdAt });
    },
    updateTask: (state, action) => {
      const index = state.tasks.findIndex(
        (task) => task.id === action.payload.id
      );
      if (index !== -1) {
        state.tasks[index] = {
          ...state.tasks[index],
          ...action.payload,
        };
      }
    },
    deleteTask: (state, action) => {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getThemeColors.fulfilled, (state, action) => {
      state.sidebar_colorBg = action.payload.sidebar_type;
      state.sidebar_colorBtn = action.payload.sidebar_color;
      state.file_url = action.payload.file_url;
    });
  },
});

export const {
  setinterviewMode,
  setPreviousMode,
  setInterviewSession,
  setTechStack,
  setInterviewCompleted,
  addTask,
  updateTask,
  deleteTask,
} = globalStatesSlice.actions;
export default globalStatesSlice.reducer;
