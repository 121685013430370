import React from "react";
import { useNavigate } from "react-router-dom";
import "./Section8.css";

const Section8 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us");
  };
  return (
    <div className="section8-box">
      {/* Left Side: Text */}
      <div className="section8-left">
        <div className="text-wrapper-section8">
          <div className="narrow-text-section8">
            <p className="text-default-blue-bold-section8 mt-4">
              Supercharge Your Career Services Today!
            </p>
          </div>
          <div className="wide-text-section8">
            <p className="text-default-black-section8">
              Join the hundreds of bootcamps and vocational centers using Nexa
              to transform career readiness and job placement.
            </p>
          </div>

          <button onClick={handleClick} className="try-nexa-button-section8">
            Start Your Free Organizational Trial
          </button>
        </div>
      </div>

      {/* Right Side: Image */}
      <div className="section8-right">
        <img
          src="images/Bootcamp/BootCampSection8.png"
          alt="Boot Camp Section 8"
          className="section8-image"
        />
      </div>
    </div>
  );
};

export default Section8;
