import axios from "axios";
import React, { useEffect, useState } from "react";
import CircularLoader from "../loader";
import Modal from "../modal"; // Assuming Modal is a custom component
import { LoaderOverlay } from "./index.style";

const ItemLoading = ({ Endingpg, Question, Answer }) => {
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState(""); // Feedback message
  const [modalIsOpen, setIsOpen] = useState(false);
  const [endScreen, setEndScreen] = useState(false);
  const [interviewTimer, setInterviewTimer] = useState(false);
  const [storeResultScore, setStoreResultScore] = useState(0);

  // Expected correct answer
  const correctAnswer = "[0,1]"; // Adjust this based on your question

  const handleFeedbackSubmissionApi = async () => {
    try {
      const prompt1 = `
        Evaluate the given code answer and return only a numeric grade (e.g., 1, 2, etc.) out of 10. Do not include any extra text, explanation, or words. If the answer is empty or invalid, return 0. Question: ${Question.description}. Answer: ${Answer}
        `;
      const response1 = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo", // or use 'gpt-3.5-turbo'
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: prompt1 },
          ],
          max_tokens: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );
      const prompt2 = `
        Evaluate the given code answer based on the question and provide feedback in two lines maximum. If the answer is empty or invalid, respond with "Please provide an answer to receive feedback." Question: ${Question.description}. Answer: ${Answer}
        `;

      const response2 = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo", // or use 'gpt-3.5-turbo'
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: prompt2 },
          ],
          max_tokens: 100,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response1.data.choices[0].message.content) {
        setStoreResultScore(response1.data.choices[0].message.content);
      }
      if (response2.data.choices[0].message.content) {
        setFeedback(response2.data.choices[0].message.content);
      }
    } catch (error) {
      console.log("ANSWER ERROR------------------", error);
      setStoreResultScore(0);
      setFeedback("Incorrect");
    }
  };

  const init = async () => {
    await handleFeedbackSubmissionApi();
  };

  useEffect(() => {
    init();
    const timer = setTimeout(() => {
      setLoading(false);
      setIsOpen(true);
    }, 6000); // Display loader for 2 seconds

    return () => clearTimeout(timer); // Clean up the timer
  }, [Answer]);

  if (loading) {
    return (
      <LoaderOverlay>
        <CircularLoader
          title="Nexa is hard at work to analyze your results, give us a minute"
          icon={true}
        />
      </LoaderOverlay>
    );
  }

  const header = (
    <div className="mockInterview-modal-header">
      <button
        className="modal-close"
        onClick={() => {
          setFeedback("");
          setIsOpen(false);
          Endingpg(true);
          setEndScreen(true);
          setInterviewTimer(false);
          localStorage.removeItem("dev_questions");
        }}
      >
        <i className="ri-close-line"></i>
      </button>
    </div>
  );

  const content = (
    <div className="row">
      <div className="col-12 order-md-1 order-2">
        <div className="cardBody">
          <h5 className="mx-3 my-3 ">Question: {Question.id}</h5>
          <hr />
          <p className="mx-3 my-3">{Question.description}</p>
          <p className="mx-3 my-3">
            {Question.examples.map((example, index) => (
              <div key={index}>
                Example {index + 1}: <br />
                Input: {example.input} <br />
                Output: {example.output} <br />
                Explanation: {example.explanation}
              </div>
            ))}
          </p>
          <hr />
          <h5 className="mx-3 my-3 text-primary">Answer:</h5>
          <hr />
          <h6 className="mx-3 my-3 text-primary">{Answer}</h6>
          <hr />
          <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
          <p className="mx-3 my-3">{feedback}</p>
        </div>
      </div>
    </div>
  );

  const footer = (
    <div className="footer_score d-flex">
      <h5 className="my-0">Nexa Grading Score..:</h5>
      <span className="score w-[25px] h-[25px]">{storeResultScore}</span>
    </div>
  );

  return (
    <Modal
      type="feedback"
      show={modalIsOpen}
      header={header}
      content={content}
      footer={footer}
    />
  );
};

export default ItemLoading;
