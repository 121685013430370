import React from "react";

const HeroSection = () => {
  return (
    <div className="aboutUs__hero-section-container">
      <div className="container">
        <div className="aboutUs__hero-section-content">
          <h1 className="aboutUs__hero-section-heading section-heading">
            Our Mission
          </h1>
          <p className="aboutUs__hero-section-description">
            At Nexa, we believe every individual deserves a fair and transparent
            path to success. Our mission is to democratize career preparation,
            making it accessible, effective, and deeply personalized. We are
            unwavering in our commitment to creating a 100% transparent job
            search ecosystem—where every candidate, whether excelling or needing
            growth, receives clear and honest feedback to learn, adapt, and
            thrive. By fostering clarity and accountability, we aim to make the
            world more productive, honest, and empowering for job seekers and
            employers alike.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
