import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { error, success } from "../../../Utilities/toast";
import { useDispatch, useSelector } from "react-redux";
import { AddSkills, deleteSkill } from "../../../Redux/UserProfileSlice";
import { MdDeleteOutline } from "react-icons/md";
import { getCurrentUser } from "../../../Redux/authenticate/authenticate.action";
import CreatableSelect from "react-select/creatable";
const { Option } = Select;

const KeySkillsProficiencyLevels = ({ activeStep, setActiveStep }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const { keySkills, keySkillsLoading } = useSelector(
    (state) => state.UserProfile
  );
  const [initialUserProfileInfo, setInitialUserProfileInfo] = useState([]);
  const [skills, setSkills] = useState([
    { skill_name: "", proficiency_level: null, id: null },
  ]);

  const handleSkillChange = (index, value) => {
    const newSkills = [...skills];
    newSkills[index].skill_name = value;
    setSkills(newSkills);
  };

  const handleLevelChange = (index, value) => {
    const newSkills = [...skills];
    newSkills[index].proficiency_level = value;
    setSkills(newSkills);
  };

  const handleAddNewSkillField = () => {
    setSkills([
      ...skills,
      { skill_name: "", proficiency_level: null, id: null },
    ]);
  };

  const handleDeleteSkill = (index, obj) => {
    const selectedData = skills.find((item) => item == obj);
    if (selectedData?.id) {
      dispatch(
        deleteSkill({
          id: selectedData?.id,
          onSuccess: () => {
            success("Skill deleted successfully");
            dispatch(getCurrentUser());
          },
        })
      );
    } else {
      const newSkills = [...skills];
      newSkills.splice(index, 1);
      setSkills(newSkills);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hasChanged =
      JSON.stringify(skills) !== JSON.stringify(initialUserProfileInfo);
    if (hasChanged) {
      if (skills.some((skillObj) => skillObj.proficiency_level == null)) {
        return error("Please enter your skill level");
      }

      const formattedSkills = skills?.map((skill) => ({
        ...skill,
        skill_name: skill.skill_name?.value || null,
      }));

      const formatedData = {
        key_skills: formattedSkills,
      };

      dispatch(
        AddSkills({
          skills: formatedData,
          onSuccess: () => {
            success("Skills added successfully");
            setActiveStep((prev) => prev + 1);
            dispatch(getCurrentUser());
          },
        })
      );
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (user) {
      if (user?.skills?.length > 0) {
        setSkills(
          user?.skills?.map((skill) => ({
            skill_name: { value: skill?.skill_name, label: skill?.skill_name },
            proficiency_level: skill?.proficiency_level,
            id: skill.id,
          }))
        );
      } else {
        setSkills([{ skill_name: "", proficiency_level: null, id: null }]);
      }

      setInitialUserProfileInfo(
        user?.skills?.length > 0
          ? user.skills.map((skill) => ({
              skill_name: {
                value: skill?.skill_name,
                label: skill?.skill_name,
              },
              proficiency_level: skill?.proficiency_level,
              id: skill.id,
            }))
          : [{ skill_name: "", proficiency_level: null, id: null }]
      );
    }
  }, [user]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="profile-form_cover">
          <div className="profile-form">
            {skills?.map((skillObj, index) => (
              <div
                key={index}
                className="w-full flex justify-center items-center align-middle gap-3 mb-1"
              >
                <CreatableSelect
                  className="basic-single w-full mt-3"
                  classNamePrefix="select"
                  defaultValue={"Enter your skill"}
                  isClearable
                  isSearchable
                  name="color"
                  options={keySkills}
                  isLoading={keySkillsLoading}
                  menuPortalTarget={document.body}
                  value={skillObj.skill_name}
                  onChange={(e) => handleSkillChange(index, e)}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                <Select
                  placeholder="Select level"
                  value={skillObj.proficiency_level}
                  onChange={(value) => handleLevelChange(index, value)}
                  className="h-[46px] mt-3 w-full"
                  optionFilterProp="children"
                  showSearch
                  required
                >
                  <Option value="">Select level</Option>
                  <Option value="beginner">Beginner</Option>
                  <Option value="intermediate">Intermediate</Option>
                  <Option value="expert">Expert</Option>
                </Select>
                {skills?.length > 1 && (
                  <MdDeleteOutline
                    className="text-5xl text-red-500 mt-3 cursor-pointer"
                    onClick={() => handleDeleteSkill(index, skillObj)}
                  />
                )}
              </div>
            ))}
            <div className="w-full justify-end flex mb-3">
              <button
                type="button"
                onClick={handleAddNewSkillField}
                className="flex justify-center items-center bg-blue-600 text-white py-2 px-2 rounded-full font-bold text-sm w-28 h-10 transition-colors duration-300"
              >
                <FaPlus className="mr-1 text-white" size={16} />
                Add Skill
              </button>
            </div>
            <div
              className={`w-full flex ${
                activeStep === 1 ? "justify-end" : "justify-between"
              }`}
            >
              <button
                className="ic-btn"
                onClick={() => {
                  setActiveStep((prev) => prev - 1);
                }}
              >
                Previous
              </button>
              <button className="ic-btn" type="submit">
                Save & Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default KeySkillsProficiencyLevels;
