import React, { useEffect, useState } from "react";
import "../../Css/dashboard.css";
import "./layout.css";
import SidebarProfile from "../dashboardComponents/layout/userSidebar";
import { SidebarProvider } from "../../context/sidebarContext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuote } from "../../Redux/authenticate/authenticate.action";
import QuotaWarningBar from "./QuotaWarningBar";

const UserLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const [dayOfTheMessage, setDayOfTheMessage] = useState("");
  const navigate = useNavigate();

  if (!user) {
    console.log("==!!!!!=going");
    navigate("/");
  }

  useEffect(() => {
    dispatch(
      getQuote({
        onSuccess: (res) => {
          setDayOfTheMessage(res?.quote);
        },
      })
    );
  }, []);

  return (
    <div className="relative">
      <QuotaWarningBar />
      <SidebarProvider>
        <div
          className="app bg-"
          style={{
            backgroundImage: `url(${"/images/interview-banner.png"})`,
          }}
        >
          <SidebarProfile />
          <div className="main-content p-0  bg-transparent">
            <h2 className="relative top-[5.3rem] left-0 right-0 text-center px-10 leading-6 pt-8">
              {dayOfTheMessage}
            </h2>
            {children}
          </div>
        </div>
      </SidebarProvider>
      {/* <Footer /> */}
    </div>
  );
};

export default UserLayout;
