import React, { useCallback } from "react";
import { formatTimeAgo } from "../IQContext";
import { getSingleJob } from "../../../../Redux/NexaIqSlice";
import { useDispatch } from "react-redux";

export const JobHeader = ({
  id,
  title,
  organizationName,
  location,
  createdAt,
  openSidebar,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const truncateLocation = (loc) =>
    loc.length > 40 ? `${loc.substring(0, 40)}...` : loc;
  const handleClick = useCallback(() => {
    openSidebar(id);
    setIsLoading((prev) => ({ ...prev, isSidebarLoading: true }));
    dispatch(
      getSingleJob({
        id,
        onSuccess: () => {
          setIsLoading((prev) => ({ ...prev, isSidebarLoading: false }));
        },
        onError: () => {
          setIsLoading((prev) => ({ ...prev, isSidebarLoading: false }));
        },
      })
    );
  }, [id, dispatch]);
  return (
    <div className="w-auto" onClick={handleClick}>
      <h3 className="flex flex-row items-center justify-between">
        <div className="flex w-full flex-row items-center justify-between text-base font-semibold sm:justify-start sm:text-lg">
          <div className="clamp h-[28px] relative mb-[4px] sm:mb-[8px] cursor-default group-hover:text-[#7593FF] sm:flex sm:flex-row flex w-full sm:items-center">
            <span className="w-full cursor-pointer absolute overflow-hidden text-ellipsis whitespace-nowrap sm:text-xl pr-[40px] sm:pr-0 hover-text">
              {title}
            </span>
          </div>
        </div>
      </h3>
      <div className="flex flex-col sm:flex-row sm:items-center items-left text-sm">
        <div className="flex flex-row items-center">
          {organizationName !== "N/A" && (
            <>
              <div className="list-item__compensation flex min-w-max flex-row items-center font-semibold text-[#4D4D4D]">
                {organizationName}
                <svg
                  width="21"
                  height="20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m15.031 6.666-5.599 5.417-2.916-2.917L5.5 10.182l3.932 3.906 6.51-6.51-.91-.912Z"
                    fill="#1A7F23"
                  ></path>
                </svg>
              </div>
              <span className="ml-[9px] mr-[9px]">•</span>
            </>
          )}
          <div
            title={location}
            className="list-item__location align-center flex"
          >
            {truncateLocation(location)}
          </div>
        </div>
        <div className="bg-white">
          <div className="text-xs text-[#000] flex-row items-center ml-4 flex">
            {formatTimeAgo(createdAt)}
          </div>
        </div>
      </div>
    </div>
  );
};
