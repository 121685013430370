import React from "react";
import LoaderMini from "../main-loader-mini";
function CircularLoader({ title, icon, className }) {
  return (
    <div
      className={`${
        className == "h-full" ? "h-[calc(100vh-224px)]" : "h-screen"
      } absolute z-50 top-30 rounded-lg  flex flex-col items-center justify-center w-full `}
    >
      {icon ? (
        <div className="flex flex-column justify-content-center align-items-center feedback_loader w-full">
          {/* <div className=" transform loaderStyler"></div> */}
          <LoaderMini />
          {/* <i className="ri-ai-generate text-primary"></i> */}
          <div
            className="spinner-border text-primary -mt-2 w-full flex justify-center"
            role="status"
          >
            <div className="w-full flex justify-center items-center px-6">
              <span className="visually-hidden text-2xl leading-9 font-medium">
                {title}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-border text-primary m-3" role="status">
          <span className="visually-hidden">{title}</span>
        </div>
      )}
    </div>
  );
}

export default CircularLoader;
