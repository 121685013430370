import React, { useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import { useRecoilState } from "recoil";
import { StepStateAtom } from "../../../../../recoil/ResumeAdditionalSection/StepsState";

const FromDateField = ({ handleInputChange, index, fieldValue }) => {
  const calendarRef = useRef(null);
  const [stepState, _] = useRecoilState(StepStateAtom);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const toDate = stepState?.selectedTheme?.sectionDetail[index]?.toDate;

  const toggleCalendar = () => setIsCalendarOpen((prev) => !prev);

  const handleDateSelect = (date) => {
    if (!date) return;
    const timeZoneOffset = date.getTimezoneOffset() * 60000;
    const formattedDate = new Date(date - timeZoneOffset)
      .toISOString()
      .split("T")[0];
    handleInputChange({
      target: { name: "fromDate", value: formattedDate },
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <label
        htmlFor="fromDate"
        className="block text-sm font-medium text-gray-700"
      >
        End Date
      </label>
      <input
        type="text"
        name="fromDate"
        id="fromDate"
        placeholder="Select an End date"
        className={`mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer`}
        value={fieldValue || ""}
        onClick={toggleCalendar}
        disabled={!toDate}
        readOnly
      />
      {isCalendarOpen && (
        <div
          ref={calendarRef}
          className="absolute mt-2 z-[999] bg-white border border-gray-300 rounded-md shadow-lg"
        >
          <DayPicker
            mode="single"
            selected={
              fieldValue
                ? new Date(fieldValue.split("/").reverse().join("-"))
                : undefined
            }
            onSelect={handleDateSelect}
            disabled={{
              before: toDate
                ? new Date(toDate.split("/").reverse().join("-"))
                : undefined,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FromDateField;
