import React, { useState } from "react";
import SkillsButton from "./SkillsButton";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import {
  getResumeCategory,
  updateResumeSectionPosition,
} from "../../Redux/ResumeBuilderSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Skill = ({
  skillIndex,
  skills,
  setCategorees,
  Categorees,
  name,
  onDelete,
  onEdit,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(Categorees[0]?.skills);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...Categorees[0]?.skills];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setCategorees([{ ...Categorees, skills: updatedSections }]);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          skills: updatedSections,
        },
        onSuccess: () => {
          dispatch(getResumeCategory({ ID: id, onSuccess: () => {} }));
        },
        onError: () => {
          setCategorees([{ ...Categorees, skills: previousSections }]);
        },
      })
    );
  };
  return (
    <div
      className="skill py-1 h-[37px]"
      onMouseEnter={() => setHoveredIndex(skillIndex)}
      onMouseLeave={() => setHoveredIndex(null)}
      key={skillIndex}
    >
      <p className="skill-name truncate">{name}</p>
      {hoveredIndex === skillIndex && (
        <>
          <button className="relative  group professionalitem-button">
            <FiChevronUp
              className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer ${
                skillIndex == 0 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: skillIndex - 1,
                  source: skillIndex,
                });
              }}
            />
            <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
              Move Up
            </div>
          </button>
          <button className="relative group professionalitem-button">
            <FiChevronDown
              className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer  ${
                skillIndex == skills - 1 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: skillIndex + 1,
                  source: skillIndex,
                });
              }}
            />
            <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
              Move Down
            </div>
          </button>
          <SkillsButton onClick={onDelete}>
            <i
              className="ri-delete-bin-line relative group"
              style={{ fontSize: "18px" }}
            >
              <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                Delete Skill
              </div>
            </i>
          </SkillsButton>
          <SkillsButton onClick={onEdit}>
            <i
              className="ri-edit-line relative group"
              style={{ fontSize: "18px" }}
            >
              <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                Edit Skill
              </div>
            </i>
          </SkillsButton>
        </>
      )}
    </div>
  );
};

export default Skill;
