import DOMPurify from "dompurify";

function createMarkup(dirty) {
  try {
    return { __html: DOMPurify.sanitize(dirty) };
  } catch (error) {
    console.error("Error sanitizing HTML:", error);
    return { __html: "" };
  }
}

export { createMarkup };
