import React from "react";
import { useNavigate } from "react-router-dom";
import "./Section1.css";

const Section1 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us");
  };
  return (
    <div className="default-container background-container">
      <div className="text-container">
        <p className="text-default-blue-bold FiraFontFamily">
          Transform Your Bootcamp or Vocational Center with Nexa’s All-in-One
          Career Platform
        </p>
        <p className="text-default-black FiraFontFamily">
          Empower your learners with cutting-edge tools that boost career
          outcomes while simplifying your operations. Nexa brings everything
          under one roof—driving higher placement rates, improving student
          engagement, and optimizing career center efficiency.
        </p>
        <button
          onClick={handleClick}
          className="start-trial-button FiraFontFamily"
        >
          Start Your Free Trial
        </button>
      </div>
      <div className="zoom-container image-container image-container-media zoom-container">
        <img
          src="/images/Bootcamp/TransformBootCampSection1.png"
          alt="Transform Your Bootcamp"
          className="section-image"
        />
      </div>
    </div>
  );
};

export default Section1;
