import React from "react";
import { ChevronDown } from "lucide-react";

export const ExpandToggle = ({ isExpanded, onToggle }) => (
  <div
    className="w-full h-[40px] border-t-[#E6E6E6] border-t sm:flex flex-row items-center justify-center cursor-pointer"
    onClick={onToggle}
  >
    <div className="flex flex-row items-center justify-center underline text-[#4D4D4D]">
      {isExpanded ? "Hide" : "Show More"}
      <ChevronDown
        className={`ml-1 w-4 h-4 transition-transform ${
          isExpanded ? "rotate-180" : ""
        }`}
      />
    </div>
  </div>
);
