import { Check } from "lucide-react";
import React from "react";
import Footer from "../../Components/footer_new/footer";
import Header from "../../Components/header_new/navbar";

const Rucoming = () => {
  const features = [
    "Smart candidate screening with live interview simulations",
    "AI-driven candidate feedback and evaluation",
    "Seamless ATS integration",
    "Automated hiring process management",
    "Real-time candidate assessment",
    "Custom workflow integrations",
  ];

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 py-12 mt-6">
        <div className="max-w-4xl mx-auto px-4 mt-14">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Nexa RU For Enterprises (Coming Soon)
            </h1>
            <p className="text-xl text-gray-600">
              Transform your Hiring Process
            </p>
          </div>

          <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
            <div className="grid gap-6">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="flex items-center gap-4 p-4 rounded-lg hover:bg-blue-50 transition-colors"
                >
                  <div className="flex-shrink-0">
                    <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center">
                      <Check className="h-5 w-5 text-blue-600" />
                    </div>
                  </div>
                  <p className="text-lg text-gray-700">{feature}</p>
                </div>
              ))}
            </div>

            <a
              href="/contact-us"
              className="mt-10 text-center flex items-center justify-center"
            >
              <button
                className="bg-blue-600 text-white px-8 py-3 rounded-full 
                         text-lg font-medium hover:bg-blue-700 
                         transition-colors shadow-lg hover:shadow-xl"
                onClick={() => console.log("Learn more clicked")}
              >
                Learn More
              </button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Rucoming;
