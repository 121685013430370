import React, { useState, useRef, useEffect } from "react";
import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { success } from "../../../Utilities/toast";
import {
  getResumeSoftSkills,
  updateResumeSectionPosition,
  updateResumeSoftSkills,
} from "../../../Redux/ResumeBuilderSlice";
import { useParams } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function SoftSkills({ softskills, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeSkillIndex, setActiveSkillIndex] = useState(null);
  const [hoveredSkillIndex, setHoveredSkillIndex] = useState(null);
  const [softSkillsState, setSoftSkillsState] = useState([]);
  const editableRefs = useRef({});

  const iconStyle = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const handleFieldClick = (index) => {
    if (activeSkillIndex !== null) submitChanges();
    setActiveSkillIndex(index);
    setTimeout(() => {
      editableRefs.current[index]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[index];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setSoftSkillsState((prev) =>
        prev.map((skill, idx) => (idx === index ? value : skill))
      );
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    } else {
      setSoftSkillsState((prev) =>
        prev.map((skill, idx) => (idx === index ? value : skill))
      );
    }
  };

  const fetchInterests = () => {
    dispatch(getResumeSoftSkills({ resumeId: id, onSuccess: () => {} }));
  };

  const submitChanges = () => {
    const originalSkill = softskills?.skills?.[activeSkillIndex];
    const updatedSkill = softSkillsState[activeSkillIndex];
    const hasChanges =
      originalSkill && originalSkill.trim() !== updatedSkill.trim();

    if (hasChanges) {
      setActiveSkillIndex(null);
      setHoveredSkillIndex(null);
      dispatch(
        updateResumeSoftSkills({
          softskillId: softskills?.id,
          payload: { skills: softSkillsState },
          onSuccess: (res) => {
            success(res);
            fetchInterests();
          },
          onError: () => {
            setSoftSkillsState(softskills?.skills);
          },
        })
      );
    } else {
      setActiveSkillIndex(null);
      setHoveredSkillIndex(null);
    }
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(softSkillsState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...softSkillsState];

    setSoftSkillsState(reorderedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          skill: [{ id: softskills?.id, data: reorderedSections }],
        },
        onSuccess: () => {
          setActiveSkillIndex(null);
          setHoveredSkillIndex(null);
          dispatch(getResumeSoftSkills({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setSoftSkillsState(previousSections);
        },
      })
    );
  };
  const renderSoftSkill = (skill, index) => {
    const isActive = activeSkillIndex === index;
    const isHovered = hoveredSkillIndex === index;
    const originalSkill = softskills?.skills?.[index];
    const hasChanges = originalSkill && originalSkill.trim() !== skill.trim();

    return (
      <span
        className={`editable-field ${isActive || isHovered ? "active" : ""}`}
        onMouseEnter={() => setHoveredSkillIndex(index)}
        onMouseLeave={() => setHoveredSkillIndex(null)}
        onDoubleClick={() => handleFieldClick(index)}
        style={{
          position: "relative",
          border:
            isActive || isHovered ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={isActive}
          className={`editable-field `}
          ref={(el) => (editableRefs.current[index] = el)}
          onInput={(e) => handleFieldInput(index, e)}
          onBlur={submitChanges}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {skill}
        </span>
        {isActive && (
          <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-blue-500 text-white text-xs font-semibold  px-1 rounded-full shadow-md flex items-center gap-2">
            {/* Upper Arrow */}
            <FiChevronUp
              className={`text-white text-lg cursor-pointer ${
                activeSkillIndex == 0 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: activeSkillIndex - 1,
                  source: activeSkillIndex,
                });
              }}
            />

            {/* Lower Arrow */}
            <FiChevronDown
              className={`text-white text-lg cursor-pointer  ${
                activeSkillIndex == softSkillsState.length - 1 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: activeSkillIndex + 1,
                  source: activeSkillIndex,
                });
              }}
            />
          </div>
        )}
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...iconStyle, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && !hasChanges && (
          <MdClose
            onClick={() => setActiveSkillIndex(null)}
            style={{ ...iconStyle, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index)}
            style={{ ...iconStyle, right: "-20px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };

  useEffect(() => {
    if (softskills) setSoftSkillsState(softskills?.skills);
  }, [softskills]);

  return (
    <section className="cv-languages">
      {softskills && softskills.skills?.length > 0 && (
        <ul className="cv-skills-list d-flex justify-content-start">
          {softSkillsState?.map((skill, index) => (
            <li
              className="cv-skill_top apply-font flex gap-1 align-middle mt-1 mb-0"
              key={index}
            >
              <span className="font-bold text-lg">•</span>{" "}
              {renderSoftSkill(skill, index)}
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
