import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../Login/style.css";
import { useDispatch } from "react-redux";
import {
  forgetPassword,
  sendVerificationEmail,
} from "../../Redux/authenticate/authenticate.action";
import { error, success, warning } from "../../Utilities/toast";
import Loader from "../../Components/main-loader";
import EyeIcon from "../../assets/icons/Eye.svg";
import EyeOffIcon from "../../assets/icons/eyeoff.svg";

const ForgotPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const [isPasswordNotVisible, setIsPasswordNotVisible] = useState(false);
  const [isPasswordNotVisibleC, setIsPasswordNotVisibleC] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [authToken, setAuthToken] = useState("");
  const [loading, setLoading] = useState(false);

  const inputType = isPasswordNotVisible ? "text" : "password";
  const inputTypeC = isPasswordNotVisibleC ? "text" : "password";

  const togglePasswordVisibility = () => {
    setIsPasswordNotVisible(!isPasswordNotVisible);
  };
  const togglePasswordVisibilityC = () => {
    setIsPasswordNotVisibleC(!isPasswordNotVisibleC);
  };

  useEffect(() => {
    if (id) {
      const tokenPart = id.split("token=")[1];
      const params = tokenPart.split("&email=");
      setAuthToken(params[0]);
      setEmail(params[1]);

      console.log(params[0], "token", params[1], "email");
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (!emailPattern.test(email)) {
      error("Please enter a valid email address (e.g., example@nexa.com)");
      setFormErrors({
        email: "Please enter a valid email address (e.g., example@nexa.com)",
      });
      return;
    }
    if (id) {
      if (password != cPassword) {
        warning("Password and confirm password does not match");
        setLoading(false);
        return;
      }
      if (password.length < 6) {
        warning("Password must be at least 6 characters long");
        setLoading(false);
        return;
      }
      let payload = {
        token: authToken,
        email,
        newPass: password,
        confirmNewPass: cPassword,
      };
      dispatch(forgetPassword(payload))
        .unwrap()
        .then((res) => {
          success(res?.message);
          setEmail("");
          setPassword("");
          setCPassword("");
          setFormErrors({});
          navigate("/login");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setFormErrors(err.errors || {});
        });
    } else {
      let payload = {
        email,
      };
      dispatch(sendVerificationEmail(payload))
        .unwrap()
        .then((res) => {
          success(res);
          setEmail("");
          setFormErrors({});
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setFormErrors(err.errors || {});
        });
    }
  };

  return (
    <div>
      <div className="ic-login">
        <div className="ic-login-box">
          <Link to="/" className="ic-auth-logo">
            <img src="/images/logo.png" alt="logo" style={{ width: "220px" }} />
          </Link>
          <p className="text-center auth-sub-title">Request for New Password</p>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                className={`form-control ${
                  formErrors.email ? "is-invalid" : ""
                }`}
                placeholder="example@nexa.com"
                value={email}
                disabled={id ? true : false}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {formErrors.email && (
                <div className="invalid-feedback">{formErrors.email}</div>
              )}
            </div>

            {id && (
              <>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type={inputType}
                    id="password"
                    className={`form-control ${
                      formErrors.password ? "is-invalid" : ""
                    }`}
                    placeholder="*********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div
                    className="absolute right-9 top-[52%] transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordNotVisible ? (
                      <img
                        src={EyeOffIcon}
                        alt="Toggle Password Visibility"
                        width={24}
                        height={24}
                      />
                    ) : (
                      <img
                        src={EyeIcon}
                        alt="Toggle Password Visibility"
                        width={24}
                        height={24}
                      />
                    )}
                  </div>
                  {formErrors.password && (
                    <div className="invalid-feedback">
                      {formErrors.password}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="cPassword">Confirm Password</label>
                  <input
                    type={inputTypeC}
                    id="cPassword"
                    className={`form-control ${
                      formErrors.cPassword ? "is-invalid" : ""
                    }`}
                    placeholder="*********"
                    value={cPassword}
                    onChange={(e) => setCPassword(e.target.value)}
                    required
                  />
                  <div
                    className="absolute right-9 top-[68%] transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibilityC}
                  >
                    {isPasswordNotVisibleC ? (
                      <img
                        src={EyeOffIcon}
                        alt="Toggle Password Visibility"
                        width={24}
                        height={24}
                      />
                    ) : (
                      <img
                        src={EyeIcon}
                        alt="Toggle Password Visibility"
                        width={24}
                        height={24}
                      />
                    )}
                  </div>
                  {formErrors.cPassword && (
                    <div className="invalid-feedback">
                      {formErrors.cPassword}
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              type="submit"
              disabled={loading}
              className="ic-btn w-100"
              style={{ fontSize: "16px", fontWeight: "500" }}
            >
              {loading ? <Loader /> : id ? "Password Reset" : "Send Email"}
            </button>
          </form>

          <p className="have-text">
            New user? <Link to="/register">Create an account</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
