import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addStep, skipEnhanceStep, skipStep } from "./StepsSlice";
import {
  addResumeJob,
  getResumeJob,
  udpateResumeJob,
} from "../../Redux/ResumeBuilderSlice";
import { useParams } from "react-router-dom";
import { success, error } from "../../Utilities/toast";
import { SaveExitIcon } from "../../Components/Resume/svgimages";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
const CompanyInfoForm = ({ setShow, setSelectedResume }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth < 768;
  const { id } = useParams();
  const { job, getSingleResumeDoc } = useSelector(
    (state) => state.ResumeBuilder
  );
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    setName(job?.name || "");
    setTitle(job?.title || "");
    setDescription(job?.description || "");
  }, [job]);
  // const fetchJob = () => {
  //   const jobID = id;
  //
  // };
  const reset = () => {
    setName("");
    setTitle("");
    setDescription("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(skipStep(false));
    try {
      const jobID = id;
      const payload = {
        name,
        title,
        description,
      };
      if (job?.name || job?.title || job?.description) {
        dispatch(
          udpateResumeJob({
            jobID,
            payload,
          })
        )
          .unwrap()
          .then((res) => {
            success(res.detail);
            dispatch(addStep(3));
          });
      } else {
        dispatch(
          addResumeJob({
            jobID,
            payload,
          })
        )
          .unwrap()
          .then((res) => {
            success(res.detail);
            dispatch(addStep(3));
          });
      }
    } catch (errors) {
      error(errors.message);
    }
  };
  useEffect(() => {
    if (id) {
      dispatch(getResumeJob(id));
    }
  }, [id]);

  return (
    <div className="mydetails w-full md:!w-50">
      <div
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              paddingBottom: "20px",
              fontWeight: "600",
              color: "#445164",
            }}
          >
            Upload the Job Description You Would Like Us To Enhance Your Resume
            For
          </p>
        </div>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex flex-column mb-1">
          <div className="form-group d-flex justify-content-between mb-3">
            <div style={{ width: "45%" }}>
              <label htmlFor="companyname">Company Name</label>
              <input
                type="text"
                id="companyname"
                className="form-control"
                placeholder=""
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div style={{ width: "45%" }}>
              <label htmlFor="jobtitle">Job Title</label>
              <input
                type="text"
                id="jobtitle"
                className="form-control"
                placeholder=""
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group w-100">
            <label htmlFor="jobdescription">Job Description</label>
            <textarea
              id="jobdescription"
              className="workcontrol"
              placeholder=""
              style={{ height: "150px" }}
              value={description}
              required
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center py-2 px-2">
          <button
            type="button"
            className="cancel-btn mr-4"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              width: "100px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
            }}
            // onClick={() => reset()}
            onClick={() => {
              dispatch(addStep(1));
            }}
          >
            <FaLongArrowAltLeft size={18} />
            Previous
          </button>

          {/* {name || title || description ? ( */}
          <button
            type="submit"
            className="save-btn"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              width: "150px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <SaveExitIcon></SaveExitIcon>
            Save & Next
          </button>
          {!job?.id && (
            <button
              type="button"
              className="cancel-btn mx-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
              }}
              onClick={() => {
                dispatch(skipEnhanceStep(true));
                dispatch(addStep(4));
                setShow(true);
                setSelectedResume("BEFORE");
                dispatch(skipStep(true));
              }}
            >
              Skip <FaLongArrowAltRight size={18} />
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CompanyInfoForm;
