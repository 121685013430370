import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/header";
import {
  getCurrentUser,
  getUserSubscription,
} from "../../Redux/authenticate/authenticate.action";

const DashboardHome = () => {
  const { user } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(getUserSubscription());
      dispatch(getCurrentUser());
    }
  }, []);

  return (
    <div className="min-h-screen">
      <Header />

      <main className="relative">
        {/* Welcome Card */}
        <div className="w-full px-4 mt-32">
          <div className="max-w-7xl mx-auto">
            <div className="bg-gradient-to-r from-blue-600 to-blue-700 rounded-3xl py-8 px-10 max-w-2xl mx-auto text-center relative overflow-hidden shadow-xl">
              {/* Decorative elements */}
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_0%_0%,rgba(255,255,255,0.15),transparent)] pointer-events-none"></div>
              <div className="absolute -right-10 -top-10 w-40 h-40 bg-blue-500/20 rounded-full blur-3xl"></div>
              <div className="absolute -left-10 -bottom-10 w-40 h-40 bg-blue-500/20 rounded-full blur-3xl"></div>

              {/* Content */}
              <div className="relative z-10">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-3 tracking-tight">
                  Welcome to{" "}
                  {user?.organizationId && user?.organizationName
                    ? user?.organizationName
                    : "Nexa"}
                </h1>
                <p className="text-lg md:text-xl text-white/90 font-light tracking-wide">
                  Where All Your Career Goals Become Reality
                </p>
              </div>
            </div>

            {/* No Pressure Card */}
            <div className="text-center mt-4 px-4">
              <div className="bg-white rounded-full shadow-sm py-1.5 px-4 md:px-5 mb-12 inline-flex flex-wrap justify-center mx-auto">
                <span className="text-blue-600 font-bold text-sm md:text-base">
                  No Pressure
                </span>
                <span className="text-gray-800 text-sm md:text-base">
                  {" "}
                  - Get Started in Your Career Journey
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Original Cards Section - Untouched */}
        <div className="flex flex-col md:flex-row justify-center gap-6 mt-8">
          <div
            className="flex flex-col items-center p-6 bg-gray-200 rounded-xl cursor-pointer w-full md:w-52 h-52 transition-all duration-300 hover:bg-blue-500 hover:scale-105 group relative"
            onClick={() => navigate("/career-goals")}
          >
            <div className="w-12 h-12 mb-3 bg-white rounded-full flex items-center justify-center">
              <img
                src="https://nexa-web-portal.s3.amazonaws.com/icons/IMG_0114.png"
                className="w-6 h-6 object-contain"
                alt="Skills Gap"
              />
            </div>
            <p className="text-center text-gray-800 group-hover:text-white text-base font-medium">
              Take Your Skills Gap Assessment
            </p>
            <svg
              className="absolute bottom-6 w-5 h-5 text-gray-800 group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>

          <div
            className="flex flex-col items-center p-6 bg-gray-200 rounded-xl cursor-pointer w-full md:w-52 h-52 transition-all duration-300 hover:bg-blue-500 hover:scale-105 group relative"
            onClick={() => navigate("/user/profile")}
          >
            <div className="w-12 h-12 mb-3 bg-white rounded-full flex items-center justify-center">
              <img
                src="https://nexa-web-portal.s3.amazonaws.com/icons/IMG_0114.png"
                className="w-6 h-6 object-contain"
                alt="Talent Showcase"
              />
            </div>
            <p className="text-center text-gray-800 group-hover:text-white text-base font-medium">
              Showcase Your Skills
            </p>
            <svg
              className="absolute bottom-6 w-5 h-5 text-gray-800 group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>

          <div
            className="flex flex-col items-center p-6 bg-gray-200 rounded-xl cursor-pointer w-full md:w-52 h-52 transition-all duration-300 hover:bg-blue-500 hover:scale-105 group relative"
            onClick={() => navigate("/resumes")}
          >
            <div className="w-12 h-12 mb-3 bg-white rounded-full flex items-center justify-center">
              <img
                src="https://nexa-web-portal.s3.amazonaws.com/icons/IMG_0114.png"
                className="w-6 h-6 object-contain"
                alt="Create Resume"
              />
            </div>
            <p className="text-center text-gray-800 group-hover:text-white text-base font-medium">
              Craft Your Rockstar Resume
            </p>
            <svg
              className="absolute bottom-6 w-5 h-5 text-gray-800 group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>
          <div
            className="flex flex-col items-center p-6 bg-gray-200 rounded-xl cursor-pointer w-full md:w-52 h-52 transition-all duration-300 hover:bg-blue-500 hover:scale-105 group relative"
            onClick={() => navigate("/IQ/jobboard")}
          >
            <div className="w-12 h-12 mb-3 bg-white rounded-full flex items-center justify-center">
              <img
                src="https://nexa-web-portal.s3.amazonaws.com/icons/IMG_0114.png"
                className="w-6 h-6 object-contain"
                alt="Create Resume"
              />
            </div>
            <p className="text-center text-gray-800 group-hover:text-white text-base font-medium">
              Apply To Your Dream Job
            </p>
            <svg
              className="absolute bottom-6 w-5 h-5 text-gray-800 group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>
        </div>

        {/* Video Section - Untouched */}
        <div className="w-full flex justify-center mt-12">
          <div
            className="relative w-full sm:w-3/4 md:w-2/3 lg:w-3/4 xl:w-2/3"
            style={{
              aspectRatio: "16 / 9",
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/YjRQ3QbykNA"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full rounded-lg"
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default DashboardHome;
