import React from "react";

const MiniAdditionalSection = ({ sections }) => {
  return (
    <section className="cv-projects">
      {sections?.data?.map((item, index) => {
        const dynamicValues = Object.keys(sections.template.fields).reduce(
          (acc, key) => {
            if (sections.template.fields[key]) {
              acc[key] = item[key] || "";
            }
            return acc;
          },
          {}
        );

        const populateTemplate = (html, values) => {
          if (!html || !values) return html;
          return html.replace(/{(.*?)}/g, (_, key) => {
            return values[key.trim()];
          });
        };

        const populatedTemplate = populateTemplate(
          sections.template.template_ui,
          dynamicValues
        );
        return (
          <section className="cv-certifications" key={index + 100}>
            <div
              className="cv-project-item pl-1 mt-2 MiniResume-additionalSection"
              key={index}
            >
              <div dangerouslySetInnerHTML={{ __html: populatedTemplate }} />
            </div>
          </section>
        );
      })}
    </section>
  );
};

export default MiniAdditionalSection;
