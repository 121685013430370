import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getResumeBlurb,
  udpateResumeBlurb,
} from "../../../Redux/ResumeBuilderSlice";
import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md";
import { success } from "../../../Utilities/toast";
import RichTextEditor from "../../../features/RichTextEditor";
import { createMarkup } from "../../../Utilities/CreateMarkup";

export default function ProfessionalSummary({
  professionalSummaries,
  getSingleResumeDoc,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [summariesState, setSummariesState] = useState([]);
  const [activeField, setActiveField] = useState(null);
  const [hoveredField, setHoveredField] = useState(null);
  const [defaultContent, setDefaultContent] = useState("");
  const [updateDocId, setUpdateDocId] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const editableRefs = useRef([]);

  const handleFieldClick = (index, id) => {
    setUpdateDocId(id);
    const foundObj = summariesState?.find((obj, idx) => idx === index);
    setDefaultContent(foundObj.text);
    setEditorContent(foundObj.text);
    setActiveField(index);
    setTimeout(() => editableRefs.current[index]?.focus(), 0);
  };

  const getBlorbInfo = () => {
    const resumeId = id;
    dispatch(getResumeBlurb({ resumeId, onSuccess: () => {} }));
  };
  const handleBlur = () => {
    setActiveField(null);
    setHoveredField(null);
  };
  const submitChanges = () => {
    const hasChanges = editorContent !== defaultContent;
    if (hasChanges) {
      handleBlur();
      const data = {
        is_active: "false",
        text: editorContent !== "" ? editorContent : defaultContent,
      };
      const payload = {
        updateDocId,
        data,
      };
      dispatch(
        udpateResumeBlurb({
          payload,
          onSuccess: (res) => {
            success(res);
            getBlorbInfo();
          },
        })
      );
    } else {
      handleBlur();
    }
  };

  const renderField = (index, item) => {
    const isActive = activeField === index;
    const isHovered = hoveredField === index;
    const hasChanges = editorContent !== defaultContent;

    const iconStyle = {
      position: "absolute",
      top: "-10px",
      borderRadius: "50%",
      padding: "4px",
      fontSize: "1.4rem",
      color: "#fff",
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
      cursor: "pointer",
    };

    return (
      <div
        style={{ position: "relative" }}
        className={`${isActive ? "px-2" : ""}`}
      >
        <div
          ref={(el) => (editableRefs.current[index] = el)}
          onBlur={() => handleBlur()}
          onMouseEnter={() => setHoveredField(index)}
          onMouseLeave={() => setHoveredField(null)}
          onDoubleClick={() => handleFieldClick(index, item?.id)}
          suppressContentEditableWarning
          className={`editable-field apply-font mt-2 w-full ${
            isActive ? "active" : ""
          }`}
          style={{
            border: isActive ? "1px solid #ffff" : "none",
            backgroundColor: isActive ? "#ffff" : "transparent",
            fontSize: "inherit",
            cursor: "text",
            borderRadius: isActive ? "10px" : "10px",
            fontFamily: `${getSingleResumeDoc?.font_style}`,
          }}
        >
          {isActive ? (
            <div className=" customized-editor mt-">
              <RichTextEditor
                defaultContent={defaultContent}
                setEditorContent={setEditorContent}
              />
            </div>
          ) : (
            <p
              className={` ${
                getSingleResumeDoc?.font_style &&
                getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
              }`}
              style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
              dangerouslySetInnerHTML={createMarkup(item?.text)}
            />
          )}

          {isActive && hasChanges && (
            <MdDone
              onClick={() => submitChanges()}
              style={{ ...iconStyle, left: "-15px", background: "#4caf50" }}
            />
          )}
          {isActive && !hasChanges && (
            <MdClose
              onClick={() => submitChanges(index)}
              style={{ ...iconStyle, right: "-16px", background: "#ff4d4d" }}
            />
          )}
          {/* {isHovered && !isActive && (
            <MdOutlineModeEdit
              onClick={() => handleFieldClick(index, item?.id)}
              style={{ ...iconStyle, right: "-16px", background: "#000" }}
            />
          )} */}
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (professionalSummaries?.length > 0) {
      setSummariesState(professionalSummaries || []);
    } else {
      setSummariesState([]);
    }
  }, [professionalSummaries]);
  return (
    summariesState.length > 0 && (
      <section
        className={`cv-professional_summary mt-1 ${
          activeField !== null || hoveredField !== null
            ? "editable-field active bg-white p-"
            : ""
        }`}
      >
        {summariesState.map((item, index) => renderField(index, item))}
      </section>
    )
  );
}
