import React from "react";

export const CompanyOverview = ({
  logoUrl,
  companyName,
  companyTagline,
  organizationNumber,
}) => (
  <div className="pb-3 pt-4">
    <div className="flex flex-col items-center justify-between sm:flex-row">
      <div className="items-top flex w-full flex-row pb-[4px] pl-4 pr-[16px] sm:mb-0 sm:w-auto sm:pb-0 sm:pr-0">
        <img
          src={logoUrl}
          className="mr-3 h-[48px] w-[48px] rounded-[12px]"
          alt={`${companyName} logo`}
        />
        <div className="list-item__company-info">
          <div className="list-item__company-title">
            <div className="mb-1 text-[18px] flex flex-row items-center font-semibold text-[#4d4d4d]">
              <span>{companyName}</span>
            </div>
            <div className="flex flex-col items-start">
              <div className="text-sm mb-2 text-[#4d4d4d]">
                {companyTagline}
              </div>
              <div className="hidden sm:block">
                <div className="flex flex-row flex-wrap sm:flex-nowrap items-center">
                  {organizationNumber && (
                    <div className="mr-2 mb-2 sm:mb-0 flex w-max items-center text-xs flex-row whitespace-nowrap rounded-[8px] border px-[6px] py-[1px] border-topInvestorsBorder bg-topInvestorsBg">
                      {organizationNumber}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
