import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdDone, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getSingleResume,
  updateAdditionalSection,
} from "../../../Redux/ResumeBuilderSlice";
import { success } from "../../../Utilities/toast";

export default function AdditionalSections({ getSingleResumeDoc, sections }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [activeField, setActiveField] = useState({ index: null, field: null });
  const [hoveredField, setHoveredField] = useState({
    index: null,
    field: null,
  });
  const [sectionsState, setSectionsState] = useState(sections?.data || []);
  const [datePickerOpen, setDatePickerOpen] = useState(null);

  const editableRefs = useRef({});

  const ICON_STYLE = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date(); // Default to today if dateString is null or undefined
    return date.toLocaleDateString(undefined, options);
  };

  const updateSectionsState = (index, field, value) => {
    setSectionsState((prev) =>
      prev.map((proj, idx) =>
        idx === index ? { ...proj, [field]: value } : proj
      )
    );
  };

  const handleFieldClick = (index, field) => {
    if (activeField.index !== null) submitChanges();
    setActiveField({ index, field });
    if (field === "fromDate" || field === "toDate") {
      setDatePickerOpen(`${index}-${field}`);
    }
    setTimeout(() => {
      editableRefs.current[`${index}-${field}`]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, field, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[`${index}-${field}`];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      updateSectionsState(index, field, value);
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    }
  };

  const handleDateChange = (index, field, selectedDate) => {
    const date = new Date(selectedDate); // Parse the selected date
    const formattedDate = date.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"
    setSectionsState((prev) =>
      prev.map((pub, idx) =>
        idx === index ? { ...pub, [field]: formattedDate } : pub
      )
    );
  };

  const submitChanges = () => {
    const originalSection = sections?.data[activeField.index];
    const updatedSection = sectionsState[activeField.index];
    const hasChanges =
      originalSection[activeField.field]?.trim() !==
      updatedSection[activeField.field]?.trim();

    if (hasChanges) {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      const payload = {
        sectionId: updatedSection?.id,
        data: [updatedSection],
      };
      dispatch(
        updateAdditionalSection({
          payload,
          onSuccess: (res) => {
            success(res?.detail);
            dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
          },
        })
      );
    } else {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
    }
  };

  const renderEditableField = (section, index, field) => {
    const isActive = activeField.index === index && activeField.field === field;
    const isHovered =
      hoveredField.index === index && hoveredField.field === field;
    const originalSection = sections?.data[index];
    const updatedSection = sectionsState[index];

    const hasChanges =
      field &&
      originalSection &&
      updatedSection &&
      originalSection[field]?.trim() !== updatedSection[field]?.trim();

    return (
      <span
        className={`editable-field ${isActive || isHovered ? "active" : ""}`}
        onMouseEnter={() => setHoveredField({ index, field })}
        onMouseLeave={() => setHoveredField({ index: null, field: null })}
        style={{
          position: "relative",
          border:
            isActive || isHovered ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={isActive}
          ref={(el) => (editableRefs.current[`${index}-${field}`] = el)}
          onInput={(e) => handleFieldInput(index, field, e)}
          onBlur={
            activeField.field != "fromDate" && activeField.field != "toDate"
              ? submitChanges
              : ""
          }
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          className={`editable-field`}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {(field === "fromDate" && datePickerOpen === `${index}-${field}`) ||
          (field === "toDate" && datePickerOpen === `${index}-${field}`) ? (
            <input
              type="date"
              id="start"
              name="trip-start"
              value={
                section && !isNaN(new Date(section).getTime())
                  ? new Date(section).toLocaleDateString("en-CA") // Converts to 'YYYY-MM-DD' format
                  : ""
              }
              onChange={(e) => handleDateChange(index, field, e.target.value)}
            />
          ) : (
            section
          )}
        </span>
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...ICON_STYLE, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && !hasChanges && (
          <MdClose
            onClick={submitChanges}
            style={{ ...ICON_STYLE, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, field)}
            style={{ ...ICON_STYLE, right: "-20px", background: "#000" }}
          />
        )}
      </span>
    );
  };

  useEffect(() => {
    if (sections?.data?.length > 0) setSectionsState(sections?.data);
  }, [sections]);
  return (
    <section className="cv-projects">
      {sectionsState?.map((data, index) => {
        return (
          <section className="cv-certifications" key={index + 100}>
            <div className="cv-project-item pl-1" key={index}>
              <div className="d-flex justify-content-between mt-2 align-items-center">
                <div>
                  {data?.title && (
                    <h5
                      className="cv-item-title text-md mb-0 apply-font"
                      style={{
                        color: getSingleResumeDoc?.subheading_color || "#000",
                      }}
                    >
                      {renderEditableField(data?.title, index, "title")}
                    </h5>
                  )}
                  {data?.subtitle && (
                    <p className="cv-item-title apply-font">
                      {renderEditableField(data?.subtitle, index, "subtitle")}
                    </p>
                  )}
                </div>
                <p className="cv-item-date apply-font">
                  {data?.toDate && (
                    <span>
                      {renderEditableField(
                        formatDate(data?.toDate),
                        index,
                        "toDate"
                      )}
                    </span>
                  )}
                  {data?.fromDate && data?.toDate ? "-" : ""}
                  {data?.fromDate && (
                    <span>
                      {renderEditableField(
                        formatDate(data?.fromDate),
                        index,
                        "fromDate"
                      )}
                    </span>
                  )}
                </p>
              </div>
              {data?.description && (
                <div className="cv-professional_summary-item apply-font">
                  <p className="apply-font">
                    {renderEditableField(
                      data?.description,
                      index,
                      "description"
                    )}
                  </p>
                </div>
              )}
            </div>
          </section>
        );
      })}
    </section>
  );
}
