import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStep,
  setActivePreview,
} from "../../features/companyinformation/StepsSlice";
import { MdUpdate } from "react-icons/md";
import { LuPencil } from "react-icons/lu";
import { FaArrowRight, FaRegEye } from "react-icons/fa";
import { useState, useEffect } from "react";
import { MagicWandIcon, CreateIcon, BuildIcon } from "./svgimages";
import ReactModal from "react-modal";
import {
  getDeletedSections,
  getSingleResume,
  restoreDeletedSections,
  updateResume,
  updateResumeDetail,
  upgradeResume,
} from "../../Redux/ResumeBuilderSlice";
import { useNavigate } from "react-router-dom";
import { ArchiveRestoreIcon, SettingsIcon } from "lucide-react";
import Colors from "../common/ResumeComponents/Colors";
import FontPicker from "font-picker-react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { Select } from "antd";
const Option = Select;
const ResumeHeaderButton = ({ children, onClick, active, disabled }) => {
  return (
    <button
      className={`w-auto px-3 sm:!px-10 md:!px-5 lg:!px-10 h-10 text-[16px] font-normal text-[#2b6be2] rounded-lg gap-3 flex justify-center items-center ${
        active ? "resume-header-button-active" : "bg-[#f7f7f7]"
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const ResumeHeader = ({ resumeId, handlePreview2 }) => {
  const step = useSelector(selectStep);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSingleResumeDoc, deletedSections } = useSelector(
    (state) => state.ResumeBuilder
  );
  const { activePreview } = useSelector((state) => state?.Steps);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [designModal, setDesignModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [upgradeLoader, setUpgradeLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("headingColor");
  const [activeFontFamily, setActiveFontFamily] = useState("DM Sans");
  const [selectedHeadingColor, setSelectedHeadingColor] = useState("");
  const [selectedSubHeadingColor, setSelectedSubHeadingColor] = useState("");
  const [restoreEnable, setRestoreEnable] = useState(false);
  const [fontSize, setFontSize] = useState([0, 25]);

  const handlePreviewResume = () => {
    const payload = {
      preview: true,
      step: step,
      step1: false,
    };
    dispatch(setActivePreview(payload));
  };

  const actionButtons = [
    {
      condition: step <= 2,
      onClick: () => setUpgradeModal(true),
      icon: <MdUpdate size={20} />,
      text: "Create New Version",
    },
    {
      condition: step <= 3 && activePreview,
      onClick: handlePreview2,
      icon: <LuPencil size={19} />,
      text: "Edit Details",
    },
    {
      condition: step <= 3 && !activePreview,
      onClick: handlePreviewResume,
      icon: <FaRegEye size={20} />,
      text: "Preview",
    },
    {
      condition: true,
      onClick: () => setDesignModal(true),
      icon: <SettingsIcon size={20} />,
      text: "Design",
    },
    {
      condition: restoreEnable,
      onClick: () => setRestoreModal(true),
      icon: <ArchiveRestoreIcon size={20} />,
      text: "Draft",
    },
  ];

  useEffect(() => {
    if (getSingleResumeDoc) {
      setSelectedHeadingColor(
        getSingleResumeDoc?.heading_color
          ? getSingleResumeDoc?.heading_color
          : ""
      );
      setSelectedSubHeadingColor(
        getSingleResumeDoc?.subheading_color
          ? getSingleResumeDoc?.subheading_color
          : ""
      );
      setFontSize([
        0,
        parseInt(getSingleResumeDoc?.font_size?.replace("px", ""), 10) || 25,
      ]);
      setActiveFontFamily(getSingleResumeDoc?.font_style || "DM Sans");
    }
  }, [getSingleResumeDoc]);
  useEffect(() => {
    if (deletedSections?.length > 0) {
      setRestoreEnable(true);
    } else {
      setRestoreEnable(false);
      setRestoreModal(false);
    }
  }, [deletedSections]);
  useEffect(() => {
    dispatch(getDeletedSections(resumeId));
  }, []);
  return (
    <>
      <div className="mt-20 bg-white resume-header">
        <div className="flex justify-between items-center w-full px-2 sm:px-4">
          <div className="flex gap-2 sm:gap-5 items-center">
            <ResumeHeaderButton active={step === 1} disabled={true}>
              <BuildIcon active={step === 1}></BuildIcon>
              <p className="hidden md:block">Build</p>
            </ResumeHeaderButton>
            <FaArrowRight className="text-[#2B6BE2] xl:mx-4 hidden sm:block" />
            <ResumeHeaderButton active={step === 2} disabled={true}>
              <MagicWandIcon active={step === 2}></MagicWandIcon>
              <p className="hidden md:block">Enhance</p>
            </ResumeHeaderButton>
            <FaArrowRight className="text-[#2B6BE2] xl:mx-4 hidden sm:block" />
            <ResumeHeaderButton active={step === 3} disabled={true}>
              <CreateIcon active={step === 3}></CreateIcon>
              <p className="hidden md:block">Create</p>
            </ResumeHeaderButton>
          </div>
          <div className="flex items-center gap-3 xl:gap-8">
            {actionButtons.map(
              (button, index) =>
                button.condition && (
                  <button
                    key={index}
                    className="whitespace-nowrap bg-[#2B6BE2] text-white rounded-full border-none flex w-auto items-center gap-2 p-2 xl:!px-4"
                    onClick={button.onClick}
                  >
                    {button.icon}
                    <span className="hidden xl:block">{button.text}</span>
                  </button>
                )
            )}
          </div>
        </div>
      </div>
      {/* ////Modals */}
      <ReactModal
        isOpen={upgradeModal}
        onRequestClose={() => setUpgradeModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>Upgrade Resume Version</h2>
        <hr />
        <div className="modal-body">
          <div className="">
            <p className="text-center">
              Do you want to upgrade your resume to the latest version?
            </p>
            <div className="flex align-items-center justify-content-center mt-2">
              <button
                className="bg-green-600 px-4 py-2 rounded text-white"
                disabled={upgradeLoader}
                onClick={() => {
                  setUpgradeLoader(true);
                  dispatch(
                    upgradeResume({
                      resumeId,
                      onSuccess: (response) => {
                        setUpgradeLoader(false);
                        if (response.status === true) {
                          setUpgradeModal(false);
                          navigate(`/resume-builder/view/${response?.id}`);
                        }
                      },
                    })
                  );
                }}
              >
                {upgradeLoader ? "Upgrading..." : "Yes"}
              </button>
              <button
                className="bg-red-600 px-4 py-2 rounded mx-2 text-white"
                onClick={() => setUpgradeModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={designModal}
        onRequestClose={() => setDesignModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },

          content: {
            top: "55%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            maxHeight: "75vh",
          },
        }}
      >
        <h2>Design & Font</h2>
        <hr />
        <div
          className="modal-body "
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div>
            <div>
              <h3>Heading Fonts Size: {fontSize[1]}px</h3>
            </div>
            <div className="mt-2 mb-2">
              <RangeSlider
                min={0}
                max={100}
                step={5}
                value={fontSize}
                onInput={(value) => setFontSize(value)} // onInput is specific to react-range-slider-input
              />
            </div>
            <div>
              <h3>Global Fonts Style</h3>
            </div>
            <div className="mt-2">
              {/* <FontPicker
                apiKey="AIzaSyCWQu1ydJc-Vi9JJvFsKa52qyKh5biip3M"
                activeFontFamily={activeFontFamily}
                onChange={(nextFont) => setActiveFontFamily(nextFont.family)}
              /> */}
              <Select
                placeholder="Select Font"
                value={activeFontFamily}
                onChange={(value) => {
                  setActiveFontFamily(value);
                }}
                className="w-full mt-1 h-[48px]"
                showSearch
              >
                <Option value="DM Sans">DM Sans</Option>
                <Option value="Bebas Neue">Bebas Neue</Option>
                <Option value="Roboto">Roboto</Option>
                <Option value="Poppins">Poppins</Option>
                <Option value="Ubuntu">Ubuntu</Option>
                <Option value="Karla">Karla</Option>
              </Select>
            </div>
            <div className="mt-4">
              <Colors
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                selectedHeadingColor={selectedHeadingColor}
                setSelectedHeadingColor={setSelectedHeadingColor}
                selectedSubHeadingColor={selectedSubHeadingColor}
                setSelectedSubHeadingColor={setSelectedSubHeadingColor}
              />
            </div>
          </div>
          <button
            className="bg-blue-500 px-4 py-2 rounded mx-2 text-white"
            onClick={() => {
              const createResume = {
                ...(selectedHeadingColor && {
                  heading_color: selectedHeadingColor,
                }),
                ...(selectedSubHeadingColor && {
                  subheading_color: selectedSubHeadingColor,
                }),
                font_size: `${fontSize[1]}px`,
                ...(activeFontFamily && {
                  font_style: activeFontFamily,
                }),
              };
              dispatch(
                updateResume({
                  createResume,
                  resumeId,
                  onSuccess: (response) => {
                    console.log("response: ", response);
                    let updateData = {
                      ...getSingleResumeDoc,
                      subheading_color: response?.subheading_color,
                      heading_color: response?.heading_color,
                      font_size: response?.font_size,
                      font_style: response?.font_style,
                    };
                    dispatch(updateResumeDetail(updateData));
                    setDesignModal(false);
                    setActiveTab("headingColor");
                    setSelectedHeadingColor("");
                    setSelectedSubHeadingColor("");
                    setFontSize([0, 60]);
                    setActiveFontFamily("DM Sans");
                  },
                  onError: () => {},
                })
              );
            }}
          >
            Save Changes
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={restoreModal}
        onRequestClose={() => setRestoreModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },

          content: {
            top: "55%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <div className="flex justify-between items-center">
          <h2>Add removed sections back into your resume</h2>
          <div
            className=" bg-white text-red-600 rounded-full w-6 h-6 flex items-center justify-center shadow-md cursor-pointer"
            onClick={() => {
              setRestoreModal(false);
            }}
          >
            <i className="ri-close-line text-sm"></i>
          </div>
        </div>
        <hr />
        <div
          className="modal-body "
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="flex flex-col max-h-[60vh]">
            <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
              <div className="py-2 inline-block min-w-full">
                <div className="overflow-hidden">
                  <table className="min-w-full">
                    <thead className="bg-gray-200 border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Title
                        </th>

                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-center"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {deletedSections?.length > 0 ? (
                        deletedSections?.map((item) => {
                          return (
                            <tr className="bg-white  text-capitalize border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {item?.title}
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex justify-center gap-3">
                                <button
                                  className="w-8 h-8 rounded-full flex justify-center items-center border hover:bg-gray-100 hover:scale-110 transition-all transform duration-200"
                                  onClick={() => {
                                    dispatch(
                                      restoreDeletedSections({
                                        resumeId,
                                        section: item?.key,
                                        onSuccess: async () => {
                                          await dispatch(
                                            getDeletedSections(resumeId)
                                          );
                                          await dispatch(
                                            getSingleResume({
                                              resumeId,
                                              onSuccess: () => {},
                                            })
                                          );
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <ArchiveRestoreIcon />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="bg-white border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                          <td
                            colSpan="6"
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center"
                          >
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default ResumeHeader;
