import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import languages from "../config/languages";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const octagonRepo = RepositoryFactory.get("octagon");
const callScriptRepo = RepositoryFactory.get("callScript");

function sanitizeTreeData(treeData) {
  return treeData.map((node) => {
    // Create a shallow copy and exclude non-serializable properties
    const { title, key, content, language, children } = node;
    return {
      title,
      key,
      content,
      language,
      children: children ? sanitizeTreeData(children) : [], // Recursively sanitize child nodes
    };
  });
}
export const saveProgress = createAsyncThunk(
  "octagon/saveProgress",
  async ({ projectId, payload }, { dispatch, rejectWithValue }) => {
    const sanitizedPayload = {
      ...payload,
      treeData: sanitizeTreeData(payload.treeData), // Use the sanitized tree data
    };

    if (payload.treeData.length == 0) {
      error("Create a file to save progress");
      return rejectWithValue("Create a file to save progress");
    }

    try {
      const { data } = await octagonRepo.saveProgress(
        projectId,
        sanitizedPayload
      );
      if (data?.status_code === 200) {
        success("Progress saved successfully!");
        return data;
      }
      throw new Error("Save failed");
    } catch (err) {
      error(err.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

// New action to load progress
export const loadProgress = createAsyncThunk(
  "octagon/loadProgress",
  async (projectId, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await octagonRepo.loadProgress(projectId);
      if (data?.status_code === 200) {
        return data.treeData;
      }
      throw new Error("Load failed");
    } catch (err) {
      error(err.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);
export const executeCode = createAsyncThunk(
  "octagone/executeCode",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(executeCodeLoader(true));
    try {
      const { data } = await octagonRepo.executeCode(payload);
      if (data) {
        dispatch(executeCodeLoader(false));
        return data;
      }
    } catch (err) {
      dispatch(executeCodeLoader(false));
      error(err.message);
      return rejectWithValue(err);
    }
  }
);
export const createCallScript = createAsyncThunk(
  "octagon/createCallScript",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await callScriptRepo.createCallScript(payload);
      if (data?.id) {
        success("Call Script created successfully!");
        return data;
      }
      throw new Error("Create Call Script failed");
    } catch (err) {
      error(err.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

// Get CallScript Thunk
export const getCallScript = createAsyncThunk(
  "octagon/getCallScript",
  async (callScriptId, { rejectWithValue }) => {
    try {
      const { data } = await callScriptRepo.getCallScript(callScriptId);
      if (data?.id) {
        return data;
      }
      throw new Error("Get Call Script failed");
    } catch (err) {
      error(err.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

// List CallScripts Thunk
export const listCallScripts = createAsyncThunk(
  "octagon/listCallScripts",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await callScriptRepo.listCallScripts(params);
      if (Array.isArray(data)) {
        return data;
      }
      throw new Error("List Call Scripts failed");
    } catch (err) {
      error(err.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

// Update CallScript Thunk
export const updateCallScript = createAsyncThunk(
  "octagon/updateCallScript",
  async ({ callScriptId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await callScriptRepo.updateCallScript(
        callScriptId,
        payload
      );
      if (data?.id) {
        success("Call Script updated successfully!");
        return data;
      }
      throw new Error("Update Call Script failed");
    } catch (err) {
      error(err.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

// Delete CallScript Thunk
export const deleteCallScript = createAsyncThunk(
  "octagon/deleteCallScript",
  async (callScriptId, { rejectWithValue }) => {
    try {
      await callScriptRepo.deleteCallScript(callScriptId);
      success("Call Script deleted successfully!");
      return callScriptId;
    } catch (err) {
      error(err.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

function getFileLanguage(fileName) {
  return fileName.includes(".") && fileName.at(0) !== "."
    ? languages[fileName.split(".").at(-1)]
    : "other";
}

function getFileExtension(fileName) {
  return fileName.includes(".") && fileName.at(0) !== "."
    ? fileName.split(".").at(-1)
    : "txt";
}

// get the reference of directory at path in the state slice,
// path is full path of directory
function getDirFromPath(fileTree, path) {
  if (path === ".") return fileTree;
  let pathArr = path.split("/");
  if (pathArr[0] === ".") pathArr = pathArr.slice(1);
  return pathArr.reduce((acc, dir) => {
    if (dir === "") {
      return acc;
    }
    return acc.childDirs.find((child) => child.label === dir);
  }, fileTree);
}

// get the reference of directory at path in the state slice,
// path is full path of the file
function getFileFromPath(fileTree, path) {
  let dirPath = path.split("/").slice(0, -1).join("/");
  let fileName = path.split("/").at(-1);
  const dir = getDirFromPath(fileTree, dirPath);
  return dir.childFiles.find((file) => file.name === fileName);
}

const octagonSlice = createSlice({
  name: "octagon",
  initialState: {
    isIndustry: false,
    selectedQuestionType: "",
    roleCategoryId: "",
    interviewTrainingSessionId: "",
    loaders: {
      executeCodeLoader: false,
      createCallScriptLoader: false,
      getCallScriptLoader: false,
      listCallScriptsLoader: false,
      updateCallScriptLoader: false,
      deleteCallScriptLoader: false,
    },
    errors: {
      executeCodeError: null,
      createCallScriptError: null,
      getCallScriptError: null,
      listCallScriptsError: null,
      updateCallScriptError: null,
      deleteCallScriptError: null,
    },
    fileType: "js",
    fileList: [],
    fileTree: {
      name: ".",
      path: ".",
      childDirs: [],
      childFiles: [],
    },
    answers: {},
    activeFileKey: null,
    executeCodeResult: null,
    callScripts: [], // To store list of CallScripts
    currentCallScript: null, // To store a single CallScript
  },
  reducers: {
    executeCodeLoader: (state, action) => {
      state.loaders.executeCodeLoader = action.payload;
    },
    // load files and directory data from the backend into state
    loadDir: (state, action) => {
      const dir = getDirFromPath(state.fileTree, action.payload.path);
      let childFiles = action.payload.files.map((file) => ({
        name: file,
        path: action.payload.path + "/" + file,
        // language: getFileLanguage(file),
        // type: getFileExtension(file),
        label: file,
        saved: true,
        value: null, // use null instead
      }));
      let childDirs = action.payload.dirs.map((dir) => ({
        label: dir,
        path: action.payload.path + "/" + dir,
        childDirs: null,
        childFiles: null,
      }));

      dir.childDirs = childDirs;
      dir.childFiles = childFiles;
    },
    changeTab: (state, action) => {
      state.activeFileKey = action.payload;
    },
    codeChange: (state, action) => {
      state[action.payload.questionId] = action.payload.code;
      state.fileList[parseInt(state.activeFileKey)].value = action.payload.code;
      state.fileList[parseInt(state.activeFileKey)].saved = false;
    },
    // load file content from the backend
    loadFile: (state, action) => {
      const dir = getDirFromPath(state.fileTree, action.payload.path);
      let fileName = action.payload.fileName;
      let node = dir.childFiles.find((file) => file.name === fileName);
      node.value = action.payload.value;

      state.fileList.find((file) => file.path === node.path).value =
        action.payload.value;
    },
    openFileTab: (state, action) => {
      const file = getFileFromPath(state.fileTree, action.payload.path);
      state.activeFileKey = state.fileList.length;
      let key = state.activeFileKey;
      state.fileList.push({
        key,
        name: file.name,
        label: file.name,
        value: file.value,
        path: file.path,
        saved: true,
        closable: true,
      });
    },
    // create new file
    newFile: (state, action) => {
      let fileName = action.payload.name;
      let fileObj = {
        key: state.fileList.length,
        label: fileName,
        type: getFileExtension(fileName),
        value: "",
        path: action.payload.dirPath + "/" + fileName,
        language: getFileLanguage(fileName),
        saved: false,
        closable: true,
      };
      const dir = getDirFromPath(state.fileTree, action.payload.dirPath);
      dir.childFiles.push({
        name: fileName,
        path: action.payload.dirPath + "/" + fileName,
        label: fileName,
        saved: false,
        value: "",
      });
      state.fileList.push(fileObj);
      state.activeFileKey = state.fileList.length - 1;
    },
    fileSavedState: (state, action) => {
      // update status of file as saved after it is saved in backend
      state.fileList[action.payload.fileKey].saved = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(executeCode.pending, (state) => {
        state.loaders.executeCodeLoader = true;
      })
      .addCase(executeCode.fulfilled, (state, action) => {
        state.loaders.executeCodeLoader = false;
        state.executeCodeResult = action.payload;
      })
      .addCase(executeCode.rejected, (state, action) => {
        state.loaders.executeCodeLoader = false;
        state.errors.executeCodeError = action.payload;
      })
      .addCase(saveProgress.pending, (state) => {
        state.loaders.saveProgressLoader = true;
      })
      .addCase(saveProgress.fulfilled, (state, action) => {
        state.loaders.saveProgressLoader = false;
      })
      .addCase(saveProgress.rejected, (state, action) => {
        state.loaders.saveProgressLoader = false;
        state.errors.saveProgressError = action.payload;
      })
      .addCase(loadProgress.pending, (state) => {
        state.loaders.loadProgressLoader = true;
      })
      .addCase(loadProgress.fulfilled, (state, action) => {
        state.loaders.loadProgressLoader = false;
        state.treeData = action.payload;
      })
      .addCase(loadProgress.rejected, (state, action) => {
        state.loaders.loadProgressLoader = false;
        state.errors.loadProgressError = action.payload;
      })
      .addCase(createCallScript.pending, (state) => {
        state.loaders.createCallScriptLoader = true;
      })
      .addCase(createCallScript.fulfilled, (state, action) => {
        state.loaders.createCallScriptLoader = false;
        state.callScripts.push(action.payload);
      })
      .addCase(createCallScript.rejected, (state, action) => {
        state.loaders.createCallScriptLoader = false;
        state.errors.createCallScriptError = action.payload;
      })
      // Get CallScript Cases
      .addCase(getCallScript.pending, (state) => {
        state.loaders.getCallScriptLoader = true;
      })
      .addCase(getCallScript.fulfilled, (state, action) => {
        state.loaders.getCallScriptLoader = false;
        state.currentCallScript = action.payload;
      })
      .addCase(getCallScript.rejected, (state, action) => {
        state.loaders.getCallScriptLoader = false;
        state.errors.getCallScriptError = action.payload;
      })
      // List CallScripts Cases
      .addCase(listCallScripts.pending, (state) => {
        state.loaders.listCallScriptsLoader = true;
      })
      .addCase(listCallScripts.fulfilled, (state, action) => {
        state.loaders.listCallScriptsLoader = false;
        state.callScripts = action.payload;
      })
      .addCase(listCallScripts.rejected, (state, action) => {
        state.loaders.listCallScriptsLoader = false;
        state.errors.listCallScriptsError = action.payload;
      })
      // Update CallScript Cases
      .addCase(updateCallScript.pending, (state) => {
        state.loaders.updateCallScriptLoader = true;
      })
      .addCase(updateCallScript.fulfilled, (state, action) => {
        state.loaders.updateCallScriptLoader = false;
        const index = state.callScripts.findIndex(
          (cs) => cs.id === action.payload.id
        );
        if (index !== -1) {
          state.callScripts[index] = action.payload;
        }
      })
      .addCase(updateCallScript.rejected, (state, action) => {
        state.loaders.updateCallScriptLoader = false;
        state.errors.updateCallScriptError = action.payload;
      })
      // Delete CallScript Cases
      .addCase(deleteCallScript.pending, (state) => {
        state.loaders.deleteCallScriptLoader = true;
      })
      .addCase(deleteCallScript.fulfilled, (state, action) => {
        state.loaders.deleteCallScriptLoader = false;
        state.callScripts = state.callScripts.filter(
          (cs) => cs.id !== action.payload
        );
      })
      .addCase(deleteCallScript.rejected, (state, action) => {
        state.loaders.deleteCallScriptLoader = false;
        state.errors.deleteCallScriptError = action.payload;
      });
  },
});

export const {
  changeTab,
  executeCodeLoader,
  codeChange,
  newFile,
  loadDir,
  fileSavedState,
  loadFile,
  openFileTab,
  setTreeData,
} = octagonSlice.actions;
export default octagonSlice.reducer;
