import React, { useEffect, useState } from "react";
import Category from "../../Components/Resume/Category";
import Skill from "../../Components/Resume/Skill";
import { useDispatch, useSelector } from "react-redux";
// import {
//   addCategory,
//   addSkill,
//   clearEditingCategoryIndex,
//   clearEditingSkillIndex,
//   deleteCategory,
//   deleteSkill,
//   selectCategories,
//   selectEditingCategoryIndex,
//   selectEditingSkillIndex,
//   selectNewCategory,
//   selectNewSkill,
//   setEditingCategoryIndex,
//   setEditingSkillIndex,
//   setNewCategory,
//   setNewSkill,
//   updateCategory,
//   updateSkill,
// } from "./SkillsSlice";
import {
  AddCategory,
  RemoveCategory,
  UpdateCategory,
  AddSkills,
  RemoveSkills,
  UpdateSkills,
  updateResumeCategory,
  deleteResumeCategory,
  addResumeSkill,
  updateResumeSkill,
  deleteResumeSkill,
  getSkill,
  getSkills,
  getResumeCategory,
} from "../../Redux/ResumeBuilderSlice";
// import {
//   setActiveField,
//   showSkillsForm,
// } from "../education/EducationFormSlice";
import { addResumeCategory } from "../../Redux/ResumeBuilderSlice";
import { useParams } from "react-router-dom";
import { success } from "../../Utilities/toast";

const Skills = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.ResumeBuilder);
  const [NewCategory, setNewCategory] = useState("");
  const [NewSkill, setNewSkill] = useState("");
  const [EditingCategoryIndex, setEditingCategoryIndex] = useState(null);
  const [EditingSkillIndex, setEditingSkillIndex] = useState(null);
  const [SkillForCategoryIndex, setSkillForCategoryIndex] = useState(null);
  const [FormVisible, setFormVisible] = useState(false);
  const [skillFormVisible, setskillFormVisible] = useState(false);
  const [Categorees, setCategorees] = useState([]);
  console.log("Categorees: ", Categorees);
  const [skillID, setskillID] = useState("");
  const [categoryVal, setCategoryVal] = useState(null);
  const [categoryID, setCategoryID] = useState("");
  useEffect(() => {
    if (categories) {
      setCategorees(categories);
    }
  }, [categories]);
  const fetchCategories = () => {
    const ID = id;
    dispatch(getResumeCategory({ ID, onSuccess: () => {} }));
  };
  const handleCategoryChange = (event) => {
    setNewCategory(event.target.value);
  };

  const handleSkillChange = (event) => {
    setNewSkill(event.target.value);
  };
  const toggleNewCategory = (val) => {
    setCategoryVal(val);
    setFormVisible(!FormVisible);
    setEditingCategoryIndex(null);
    setNewCategory("");
  };

  const toggleNewSkill = (Id, categoryIndex) => {
    setCategoryID(Id);
    setSkillForCategoryIndex(
      SkillForCategoryIndex === categoryIndex ? null : categoryIndex
    );
    setCategoryID(Id);
    setskillFormVisible(!skillFormVisible);
    setEditingSkillIndex(null);
    setNewSkill("");
  };

  const handlePlusCategory = (event) => {
    event.preventDefault();
    if (NewCategory.trim() !== "") {
      const payload = { name: NewCategory };
      const ID = id;
      if (categoryVal == "category") {
        dispatch(
          addResumeCategory({
            ID,
            payload,
            onSuccess: (res) => {
              success(res);
              fetchCategories();
            },
          })
        );
      } else if (categoryVal == "skill") {
        payload.resume_id = ID;
        dispatch(
          addResumeSkill({
            ID,
            payload,
            onSuccess: (res) => {
              success(res);
              fetchCategories();
              setCategoryID("");
            },
          })
        );
      }

      // dispatch(getSkills({ ID, onSuccess: () => {} }));
      setNewCategory("");
      setFormVisible(false);
    }
  };

  const handleUpdateCategory = (ID, index) => {
    setFormVisible(true);
    setCategoryID(ID);
    setEditingCategoryIndex(index);
    setNewCategory(categories[index]?.name);
  };

  const handleSaveUpdatedCategory = (event) => {
    event.preventDefault();
    if (NewCategory !== "") {
      const ID = categoryID;
      const payload = { name: NewCategory };
      dispatch(
        updateResumeCategory({
          ID,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchCategories();
            setCategoryID("");
          },
        })
      );
      setEditingCategoryIndex(null);
      setNewCategory("");
      setFormVisible(false);
    }
  };

  const handleDeleteCategory = (ID, index) => {
    dispatch(
      deleteResumeCategory({
        ID,
        onSuccess: (res) => {
          success(res);
          fetchCategories();
        },
      })
    );
  };

  const handlePlusSkill = (event, Id, categoryIndex) => {
    event.preventDefault();
    if (NewSkill.trim() !== "") {
      const ID = id;
      const payload = {
        resume_id: ID,
        name: NewSkill,
        category_id: categoryID,
      };
      dispatch(
        addResumeSkill({
          ID,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchCategories();
            setCategoryID("");
          },
        })
      );
      setNewSkill("");
      setSkillForCategoryIndex(null);
      setskillFormVisible(false);
    }
  };

  const handleupdateSkill = (ID, categoryIndex, skillIndex) => {
    setSkillForCategoryIndex(categoryIndex);
    setEditingSkillIndex(skillIndex);
    setskillID(ID);
    setNewSkill(Categorees[categoryIndex].skills[skillIndex].name);
  };

  const handleSaveUpdatedSkill = (event, categoryIndex) => {
    event.preventDefault();
    if (NewSkill.trim() !== "") {
      const ID = skillID;
      const payload = {
        resume_id: id,
        name: NewSkill,
        skill_id: skillID,
      };
      dispatch(
        updateResumeSkill({
          ID,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchCategories();
            setskillID("");
          },
        })
      );
      setEditingSkillIndex(null);
      setNewSkill("");
      setSkillForCategoryIndex(null);
    }
  };

  const handleDeleteSkill = (ID, categoryIndex, skillIndex) => {
    dispatch(
      deleteResumeSkill({
        ID,
        onSuccess: (res) => {
          success(res);
          fetchCategories();
        },
      })
    );
  };

  return (
    <>
      {FormVisible ? (
        <div className="mt-3 pb-1 px-3 border-bottom border-primary">
          <form
            onSubmit={(e) =>
              EditingCategoryIndex !== null
                ? handleSaveUpdatedCategory(e)
                : handlePlusCategory(e)
            }
          >
            <div className="d-flex flex-column mb-1">
              <div className="form-group w-100">
                <label htmlFor="category">
                  {categoryVal == "category" ? "Category" : "Skill"}
                </label>
                <input
                  type="text"
                  id="category"
                  className="form-control"
                  placeholder={`Enter ${categoryVal} name`}
                  // onFocus={() => dispatch(showSkillsForm())}
                  value={NewCategory}
                  onChange={(e) => handleCategoryChange(e)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end py-2">
              <button
                type="button"
                className="cancel-btn mr-4"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100px",
                  height: "40px",
                }}
                // onClick={toggleCategory}
                onClick={toggleNewCategory}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="save-btn"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100px",
                  height: "40px",
                }}
              >
                {EditingCategoryIndex !== null ? "Save" : "Add"}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="professionalsummary-item pt-2 px-2 border-bottom border-0">
          {/* <div className="skills-add-container "> */}
          {/* <button
            className="transparent_button"
            onClick={() => toggleNewCategory("category")}
          >
            <i className="ri-add-circle-line" style={{ fontSize: "24px" }}></i>
            <p>Add Category</p>
          </button> */}
          <button
            className="pl-2 professionalitem-button"
            onClick={() => toggleNewCategory("skill")}
          >
            <i className="ri-add-circle-line" style={{ fontSize: "20px" }}></i>
            <span className="ml-2" style={{ fontSize: "18px" }}>
              Add Skill
            </span>
          </button>
          {/* </div> */}
        </div>
      )}

      <div className="pt-2 pb-1 px-2 border-[#2a68dc] rounded-xl skills-show">
        {Categorees?.length > 0 ? (
          Categorees?.map((category, categoryIndex) => (
            <div key={categoryIndex} className="d-flex  w-100  flex-column  ">
              {/* <Category
                name={category?.name}
                onAddSkill={() => toggleNewSkill(category?.id, categoryIndex)}
                onDelete={() =>
                  handleDeleteCategory(category.id, categoryIndex)
                }
                onEdit={() => handleUpdateCategory(category.id, categoryIndex)}
              /> */}
              {SkillForCategoryIndex === categoryIndex && (
                <div className="mt-3 mb-3">
                  <form
                    onSubmit={(event) =>
                      EditingSkillIndex !== null
                        ? handleSaveUpdatedSkill(event, categoryIndex)
                        : handlePlusSkill(event, categoryIndex)
                    }
                  >
                    <div className="d-flex flex-column mb-1">
                      <div className="form-group w-100">
                        <label htmlFor="skills">Skills</label>
                        <input
                          type="text"
                          id="skill"
                          className="form-control"
                          placeholder="Enter skills"
                          value={NewSkill}
                          onChange={(e) => handleSkillChange(e)}
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-end py-2 px-2 border-bottom border-primary">
                      <button
                        type="button"
                        className="cancel-btn mr-4"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          width: "100px",
                          height: "40px",
                        }}
                        onClick={() => toggleNewSkill(categoryIndex)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="save-btn"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          width: "100px",
                          height: "40px",
                        }}
                      >
                        {EditingSkillIndex !== null ? "Save" : "Add"}
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {category?.skills?.length > 0 ? (
                category.skills
                  .slice()
                  .sort((a, b) => Number(a?.position) - Number(b?.position))
                  .filter((skill, index) => EditingSkillIndex !== index)
                  .map((skill, skillIndex) => (
                    <Skill
                      skills={category?.skills?.length}
                      skillIndex={skillIndex}
                      setCategorees={setCategorees}
                      Categorees={Categorees}
                      name={skill.name}
                      category={category.categoryName}
                      onDelete={() =>
                        handleDeleteSkill(skill?.id, categoryIndex, skillIndex)
                      }
                      onEdit={() =>
                        handleupdateSkill(skill?.id, categoryIndex, skillIndex)
                      }
                    />
                  ))
              ) : (
                <></>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Skills;
