import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import DatePicker from "../../Components/Resume/DatePicker";
import {
  addCertification,
  addResumeCertificate,
  deleteResumeCertificate,
  getResumeCertificate,
  removeCertification,
  udpateResumeCertificate,
  updateCertification,
  updateResumeSectionPosition,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const CertificationForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { certificates } = useSelector((state) => state.ResumeBuilder);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [certificate, setCertificate] = useState(certificates);
  const [selectedStartDate, setSelectedStartDate] = useState(yesterday);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [updateDocId, setUpdateDocId] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleAddCertification = () => {
    setIsFormVisible(true);
  };

  const handleEditingIndex = (index, id) => {
    setIsFormVisible(true);
    setSelectedIndex(index);
    setUpdateDocId(id);
    const foundObj = certificate.find((obj, idx) => idx === index);
    reset({
      name: foundObj?.name,
      provider: foundObj?.provider,
    });
    setSelectedStartDate(
      foundObj.start_date ? new Date(foundObj.start_date) : new Date()
    );
    setSelectedEndDate(
      foundObj.end_date ? new Date(foundObj.end_date) : new Date()
    );
  };

  const onCancel = () => {
    setIsFormVisible(false);
    reset({
      name: "",
      provider: "",
    });
    setSelectedStartDate(yesterday);
    setSelectedEndDate(new Date());
  };

  const onSubmit = (data) => {
    const timeZoneOffset = selectedStartDate.getTimezoneOffset() * 60000;
    data.start_date = new Date(selectedStartDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    data.end_date = new Date(selectedEndDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    if (new Date(data.start_date) >= new Date(data.end_date)) {
      error("Start date cannot be greater than or equal to end date");
      return;
    }

    if (new Date(data.start_date) > new Date()) {
      error("Start date cannot be greater than Current Date");
      return;
    }

    if (selectedIndex !== -1) {
      const payload = {
        updateDocId,
        data,
      };
      dispatch(
        udpateResumeCertificate({
          payload,
          onSuccess: (res) => {
            success(res);
            getCertificationInfo();
          },
        })
      );
    } else {
      const payload = data;
      dispatch(
        addResumeCertificate({
          payload,
          id,
          onSuccess: (res) => {
            success(res);
            getCertificationInfo();
          },
        })
      );
    }

    setIsFormVisible(false);
    reset({
      name: "",
      provider: "",
    });
    setSelectedStartDate(yesterday);
    setSelectedEndDate(new Date());
    setSelectedIndex(-1);
  };

  const getCertificationInfo = () => {
    const resumeId = id;
    dispatch(getResumeCertificate({ resumeId, onSuccess: () => {} }));
  };

  const handleRemoveCertification = (payload) => {
    dispatch(
      deleteResumeCertificate({
        payload,
        onSuccess: (res) => {
          success(res);
          getCertificationInfo();
        },
      })
    );
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(certificate);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...certificate];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setCertificate(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          certification: updatedSections,
        },
        onSuccess: () => {
          getCertificationInfo();
        },
        onError: () => {
          setCertificate(previousSections);
        },
      })
    );
  };

  useEffect(() => {
    if (certificates?.length > 0) {
      const sortedCertificates = certificates
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setCertificate(sortedCertificates);
    }
  }, [certificates]);

  return (
    <div className="mydetails rounded-xl p-0">
      {isFormVisible && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="px-3 mb-2 pt-3 border-bottom border-primary"
        >
          <div className="d-flex flex-column mb-1">
            <div className="d-flex mb-1">
              <div className="form-group mr-2 w-50 ">
                <label htmlFor="name">Certification</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder=""
                  {...register("name", {
                    required: "Certification name is required",
                  })}
                />
                {errors.name && (
                  <span className="text-danger">{errors.name.message}</span>
                )}
              </div>
              <div className="form-group ml-3  w-50">
                <label htmlFor="provider">Provider</label>
                <input
                  type="text"
                  id="provider"
                  className="form-control"
                  placeholder=""
                  {...register("provider")}
                />
                {errors.provider && (
                  <span className="text-danger">{errors.provider.message}</span>
                )}
              </div>
            </div>
            <div className="d-flex mb-1 flex-col md:flex-row">
              <div className="form-group mr-2 w-full md:w-50">
                <label htmlFor="startdate">Start Date</label>
                <DatePicker
                  selectedDate={selectedStartDate}
                  onSelect={setSelectedStartDate}
                />
              </div>
              <div className="form-group ml-0 md:ml-3 w-full md:w-50">
                <label htmlFor="enddate">End Date</label>
                <DatePicker
                  selectedDate={selectedEndDate}
                  onSelect={setSelectedEndDate}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end py-2">
              <button
                type="button"
                className="cancel-btn mr-3"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100px",
                  height: "40px",
                }}
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="save-btn"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100px",
                  height: "40px",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
      {!isFormVisible && (
        <div className="professionalsummary-item pt-2 pl-2 border-0 mb-2">
          <button
            className="professionalitem-button pl-2"
            onClick={handleAddCertification}
          >
            <i className="ri-add-circle-line" style={{ fontSize: "20px" }}></i>
            <span className="ml-2" style={{ fontSize: "18px" }}>
              Add Certification
            </span>
          </button>
        </div>
      )}
      {certificate &&
        certificate.map((certificate, index) => (
          <div
            key={index}
            className="professionalsummary-item professionalsummary-item-block py-2 pl-2 pr-3"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="d-flex">
              <p className="mr-2">{certificate.name}</p>
              <p>{certificate.provider}</p>
            </div>
            {hoveredIndex === index && (
              <div className="professionalitem-actions rounded-l-xl w-auto px-2 border">
                <button className="relative group professionalitem-button">
                  <FiChevronUp
                    className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer ${
                      index == 0 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: index - 1,
                        source: index,
                      });
                    }}
                  />
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Move Up
                  </div>
                </button>
                <button className="relative group professionalitem-button">
                  <FiChevronDown
                    className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer  ${
                      index == certificates.length - 1 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: index + 1,
                        source: index,
                      });
                    }}
                  />
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Move Down
                  </div>
                </button>
                <button
                  className="relative group professionalitem-button fs-[18px]"
                  onClick={() => handleEditingIndex(index, certificate?.id)}
                >
                  <i className="ri-edit-line"></i>
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Edit Certificate
                  </div>
                </button>
                <button
                  className="relative group professionalitem-button fs-[18px]"
                  onClick={() => handleRemoveCertification(certificate?.id)}
                >
                  <i className="ri-delete-bin-line"></i>
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Delete Certificate
                  </div>
                </button>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default CertificationForm;
