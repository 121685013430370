import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/header";
import Modal from "../../Components/modal";
import { error } from "../../Utilities/toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Interview from "./components/interview";
import Loader from "../../Components/main-loader";
import { Link } from "react-router-dom";
import { getSimulationsAction } from "../../Redux/DashboardSlice";
import { getResume } from "../../Redux/ResumeBuilderSlice";
import { stopMachineSpeaksGlobal } from "../../Utilities/textToSpeech";
import chevronDoubleRightIcon from "../../assets/icons/chevronDoubleRight.svg";
import { Select } from "antd";
import { getUserSubscription } from "../../Redux/authenticate/authenticate.action";
import QuotaExceeded from "../../Components/quotaExceeded";
const { Option } = Select;

const InterviewSimulation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const { isLoading } = useSelector((state) => state.dashboard);
  const { allResume } = useSelector((state) => state.ResumeBuilder);
  const [isQuestionShow, setIsQuestionShow] = useState(true);
  const { user } = useSelector((state) => state.authenticate);
  const [isRole, setIsRole] = useState(false);
  const [step, setStep] = useState(1);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [description, setDescription] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isFirstQ, setIsFirstQ] = useState(false);
  const [interviewTimer, setInterviewTimer] = useState(false);
  const [audio, setAudio] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [question, setQuestion] = useState([]);
  const [selectedResumeID, setSelectedResumeID] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [conversationLogId, setConversationLogId] = useState(null);
  const [interviewSimulationSessionId, setInterviewSimulationSessionId] =
    useState(null);
  const [activeTab, setActiveTab] = useState("upload_resume");
  const handleChange = (e) => {
    const value = e.target.value;
    setDescription(value);

    if (value.length >= 1000) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleTabClick = (tab) => {
    setUploadedFile(null);
    setSelectedResumeID("");
    setActiveTab(tab);
  };

  const getFile = (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      if (!file) return;
      const fileName =
        file.name.length >= 50
          ? file.name.substring(0, 51) + "..." + file.name.split(".")[1]
          : file.name;

      if (file && file.size > 2.5 * 1024 * 1024) {
        error("File size exceeds 2.5MB, please select a smaller file.");
        return;
      }

      setUploadedFile(file);
    } else {
      error("Only PDF file Supported");
    }
  };

  const submit = (type) => {
    if (!description) {
      error("Job Description is required!");
      return;
    }
    let payload = {
      jobDescription: description,
    };
    if (uploadedFile) {
      payload.resume_file = uploadedFile;
    } else if (selectedResumeID) {
      payload.resumeId = selectedResumeID;
    }
    if (uploadedFile && uploadedFile.size > 2.7 * 1024 * 1024) {
      console.error("The file size exceeds the limit of 2.7 MB.");
      error("The file size exceeds the limit of 2.7 MB.");
    } else {
      if (type == "timer") {
        setInterviewTimer(true);
      }
      dispatch(
        getSimulationsAction({
          payload,
          onSuccess: (resolvedData) => {
            setIsQuestionShow(false);
            setQuestion(resolvedData?.question);
            setIsFirstQ(true);
            setInterviewSimulationSessionId(
              resolvedData?.interviewSimulationSessionId
            );
            setConversationLogId(resolvedData?.conversationLogId);
            setIsModal(true);
          },
          onError: (err) => {
            error(err?.data?.detail);
          },
        })
      );
      // .unwrap()
      // .then((resolvedData) => {
      //   setIsQuestionShow(false);
      //   setQuestion(resolvedData?.question);
      //   setIsFirstQ(true);
      //   setInterviewSimulationSessionId(
      //     resolvedData?.interviewSimulationSessionId
      //   );
      //   setConversationLogId(resolvedData?.conversationLogId);
      //   setIsModal(true);
      // })
      // .catch((err) => {
      //   console.error(err.detail);
      // });
    }
  };

  const getModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => setIsModal(false)}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-lg-7 col-md-7 order-md-1 order-2">
          <div>
            <h4 className="mb-4">Realistic Expectations:</h4>
          </div>
          <p>
            {" "}
            Friendly reminder that perfection isn't expected in interview. It's
            about showing Your potential and fit for the role. Regardless of the
            outcome, you just got better at interviewing from yesterday!
            Congrats.
          </p>
        </div>
        <div className="col-lg-5 col-md-5 order-md-2 order-1 text-center">
          <img
            src="/images/message-img.webp"
            className="img-fluid ic-message-vector"
            alt="message image"
          />
        </div>
      </div>
    );

    const footer = (
      <div>
        <button
          className="ic-btn"
          onClick={() => {
            setIsModal(false);
            setIsRole(true);
          }}
        >
          Okay
        </button>
      </div>
    );

    return (
      <Modal
        show={isModal}
        // header={header}
        content={content}
        footer={footer}
        type="begin"
      />
    );
  };

  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
  }, []);

  useEffect(() => {
    return () => {
      if (audio) {
        stopMachineSpeaksGlobal();
        setAudio(false);
      }
    };
  }, [audio]);

  useEffect(() => {
    if (
      user?.subscriptionQuotas?.interview?.quota_allowed ===
      user?.subscriptionQuotas?.interview?.quota_consumed
    ) {
      setIsQuoteModalOpen(true);
    }
  }, [user?.subscriptionQuotas?.interview]);

  useEffect(() => {
    dispatch(getUserSubscription());
  }, []);

  return (
    <div>
      <Header />
      {isLoading && <Loader />}
      {step === 2 && isFirstQ != true && (
        <div className="resume-back absolute">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className={`z-50 mt-28 ml-8 bg-white rounded-full size-6 p-1 transition-all duration-500  rotate-180 `}
          >
            <img src={chevronDoubleRightIcon} alt="" />
          </button>
        </div>
      )}
      <div
        className="ic-interview-full-body"
        // style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
      >
        {isQuestionShow && (
          <div className="d-flex align-items-center justify-content-center">
            {step === 1 && (
              <>
                <div className="ic-mockInterview">
                  <div className="ic-mock-interview">
                    <div className="grid divide-neutral-200 -mt-4">
                      <div className="flex justify-content-between items-center nexaInterviewTab-container border-b">
                        <ul className="list-reset flex border-b">
                          <li className="-mb-px mr-1">
                            <a
                              className={`inline-block py-2 px-4 font-semibold ${
                                activeTab === "upload_resume"
                                  ? " border-l border-t border-r rounded-t text-primary"
                                  : "text-dark"
                              }`}
                              href="#"
                              onClick={() => handleTabClick("upload_resume")}
                            >
                              Upload Resume
                            </a>
                          </li>
                          <li className="mr-1">
                            <a
                              className={`inline-block py-2 px-4 font-semibold ${
                                activeTab === "existing_resume"
                                  ? " border-l border-t border-r rounded-t text-primary"
                                  : "text-dark"
                              }`}
                              href="#"
                              onClick={() => handleTabClick("existing_resume")}
                            >
                              Select Resume
                            </a>
                          </li>
                        </ul>
                      </div>
                      {activeTab === "upload_resume" ? (
                        <>
                          <div className="py-4">
                            <span className="font-medium">
                              Upload Your Resume For Personal Interview Coming
                              Up
                            </span>
                          </div>
                          <div className="pt-1  group-open:animate-fadeIn">
                            <form action="#">
                              <input
                                type="file"
                                name="file"
                                ref={fileInput}
                                accept=".pdf"
                                hidden
                                onChange={(e) => getFile(e)}
                              />
                              <div
                                className="ic-file-upload"
                                onClick={() => {
                                  fileInput.current.click();
                                }}
                              >
                                <div className="ic-icon">
                                  <img
                                    src="/images/document-upload.png"
                                    alt=""
                                    style={{ width: "auto" }}
                                  />
                                </div>
                                <p className="support-text">
                                  <span className="primary">
                                    Click to Upload{" "}
                                  </span>
                                  or drag and drop
                                </p>
                                <span className="ic-file-size">
                                  {" "}
                                  (Max. File size: 2.5 MB)
                                </span>
                              </div>
                            </form>

                            {uploadedFile && (
                              <div className="ic-loading-area">
                                <ul>
                                  <li>
                                    <div className="file-name">
                                      <div className="file-icon">
                                        <img
                                          src="/images/document-text-area.png"
                                          alt=""
                                        />
                                      </div>
                                      <div className="file-size">
                                        <div>
                                          <p>{uploadedFile.name}</p>
                                          {uploadedFile.size && (
                                            <span>{uploadedFile.size}</span>
                                          )}
                                        </div>
                                        {uploadedFile.loading === 100 && (
                                          <i className="ri-checkbox-circle-fill"></i>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )}

                            {uploadedFile && (
                              <div
                                className="mt-2 ic-btn"
                                onClick={() => setStep(2)}
                              >
                                Next
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="py-4">
                            <span className="font-medium">
                              Select From Existing Resumes?
                            </span>
                          </div>
                          <form className="w-full py-1 mb-3 -mt-3 group-open:animate-fadeIn">
                            <fieldset>
                              <div className="text-gray-800">
                                {allResume?.filter((item) => item?.isCompleted)
                                  .length > 0 ? (
                                  <Select
                                    placeholder="Select Resume"
                                    value={selectedResumeID || "Select Resume"}
                                    onChange={(value) =>
                                      setSelectedResumeID(value)
                                    }
                                    className="w-full mt-1 h-[48px]"
                                  >
                                    {allResume
                                      ?.filter((item) => item?.isCompleted)
                                      ?.map((resume) => {
                                        return (
                                          <Option
                                            key={resume.id}
                                            value={resume.id}
                                          >
                                            {resume?.resume_name
                                              ? `${resume.resume_name}(${
                                                  resume?.iterations
                                                })${
                                                  resume.company_name
                                                    ? ` - ${resume.company_name}`
                                                    : ""
                                                }`
                                              : `${resume?.contact?.first_name} ${resume?.contact?.last_name}`}
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                ) : (
                                  <p className="text-yellow-600 p-1">
                                    You currently don't have any completed
                                    resumes in your profile. Please visit the{" "}
                                    <Link
                                      to="/resumes"
                                      className="text-yellow-600 underline"
                                    >
                                      Resume Page
                                    </Link>{" "}
                                    to create and complete a resume for use in
                                    Nexa.
                                  </p>
                                )}
                              </div>
                            </fieldset>
                          </form>
                          {selectedResumeID !== "" && (
                            <div
                              className="mt-2 ic-btn"
                              onClick={() => setStep(2)}
                            >
                              Next
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {step === 2 && (
              <div className="ic-mockInterview">
                <div className="ic-mock-interview">
                  <h4>
                    Welcome to the Nexa Interview Simulator, you can now step
                    into any interview for any career you want to be the best
                    candidate. Simply fill out the information below and press
                    start!
                  </h4>
                  <div className="text-area">
                    <textarea
                      placeholder="Type your Job Description..."
                      required
                      value={description}
                      onChange={handleChange}
                      maxLength={1000}
                    />
                    {errorMessage && (
                      <p style={{ color: "red" }}>
                        You have reached the maximum character limit of 1000!
                      </p>
                    )}
                  </div>

                  {uploadedFile && (
                    <div className="ic-loading-area">
                      <ul className="position-relative">
                        <li>
                          <div className="file-name">
                            <div className="file-icon">
                              <img
                                src="/images/document-text-area.png"
                                alt=""
                              />
                            </div>
                            <div className="file-size ">
                              <div>
                                <p>{uploadedFile.name}</p>
                                <span v-if="uploadedFile.size">
                                  {uploadedFile.size}
                                </span>
                              </div>
                              <i className="ri-checkbox-circle-fill"></i>

                              <i
                                className="ri-close-circle-fill"
                                style={{
                                  position: "absolute",
                                  right: "-6px",
                                  top: "-17px",
                                  fontSize: "24px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setStep(1);
                                  setUploadedFile(null);
                                }}
                              ></i>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="flex justify-left mt-3 items-center">
                    <input
                      type="checkbox"
                      checked={startTimer}
                      className="h-4 w-4  text-white rounded border-gray-300 "
                      id="timerCheckbox"
                      name="checkbox"
                      onChange={(e) => setStartTimer(!startTimer)}
                    />
                    <label
                      className="font-light text-sm ml-2"
                      for="timerCheckbox"
                    >
                      {" "}
                      Start with Timer <i className="ri-time-line"></i>
                    </label>
                  </div>
                  <div className="flex">
                    <button
                      onClick={() =>
                        !startTimer ? submit("no_timer") : submit("timer")
                      }
                      className="ic-btn mt-3"
                    >
                      Submit Application <i className="ri-file-list-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {isRole && (
          <Interview
            allQuestions={question && question}
            audio={audio}
            setAudio={setAudio}
            setIsFirstQ={setIsFirstQ}
            isFirstQ={isFirstQ}
            conversationLogId={conversationLogId}
            setConversationLogId={setConversationLogId}
            interviewSimulationSessionId={interviewSimulationSessionId}
            setInterviewTimer={setInterviewTimer}
            interviewTimer={interviewTimer}
          />
        )}
      </div>

      {getModal()}

      {isQuoteModalOpen && (
        <QuotaExceeded
          isQuoteModalOpen={isQuoteModalOpen}
          setIsQuoteModalOpen={setIsQuoteModalOpen}
        />
      )}
    </div>
  );
};

export default InterviewSimulation;
