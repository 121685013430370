import React, { useRef } from "react";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import moment from "moment";

const CVminTemplate = ({
  tempRef,
  blured = false,
  profile,
  professionalSummaries,
  workExperience,
  educations,
  projects,
  certificates,
  categories,
  publications,
  interests,
  softskills,
  languages,
}) => {
  const containerRef = useRef(null);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date(); // Default to today if dateString is null or undefined
    return date.toLocaleDateString(undefined, options);
  };
  const exportToImage = () => {
    if (containerRef.current) {
      html2canvas(containerRef.current, {
        useCORS: true,
        scale: 2, // Adjust scale if necessary for better image quality
      }).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "CVminTemplate.png";
        link.click();
      });
    }
  };
  return (
    <div
      className={`cv-child-container ${blured ? "blured" : ""}`}
      ref={tempRef}
    >
      {/* Render profile data */}
      {profile && (
        <header className="cv-header" style={{ fontSize: "12px" }}>
          <p className="cv-name text-uppercase" style={{ fontSize: "14px" }}>
            {profile.first_name} {profile.last_name}
          </p>
          <p className="cv-email text-center" style={{ fontSize: "12px" }}>
            {profile.email && `${profile.email}`}
            {profile.email &&
              (profile.address ||
                profile.city ||
                profile.phone ||
                profile.linked_in ||
                profile.website) &&
              " | "}
            {(profile.address || profile.city) &&
              `${profile.address ?? ""}${
                profile.address && profile.city ? ", " : ""
              }${profile.city ?? ""}`}
            {(profile.address || profile.city) &&
              (profile.phone || profile.linked_in || profile.website) &&
              " | "}
            {profile.phone && `${profile.phone}`}
            {profile.phone && (profile.linked_in || profile.website) && " | "}
            {profile.linked_in && `${profile.linked_in}`}
            {profile.linked_in && profile.website && " | "}
            {profile.website && `${profile.website}`}
          </p>
        </header>
      )}
      {professionalSummaries && professionalSummaries.length > 0 && (
        <section className="cv-professional_summary">
          <h2 className="cv-section-title">Professional Summary</h2>
          {professionalSummaries.map((item, index) => (
            <div
              key={index}
              className="cv-professional_summary-item"
              ref={containerRef}
            >
              <p dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
          ))}
        </section>
      )}
      {certificates && certificates.length > 0 && (
        <section className="cv-certifications">
          <h2 className="cv-section-title">Certifications</h2>
          <ul className="cv-skills-list d-flex justify-content-start">
            {certificates?.map((certificate, index) => (
              <li className="cv-skill_top" key={index}>
                <span className="font-bold text-lg">•</span> {certificate?.name}
              </li>
            ))}
          </ul>
          {/* {certificates.map((certification, index) => (
            <div
              className="cv-certification-item d-flex justify-content-between align-items-center"
              key={index}
            >
              <div>
                <h3 className="cv-item-title mb-0">{certification.name}</h3>
                <p className="cv-item-description text-capitalize">
                  {certification.provider}
                </p>
              </div>
              <p className="cv-item-date">
                {formatDate(certification.start_date)} -{" "}
                {formatDate(certification.end_date)}
              </p>
            </div>
          ))} */}
        </section>
      )}
      {categories && categories.find((item) => item.name === "Top Skills") && (
        <section className="cv-skills">
          <h2 className="cv-section-title">Top Skills</h2>
          {categories.map((category, index) => (
            <React.Fragment key={index}>
              {category.name === "Top Skills" ? (
                <ul className="cv-skills-list">
                  {category.skills &&
                    category.skills.map((skill, index) => (
                      <li className="cv-skill_top" key={index}>
                        <span className="font-bold text-lg">•</span>{" "}
                        {skill.name}
                      </li>
                    ))}
                </ul>
              ) : (
                <ul className="cv-skills-list">
                  {category.skills &&
                    category.skills.map((skill, index) => (
                      <li className="cv-skill" key={index}>
                        {skill.name}
                      </li>
                    ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </section>
      )}
      {workExperience && workExperience.length > 0 && (
        <section className="cv-work-experience">
          <h2 className="cv-section-title">Work Experiences</h2>
          {workExperience.map((experience, index) => (
            <>
              <div
                key={index}
                className="cv-work-experience-item d-flex justify-content-between align-items-center"
              >
                <div className="text-capitalize">
                  <h3 className="cv-item-title mb-0">
                    {experience.company.name}
                  </h3>
                  {experience.roles.map((item, index) => (
                    <p className="cv-item-date mb-0" key={index}>
                      {item.name}
                    </p>
                  ))}
                </div>
                <div className="text-capitalize">
                  {experience.roles.map((item, index) => (
                    <p className="cv-item-date" key={index}>
                      {formatDate(item.started_at)}
                      {item.ended_at == null
                        ? " - present"
                        : ` - ${formatDate(item.ended_at)}`}
                    </p>
                  ))}
                </div>
              </div>
              <div>
                <p
                  className="cv-item-date"
                  dangerouslySetInnerHTML={{
                    __html: experience?.company?.description,
                  }}
                />
              </div>
            </>
          ))}
        </section>
      )}
      {educations && educations.length > 0 && (
        <section className="cv-educations">
          <h2 className="cv-section-title">Educations</h2>
          {educations.map((education, index) => (
            <div
              key={index}
              className="cv-education-item d-flex align-items-center justify-content-between"
            >
              <div>
                <h3>{education.school_name}</h3>
                <p className="cv-item-date">
                  {education.degree && `${education.degree} Degree | `}
                  {education.field && education.field}
                </p>
              </div>
              <div>
                <p className="cv-item-date text-capitalize">
                  {education.ended_at == null
                    ? " present"
                    : `${formatDate(education.ended_at)}`}
                </p>
              </div>
            </div>
          ))}
        </section>
      )}
      {projects && projects.length > 0 && (
        <section className="cv-projects">
          <h2 className="cv-section-title">Projects</h2>
          {projects.map((pro, index) => (
            <div className="cv-project-item" key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="cv-item-title mb-0">{pro.project_name}</h3>
                  <p className="cv-item-date">{pro.organization}</p>
                </div>
                <p className="cv-item-date">
                  {formatDate(pro.start_date)} - {formatDate(pro.end_date)}
                </p>
              </div>
              <p
                className="cv-item-description px-2"
                dangerouslySetInnerHTML={{ __html: pro.additional_information }}
              />
            </div>
          ))}
        </section>
      )}
      {publications && publications.length > 0 && (
        <section className="cv-publications">
          <h2 className="cv-section-title">Publications</h2>
          {publications.map((publication, index) => (
            <div
              className="cv-publication-item d-flex justify-content-between align-items-center"
              key={index}
            >
              <div>
                <h3 className="cv-item-title mb-0">{publication.title}</h3>
                <p className="cv-item-date">{publication.publisher}</p>
              </div>
              <p className="cv-item-description">
                {formatDate(publication.date)}
              </p>
            </div>
          ))}
        </section>
      )}
      {interests && interests.length > 0 && (
        <section className="cv-interests">
          <h2 className="cv-section-title">Interests</h2>
          <ul className="cv-skills-list d-flex justify-content-start">
            {interests.map((interest, index) => (
              <li className="cv-skill_top" key={index}>
                <span className="font-bold text-lg">•</span> {interest}
              </li>
            ))}
          </ul>
        </section>
      )}
      {softskills && softskills.length > 0 && (
        <section className="cv-soft-skills">
          <h2 className="cv-section-title">Soft Skills</h2>
          <ul className="cv-skills-list d-flex justify-content-start">
            {softskills.map((skill, index) => (
              <li className="cv-skill_top" key={index}>
                <span className="font-bold text-lg">•</span> {skill}
              </li>
            ))}
          </ul>
        </section>
      )}
      {languages && languages.length > 0 && (
        <section className="cv-languages">
          <h2 className="cv-section-title">Languages</h2>
          <ul className="cv-skills-list d-flex justify-content-start">
            {languages.map((language, index) => (
              <li className="cv-skill_top" key={index}>
                <span className="font-bold text-lg">•</span> {language}
              </li>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};

// Define prop types
CVminTemplate.propTypes = {
  tempRef: PropTypes.object,
  blured: PropTypes.bool,
  profile: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    phone: PropTypes.string,
    linked_in: PropTypes.string,
    website: PropTypes.string,
  }),
  professionalSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  ),
  workExperience: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          started_at: PropTypes.string,
          ended_at: PropTypes.string,
        })
      ),
    })
  ),
  educations: PropTypes.arrayOf(
    PropTypes.shape({
      school_name: PropTypes.string,
      degree: PropTypes.string,
      field: PropTypes.string,
      ended_at: PropTypes.string,
    })
  ),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      project_name: PropTypes.string,
      organization: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      additional_information: PropTypes.string,
    })
  ),
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      provider: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    })
  ),
  publications: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      publisher: PropTypes.string,
      date: PropTypes.string,
    })
  ),
  interests: PropTypes.arrayOf(PropTypes.string),
  softskills: PropTypes.arrayOf(PropTypes.string),
  languages: PropTypes.arrayOf(PropTypes.string),
};

export default CVminTemplate;
