import React from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";

const QuotaExceeded = ({ isQuoteModalOpen, setIsQuoteModalOpen }) => {
  const navigate = useNavigate();
  return (
    // <ReactModal
    //   isOpen={isQuoteModalOpen}
    //   //   onRequestClose={false}
    //   shouldCloseOnOverlayClick={false} // Prevent closing on outside click
    //   style={{
    //     overlay: {
    //       backgroundColor: "rgba(0, 0, 0, 0.5)",
    //     },
    //     content: {
    //       top: "50%",
    //       left: "50%",
    //       right: "auto",
    //       bottom: "auto",
    //       marginRight: "-50%",
    //       transform: "translate(-50%, -50%)",
    //       padding: "20px",
    //       maxWidth: "700px",
    //       width: "90%",
    //       borderRadius: "10px",
    //       boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    //     },
    //   }}
    // >
    //   <h2 className="text-lg">
    //     Oh no you just used up your free credits for{" "}
    //     {window.location.pathname === "/resumes"
    //       ? "Resume"
    //       : window.location.pathname === "/interview/role/"
    //       ? "Interview Practice"
    //       : window.location.pathname === "/simulator-pro"
    //       ? "Interview Simulator Pro"
    //       : "Interview"}
    //     . Lucky for you, you can upgrade for unlimited use for only $6!
    //   </h2>
    //   <div className="ic-footer-modal d-flex justify-content-end">
    //     <button
    //       onClick={() => {
    //         navigate("/pricing");
    //       }}
    //     >
    //       Upgrade Now
    //     </button>
    //     <button
    //       onClick={() => {
    //         setIsQuoteModalOpen(false);
    //       }}
    //     >
    //       Okay For Now
    //     </button>
    //   </div>
    // </ReactModal>

    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-4">
          Enhance Your Experience
        </h2>
        <p className="text-black-600 text-center mb-6">
          You are currently using a basic version of our{" "}
          {window.location.pathname === "/resumes"
            ? "Resume Tool"
            : window.location.pathname === "/interview/role/"
            ? "Interview Practice Tool"
            : window.location.pathname === "/simulator-pro"
            ? "Interview Simulator Pro Tool"
            : "simulator"}{" "}
          that has delays. You can upgrade for our{" "}
          {window.location.pathname === "/resumes" ? (
            "Resume Tool"
          ) : window.location.pathname === "/interview/role/" ? (
            "Interview Practice Tool"
          ) : window.location.pathname === "/simulator-pro" ? (
            "Interview Simulator Pro Tool"
          ) : window.location.pathname === "/mock-interview" ? (
            <>
              <b>Interview</b> Simulation Tool
            </>
          ) : (
            <>
              <b>REALTIME</b> simulator
            </>
          )}{" "}
          experience.
        </p>
        <div className="flex flex-col gap-3">
          <button
            onClick={() => navigate("/pricing")}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg font-semibold transition-all duration-200"
          >
            Upgrade
          </button>
          <button
            onClick={() => setIsQuoteModalOpen(false)}
            className="w-full bg-gray-100 hover:bg-gray-200 text-gray-800 px-6 py-3 rounded-lg font-semibold transition-all duration-200"
          >
            Continue Anyway
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuotaExceeded;
