import React from "react";
import "./Section3.css";

const Section3 = () => {
  return (
    <div className="default-container-section3">
      <div className="text-wrapper-3">
        <div className="narrow-text-3">
          <p className="text-default-blue-bold-3 text-center mb-2 FiraFontFamily bold-family">
            How Nexa Solves These{" "}
            <span className="text-default-blue-bold-3 highlighted-word FiraFontFamily bold-family">
              CHALLENGES
            </span>
          </p>
        </div>
        <div className="wide-text-3">
          <p className="text-default-black-3 text-center FiraFontFamily">
            Nexa is purpose-built to simplify and enhance career services with:
          </p>
        </div>
      </div>
      <div className="main-container-3">
        <div className="image-side-container-3 zoom-container">
          <img
            src="/images/Bootcamp/TransformBootCampSection3.png"
            alt="Section Illustration"
            className="section3-side-image"
          />
        </div>
        {/* Boxes div */}
        <div className="boxes-container">
          <div className="styled-box-3">
            {/* Add the Image Here */}

            <div className="box-content-3">
              <div className="number-3 FiraFontFamily">01</div>
              <div className="text-content-3">
                <p className="title-3 FiraFontFamily">
                  All-in-One Career Services Platform
                </p>
                <ul className="description-3">
                  <li className="bulletpoint-3 FiraFontFamily">
                    Build and manage resumes effortlessly in one hub.
                  </li>
                  <li className="bulletpoint-3 FiraFontFamily">
                    Practice and simulate interviews with real-time feedback.
                  </li>
                  <li className="bulletpoint-3 FiraFontFamily">
                    Get career guidance from Nexa Voyce, our AI career advisor.
                  </li>
                  <li className="bulletpoint-3 FiraFontFamily">
                    Use Nexa Octagon for simulated job experiences tailored to
                    real-world scenarios.
                  </li>
                  <li className="bulletpoint-3 FiraFontFamily">
                    Automate job applications with Nexa Autopilot.
                  </li>
                  <li className="bulletpoint-3 FiraFontFamily">
                    Navigate jobs with Nexa IQ, your personal AI recruiter.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Repeat styled-box-3 for other sections */}
          <div className="styled-box-3">
            <div className="box-content-3">
              <div className="number-3 FiraFontFamily">02</div>
              <div className="text-content-3">
                <p className="title-3 FiraFontFamily">
                  AI-Powered Job Matching
                </p>
                <p className="description-3 greyish FiraFontFamily">
                  Measure what matters with Untitle-3d’s easy-to-use reports.
                  You can filter, export, and drilldown on the data in a couple
                  clicks.
                </p>
              </div>
            </div>
          </div>

          <div className="styled-box-3">
            <div className="box-content-3">
              <div className="number-3 FiraFontFamily">03</div>
              <div className="text-content-3">
                <p className="title-3 FiraFontFamily">
                  Streamlined Operational Workflows
                </p>
                <p className="description-3 greyish FiraFontFamily">
                  Measure what matters with Untitle-3d’s easy-to-use reports.
                  You can filter, export, and drilldown on the data in a couple
                  clicks.
                </p>
              </div>
            </div>
          </div>

          <div className="styled-box-3">
            <div className="box-content-3">
              <div className="number-3 FiraFontFamily">04</div>
              <div className="text-content-3">
                <p className="title-3 FiraFontFamily">
                  Pre-Vetted Employer Network
                </p>
                <p className="description-3 greyish FiraFontFamily">
                  Measure what matters with Untitle-3d’s easy-to-use reports.
                  You can filter, export, and drilldown on the data in a couple
                  clicks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
