import { useSelector } from "react-redux";

export const cleanObject = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map(cleanObject)
      .filter(
        (item) =>
          item !== null &&
          item !== undefined &&
          (typeof item !== "object" || Object.keys(item).length > 0)
      );
  }

  if (obj && typeof obj === "object") {
    const cleanedObj = {};
    for (const key in obj) {
      const value = obj[key];
      const cleanedValue = cleanObject(value);
      if (
        cleanedValue !== null &&
        cleanedValue !== undefined &&
        (!Array.isArray(cleanedValue) || cleanedValue.length > 0) &&
        (typeof cleanedValue !== "object" ||
          Object.keys(cleanedValue).length > 0)
      ) {
        cleanedObj[key] = cleanedValue;
      }
    }
    return cleanedObj;
  }

  return obj;
};

const useResumeData = () => {
  const {
    profile,
    professionalSummaries,
    educations,
    categories,
    publications,
    certificates,
    projects,
    interests,
    languages,
    softskills,
    skills,
    workExperience,
    additionalSections,
    AiResume,
  } = useSelector((state) => state.ResumeBuilder);

  const existingResume = cleanObject({
    profile,
    professionalSummaries,
    educations,
    categories,
    publications,
    certificates,
    projects,
    interests,
    languages,
    softskills,
    skills,
    workExperience,
    additionalSections,
  });

  const cleanedAiResume = cleanObject(AiResume || {});

  return { existingResume, AiResume: cleanedAiResume };
};

export default useResumeData;
