import React from "react";
import { CompanyOverview } from "./CompanyOverview";
import { JobStatusOverview } from "./JobStatusOverview";

export const JobDetails = ({
  jobProvider,
  logoUrl,
  companyName,
  companyTagline,
  organizationNumber,
  jobStatus,
  jobTonality,
  prescreeningDeadline,
  interviewDeadline,
  howToConduct,
  companyDescription,
}) => {
  return (
    <div className="flex flex-col items-stretch justify-between border-t-[#e6e6e6] sm:border-t-0 grey-2">
      {jobProvider === "smart_screen_jobs" && (
        <>
          <CompanyOverview
            logoUrl={logoUrl}
            companyName={companyName}
            companyTagline={companyTagline}
            organizationNumber={organizationNumber}
          />
          <div className="flex flex-row items-start justify-stretch">
            <div className="flex flex-col items-start w-full">
              <h3 className="mb-2 text-base font-semibold px-4 text-[#4d4d4d]">
                Overview
              </h3>
              <JobStatusOverview
                jobStatus={jobStatus}
                jobTonality={jobTonality}
                prescreeningDeadline={prescreeningDeadline}
                interviewDeadline={interviewDeadline}
                howToConduct={howToConduct}
              />
              <div className="w-full sm:px-4 sm:pb-4">
                <div className="text-sm prose desc-text">
                  {companyDescription}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
