import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../Components/main-loader";

const Roles = ({
  roles,
  selectedRole,
  setRoleCategoryId,
  setTechStack,
  setTechStackIcon,
  tabs,
}) => {
  const { isLoading } = useSelector((state) => state.dashboard);
  const location = useLocation();

  const isMainPage = location.pathname.startsWith("/interview/role/");
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  setTechStack && setTechStack(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getUrl = (name) => {
    let url = "/interview/role/";
    if (selectedRole && selectedRole.name) {
      url += selectedRole.name.replace(" ", "-").toLowerCase();
    }
    if (!name || typeof name !== "string") return "";
    return name.replace(" ", "-").toLowerCase();
  };

  const getRoleCard = (id, name, icon, index) => {
    setTechStackIcon(icon);
    return (
      <div className="ic-interview-items relative">
        <Link
          onClick={() => setRoleCategoryId(id)}
          key={index}
          to={`${getUrl(name)}`}
          className="w-full h-full flex flex-col justify-center items-center gap-2"
          // data-aos="fade-up"
          // data-aos-delay="50"
          // data-aos-duration={index * 200 + 500}
        >
          <div className="ic-icons">
            <img src={icon} className="img-fluid" alt={name} />
          </div>
          <p>{name}</p>
          <i
            className="ri-arrow-right-line ic-right-arrow"
            style={{ fontSize: "22px" }}
            size="30"
          />
        </Link>
      </div>
    );
  };

  return (
    <div className="ic-interviews">
      <div className="container">
        <div className="ic-interview-container">
          <h1 className="text-center text-capitalize text-bold">
            Which Career are you interested in practicing?
          </h1>
          {isMainPage && (
            <div className="flex justify-end mt-4 mb-6">
              <button
                className="inline-flex whitespace-nowrap text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-2xl text-sm px-6 py-2.5 transition-all duration-200 ease-in-out hover:shadow-lg"
                onClick={toggleModal}
              >
                Interview Practice Tutorial
              </button>
            </div>
          )}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="ic-interview-card-container">
            {selectedRole ? (
              selectedRole?.categories
                ?.slice()
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((item, index) =>
                  getRoleCard(
                    item.id,
                    item.name,
                    selectedRole.iconUrl,
                    index
                    // setRoleCategoryId
                  )
                )
            ) : Array.isArray(roles) && roles.length > 0 ? (
              roles
                ?.slice()
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((item, index) =>
                  getRoleCard(
                    item.id,
                    item.name,
                    item.iconUrl,
                    index
                    // setRoleCategoryId
                  )
                )
            ) : (
              <h2 className="text-xl font-semibold text-gray-600">
                There are no roles available
              </h2>
            )}
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out">
          <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-3xl transform transition-transform duration-300 ease-in-out scale-100">
            <div className="flex justify-between items-center p-4 border-b">
              <button
                onClick={toggleModal}
                className="text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                ✖
              </button>
            </div>
            <div className="p-4">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/c45rpuTlYRI?si=GhyaD8dr52romN1F"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Roles;
