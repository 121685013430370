import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/header";
import Loader from "../../Components/main-loader";
import Progress from "../../Components/Progress";
import {
  getCompletedInterviewSessions,
  getCompletedSimulatorCount,
  getkeySkills,
} from "../../Redux/UserProfileSlice";
import CertificationsLicenses from "./components/CertificationsLicenses";
import ExpEduHistory from "./components/ExpEduHistory";
import JobPreference from "./components/JobPreference";
import KeySkillsProficiencyLevels from "./components/KeySkillsProficiencyLevels";
import ShowcaseTalent from "./components/ShowcaseTalent";
import UserInfo from "./components/UserInfo";
import WorkSamplesLinks from "./components/WorkSamplesLinks";

import CongratulationsPopup from "./components/CongratulationsPopup";
const UserProfile = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.UserProfile);
  const { user } = useSelector((state) => state.authenticate);
  const [userProfileInfo, setUserProfileInfo] = useState({
    image: null,
    name: "",
    email: "",
    address: "",
    education: "",
    contact: "",
    target_career: "",
    employment_status: "",
    linkedIn_profile: "",
    resume_feedback_id: "",
  });
  const [activeStep, setActiveStep] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const calculateActiveStep = (percentage) => {
    if (percentage >= 0 && percentage < 20) return 1;
    if (percentage >= 20 && percentage < 40) return 2;
    if (percentage >= 40 && percentage < 60) return 3;
    if (percentage >= 60 && percentage < 80) return 4;
    if (percentage >= 80 && percentage < 90) return 5;
    if (percentage >= 90 && percentage < 100) return 6;
    if (percentage === 100) return 1;
    return 1;
  };

  useEffect(() => {
    if (user.is_badge_first_time) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [user]);

  useEffect(() => {
    dispatch(getCompletedSimulatorCount({ onSuccess: () => {} }));
    dispatch(getCompletedInterviewSessions({ onSuccess: () => {} }));
    dispatch(getkeySkills({ education: user?.education, onSuccess: () => {} }));
    if (user?.profile_completion) {
      setActiveStep(calculateActiveStep(user.profile_completion));
    }
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Header />
      <div
        className="ic-interview-full-body"
        style={{
          // backgroundImage: `url(${"/images/interview-banner.png"})`,
          paddingTop: "6em",
        }}
      >
        <main
          role="main"
          className="ml-sm-auto col-lg-12 px-4"
          style={{
            maxWidth: "1200px", // Constrain the width of the main container
            margin: "0 auto", // Center the container horizontally
            // backgroundImage: `url(${"/images/interview-banner.png"})`,
            backgroundSize: "contain", // Adjust background size to fit the container
            backgroundRepeat: "no-repeat", // Prevent tiling of the background image
            backgroundPosition: "center", // Center the background image
          }}
        >
          <div className="relative min-h-[300px] px-4 sm:px-6 pb-8 flex flex-col items-center bg-gradient-to-br from-blue-50/40 via-white to-blue-50/40">
            {/* Decorative Elements */}
            <div className="absolute inset-0 overflow-hidden -z-10">
              <div
                aria-hidden="true"
                className="absolute h-[30rem] w-[30rem] -right-[15rem] top-0 rounded-full bg-gradient-to-l from-blue-50 to-white/60 blur-3xl transform -translate-y-[60%] opacity-40"
              />
              <div
                aria-hidden="true"
                className="absolute h-[30rem] w-[30rem] -left-[15rem] bottom-0 rounded-full bg-gradient-to-r from-blue-50 to-white/60 blur-3xl transform translate-y-[60%] opacity-40"
              />
            </div>

            {/* Main Content */}
            <div className="w-full max-w-[1000px] mx-auto pt-8 flex flex-col items-center">
              {/* Text Section */}
              <div className="w-full max-w-[800px] mx-auto flex flex-col items-center text-center">
                <h1 className="text-[2rem] md:text-[2.5rem] lg:text-[2.75rem] leading-[1.2] font-bold text-center mb-6">
                  <span className="text-gray-900">
                    Showcase Your Skills To{" "}
                  </span>
                  <br className="hidden sm:block" />
                  <span className="bg-gradient-to-r from-blue-700 via-blue-600 to-blue-800 bg-clip-text text-transparent">
                    Directly Be Seen By Hiring Managers
                  </span>
                </h1>

                <div className="space-y-4 mb-8">
                  <p className="text-base md:text-lg leading-[1.6] text-black">
                    Welcome to the Nexa SkillPrint® — where your unique talents
                    take center stage. Here, you're{" "}
                    <span className="text-blue-700 font-semibold">NOT</span>{" "}
                    just another resume or degree.
                  </p>

                  <p className="text-base md:text-lg leading-[1.6] text-black">
                    With Nexa, showcase why you{" "}
                    <span className="text-blue-700 font-semibold">ARE</span> the
                    perfect candidate and prove it. We'll connect your
                    outstanding achievements directly with employers seeking
                    exactly what you offer.
                  </p>
                </div>

                {/* Centered Button */}
                <button
                  onClick={toggleModal}
                  className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-2xl text-sm px-5 py-2.5 w-full sm:w-auto min-w-[150px] max-w-[230px] transform hover:scale-105 transition-transform duration-200 ease-out shadow-lg hover:shadow-xl"
                >
                  My Profile Tutorial
                </button>
              </div>
            </div>

            <div
              aria-hidden="true"
              className="absolute bottom-0 h-px w-full bg-gradient-to-r from-transparent via-gray-200 to-transparent"
            />

            {/* Mobile-first responsive fixes */}
            <div className="block sm:hidden h-20"></div>

            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out">
                <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-3xl transform transition-transform duration-300 ease-in-out scale-100">
                  <div className="flex justify-between items-center p-4 border-b">
                    <button
                      onClick={toggleModal}
                      className="text-gray-400 hover:text-gray-600 focus:outline-none"
                    >
                      ✖
                    </button>
                  </div>
                  <div className="p-4">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/Stxeu11kDfA?si=ApGBZRW7T_pK0m9Y"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="rounded-lg"
                    ></iframe>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="career_slide-cover">
            <div className="ic-card_container">
              <h2 className="text-start text-3xl mb-6">
                <h1 className="h2">
                  {activeStep === 1
                    ? "Personal Information"
                    : activeStep === 2
                    ? "Select Job Opportunities You Are Open For"
                    : activeStep === 3
                    ? "Key Skills & Proficiency Levels"
                    : activeStep === 4
                    ? "Certifications & Licenses"
                    : activeStep === 5
                    ? "Work Samples & Project Links"
                    : activeStep === 6
                    ? "Key Work Experience History"
                    : "Showcase Your Talent"}
                </h1>
                {activeStep === 5 && (
                  <p className="-mt-3">
                    Highlight Your Key Achievements In Your Previous Work
                    Experience
                  </p>
                )}
                {activeStep === 6 && (
                  <p className="-mt-2 leading-7">
                    Show hiring managers you are AS QUALIFIED as YOU SAY you are
                    with REAL proof. Complete a Nexa certified interview
                    practice round or simulation related to the job you are
                    looking for with the best score possible. Don't just talk
                    the talk but walk the walk!
                  </p>
                )}
              </h2>

              <div className="w-full flex justify-center items-center mb-4">
                <Progress percentage={`${user?.profile_completion}%`} />
              </div>
              {activeStep === 1 && (
                <UserInfo
                  userProfileInfo={userProfileInfo}
                  setUserProfileInfo={setUserProfileInfo}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 2 && (
                <JobPreference
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}

              {activeStep === 3 && (
                <KeySkillsProficiencyLevels
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}

              {activeStep === 4 && (
                <CertificationsLicenses
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}

              {activeStep === 5 && (
                <WorkSamplesLinks
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 6 && (
                <ExpEduHistory
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 7 && (
                <ShowcaseTalent
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
            </div>
          </div>
        </main>
      </div>
      <CongratulationsPopup isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
};

export default UserProfile;
