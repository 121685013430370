import React from "react";
import feedbackIcon from "../../assets/icons/feedback.png";
import { MdDownload, MdEdit, MdOutlineDelete } from "react-icons/md";
import { warning } from "../../Utilities/toast";
import PdfTemplate from "./PdfTemplate";
import { cleanObject } from "../../hook/useResumeData";
import { pdf } from "@react-pdf/renderer";

const MiniResumeActions = ({
  item,
  handleResumeClick,
  setSelectedDeleteResumeId,
  setIsOpenConfirmationModal,
  setModal,
  handleGetFeedback,
  setSelectedResume,
}) => {
  const handleDownload = async () => {
    const existingResume = cleanObject({
      profile: item?.contact,
      professionalSummaries: item?.blurbs,
      educations: item?.educations,
      categories: item?.skills,
      publications: item?.publications,
      certificates: item?.certifications,
      projects: item?.projects,
      interests: item?.interests?.length > 0 ? item?.interests[0] : [],
      languages: item?.languages?.length > 0 ? item?.languages[0] : [],
      softskills: item?.soft_skills?.length > 0 ? item?.soft_skills[0] : [],
      skills: item?.skills,
      workExperience: item?.work_experience,
      additionalSections: item?.additional_sections,
    });
    const doc = (
      <PdfTemplate resumeData={existingResume} getSingleResumeDoc={item} />
    );
    const pdfBlob = await pdf(doc).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = `${item?.resume_name}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        left: "50%",
        bottom: "10px",
        transform: "translateX(-50%)",
      }}
      className="w-max"
    >
      <h2 className="name leading-none mb-1 line-clamp-1">
        {item?.resume_name
          ? `${item.resume_name}${
              item.company_name ? ` - ${item.company_name}` : ""
            }`
          : `${item?.contact?.first_name} ${item?.contact?.last_name}`}
      </h2>
      <p className="text-center text-md leading-none">{item?.iterations}</p>
      <div>
        <button
          onClick={() => handleResumeClick(item.id)}
          style={{
            border: "none",
            background: "none",
            cursor: "pointer",
            color: "blue",
          }}
          className="relative group"
        >
          <MdEdit style={{ fontSize: "15px", color: "#2B6BE2" }} />
          <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max mr-6 left-1/2 transform -translate-x-1/2">
            Edit Item
          </div>
        </button>
        <button className="relative group">
          <MdOutlineDelete
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDeleteResumeId(item.id);
              setIsOpenConfirmationModal(true);
            }}
            style={{
              marginLeft: "8px",
              marginTop: "4px",
              cursor: "pointer",
              color: "red",
              fontSize: "15px",
            }}
          />
          <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max mr-6 left-1/2 transform -translate-x-1/2">
            Delete Resume
          </div>
        </button>
        {/* {item?.isCompleted && ( */}
        <button
          onClick={(e) => {
            if (!item?.isCompleted) {
              const missingSections = [
                !item?.contact && "personal information",
                item?.blurbs?.length == 0 && "profile summary",
                item?.educations?.length == 0 && "education",
                item?.work_experience?.length == 0 && "work experience",
              ].filter(Boolean);
              if (missingSections.length) {
                warning(
                  `Complete the ${missingSections.join(", ")} to get feedback.`
                );
              } else {
                warning("Job description is mandatory for get the feedback");
                setModal(true);
                setSelectedResume(item.id);
              }
            } else {
              e.stopPropagation();
              handleGetFeedback(item.id);
            }
          }}
          // disabled={!item?.isCompleted}
          color="success"
          className={`text-success
       cursor-pointer relative group
      `}
        >
          <img
            src={feedbackIcon}
            alt="Feedback"
            height={15}
            width={15}
            style={{
              marginLeft: "8px",
              marginTop: "4px",
              fontSize: "15px",
            }}
          />
          <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max mr-6 left-1/2 transform -translate-x-1/2">
            Get Feedback
          </div>
        </button>
        <button className="relative group">
          <MdDownload
            onClick={(e) => {
              if (!item?.isCompleted) {
                const missingSections = [
                  !item?.contact && "personal information",
                  item?.blurbs?.length == 0 && "profile summary",
                  item?.educations?.length == 0 && "education",
                  item?.work_experience?.length == 0 && "work experience",
                ].filter(Boolean);
                if (missingSections.length) {
                  warning(
                    `Complete the ${missingSections.join(
                      ", "
                    )} to get download document`
                  );
                }
              } else {
                e.stopPropagation();
                handleDownload();
              }
            }}
            style={{
              marginLeft: "8px",
              marginTop: "4px",
              cursor: "pointer",
              color: "blue",
              fontSize: "15px",
            }}
          />
          <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max mr-6 left-1/2 transform -translate-x-1/2">
            Download Resume
          </div>
        </button>
      </div>
    </div>
  );
};

export default MiniResumeActions;
