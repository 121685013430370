import React from "react";
import dayjs from "dayjs";

export const JobStatusOverview = ({
  jobStatus,
  jobTonality,
  prescreeningDeadline,
  interviewDeadline,
  howToConduct,
}) => (
  <div className="flex w-full flex-row items-center border-y border-t-[#e6e6e6] sm:border-none sm:px-4 mb-3">
    <div className="flex w-full flex-col items-stretch rounded-[8px] border-[#e6e6e6] bg-[#f5f5f5] sm:border">
      <div className="flex flex-row items-center">
        <div className="flex w-full flex-col items-start justify-between border-r border-r-[#e6e6e6] pt-2 pl-3 pr-2">
          <div className="text-sm text-[#4d4d4d]">Status</div>
          <div className="flex h-[36px] items-center text-sm font-semibold">
            <span
              className={`question-tags5 mr-3 text-white ${
                jobStatus === "open" ? "bg-success" : "bg-danger"
              }`}
            >
              {jobStatus}
            </span>
          </div>
        </div>
        <div className="flex w-full flex-col items-start px-3 pt-2">
          <div className="text-sm text-[#4d4d4d]">Interview Screening Type</div>
          <div className="flex h-[36px] flex-row items-center">
            <div className="flex text-sm font-semibold">{jobTonality}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center">
        <div className="flex w-2/4 flex-col items-start justify-between border-r border-r-[#e6e6e6] pt-2 pl-3 pr-2">
          <div className="text-sm text-[#4d4d4d]">Pre Screening Deadline</div>
          <div className="flex h-[36px] items-center text-sm font-semibold">
            {dayjs(prescreeningDeadline).format("DD MMM YYYY")}
          </div>
        </div>
        <div className="flex w-2/4 flex-col items-start px-3 pt-2">
          <div className="text-sm text-[#4d4d4d]">Interview Deadline</div>
          <div className="flex h-[36px] flex-row items-center">
            <div className="flex text-sm font-semibold">
              {dayjs(interviewDeadline).format("DD MMM YYYY")}
            </div>
          </div>
        </div>
      </div>

      {howToConduct && (
        <div className="w-full flex items-center justify-between border-t border-t-[#e6e6e6] pl-3 pr-2">
          <div className="text-sm text-[#4d4d4d]">How To Conduct:</div>
          <div className="flex h-[36px] items-center text-sm font-semibold">
            {howToConduct}
          </div>
        </div>
      )}
    </div>
  </div>
);
