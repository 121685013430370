import React, { useEffect, useState } from "react";
import DatePicker from "../../Components/Resume/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPublications,
  RemovePublication,
  UpdatePublications,
  addResumePublication,
  deleteResumePublication,
  updateResumeSectionPosition,
} from "../../Redux/ResumeBuilderSlice";
// import {
//   setActiveField,
//   showPublicationForm,
// } from "../education/EducationFormSlice";
import {
  getResumePublication,
  updateResumePublication,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const PublicationForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { publications } = useSelector((state) => state.ResumeBuilder);
  const [selectedpubDate, setSelectedPubDate] = useState(new Date());
  const [Publications, setPublications] = useState([]);
  useEffect(() => {
    setPublications(publications);
  }, [publications]);
  const fetchPublications = () => {
    const ID = id;
    dispatch(getResumePublication({ ID, onSuccess: () => {} }));
  };
  const [EditingIndex, setEditingIndex] = useState(null);
  const [FormVisible, setFormVisible] = useState(false);
  const [HoveredIndex, sethoveredIndex] = useState(null);
  const [Newpublication, setNewPublication] = useState("");
  const [NewPublisher, setNewPublisher] = useState("");
  const [publicationID, setpublicationID] = useState("");
  const [publicationsState, setPublicationsState] = useState([]);
  function onPublicationCancel() {
    setFormVisible(!FormVisible);
    setEditingIndex(null);
    setNewPublication("");
    setNewPublisher("");
    setSelectedPubDate(new Date());
  }
  const toggleNewPublication = () => {
    setFormVisible(!FormVisible);
    setEditingIndex(null);
    setNewPublication("");
    setNewPublisher("");
    setSelectedPubDate(new Date());
  };
  const handlePlusPublication = (event) => {
    event.preventDefault();
    if (Newpublication.trim() !== "" && NewPublisher.trim() !== "") {
      if (new Date(selectedpubDate) > new Date()) {
        error("Start date cannot be greater than Current Date");
        return;
      }
      const timeZoneOffset = selectedpubDate.getTimezoneOffset() * 60000;

      const ID = id;
      const payload = {
        title: Newpublication,
        publisher: NewPublisher,
        date: new Date(selectedpubDate - timeZoneOffset)
          .toISOString()
          .split("T")[0],
      };
      dispatch(
        addResumePublication({
          ID,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchPublications();
          },
        })
      );
      setNewPublication("");
      setNewPublisher("");
      setSelectedPubDate(new Date());
      setFormVisible(false);
    } else {
      error("All Fields are mandatory");
    }
  };
  const handleRemove = (ID) => {
    dispatch(
      deleteResumePublication({
        ID,
        onSuccess: (res) => {
          success(res);
          fetchPublications();
        },
      })
    );
  };

  const handleEdit = (ID, index) => {
    setpublicationID(ID);
    setEditingIndex(index);
    setNewPublication(Publications[index].title);
    setNewPublisher(Publications[index].publisher);
    setSelectedPubDate(
      Publications[index].date ? new Date(Publications[index].date) : new Date()
    );
    setFormVisible(true);
  };
  const handleSaveUpdatedPublication = (event) => {
    event.preventDefault();
    if (Newpublication !== "") {
      if (new Date(selectedpubDate) > new Date()) {
        error("Start date cannot be greater than Current Date");
        return;
      }
      const timeZoneOffset = selectedpubDate.getTimezoneOffset() * 60000;
      const ID = publicationID;
      const payload = {
        title: Newpublication,
        publisher: NewPublisher,
        date: new Date(selectedpubDate - timeZoneOffset)
          .toISOString()
          .split("T")[0],
      };
      dispatch(
        updateResumePublication({
          ID,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchPublications();
          },
        }),
        setPublications("")
      );
      setEditingIndex(null);
      setNewPublication("");
      setNewPublisher("");
      setSelectedPubDate(new Date());
      setFormVisible(false);
    } else {
      error("All Fields are mandatory");
    }
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(publicationsState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...publicationsState];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setPublicationsState(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          publications: updatedSections,
        },
        onSuccess: () => {
          fetchPublications();
        },
        onError: () => {
          setPublicationsState(previousSections);
        },
      })
    );
  };
  useEffect(() => {
    if (publications?.length > 0) {
      const sortedPublications = publications
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setPublicationsState(sortedPublications);
    }
  }, [publications]);
  return (
    <div className="mydetails rounded-xl px-0 pb-0">
      {FormVisible && (
        <form
          onSubmit={(e) =>
            EditingIndex !== null
              ? handleSaveUpdatedPublication(e)
              : handlePlusPublication(e)
          }
          className="px-3 mb-2 pt-2 border-bottom border-primary"
        >
          <div className="d-flex flex-column mb-1">
            <div className="form-group w-100">
              <label htmlFor="publication">Publication</label>
              <input
                type="text"
                id="publication"
                className="form-control"
                placeholder=""
                value={Newpublication}
                // onFocus={() => dispatch(showPublicationForm())}
                onChange={(e) => setNewPublication(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex mb-1 flex-col md:flex-row">
            <div className="form-group mr-2 w-full md:w-50">
              <label htmlFor="publisher">Publisher</label>
              <input
                type="text"
                id="publisher"
                className="form-control"
                placeholder="Meta"
                value={NewPublisher}
                // onFocus={() => dispatch(showPublicationForm())}
                onChange={(e) => setNewPublisher(e.target.value)}
              />
            </div>
            <div className="form-group ml-0 md:ml-3 w-full md:w-50">
              <label htmlFor="date">Date</label>
              <DatePicker
                selectedDate={selectedpubDate}
                onSelect={setSelectedPubDate}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end py-2">
            <button
              type="button"
              className="cancel-btn mr-3"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onPublicationCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
            >
              {EditingIndex !== null ? "Update" : "Save"}
            </button>
          </div>
        </form>
      )}
      {!FormVisible && (
        <div className="professionalsummary-item px-2 pb-1 border-bottom border-0">
          <button
            className="professionalitem-button pl-2"
            onClick={toggleNewPublication}
          >
            <i className="ri-add-circle-line" style={{ fontSize: "20px" }}></i>
            <span className="ml-2" style={{ fontSize: "18px" }}>
              Add Publication
            </span>
          </button>
        </div>
      )}
      {publicationsState &&
        publicationsState
          .filter((publication, index) => EditingIndex !== index)
          .map((publication, index) => (
            <div
              key={index}
              className="professionalsummary-item professionalsummary-item-block p-2 w-auto"
              onMouseEnter={() => sethoveredIndex(index)}
              onMouseLeave={() => sethoveredIndex(null)}
            >
              <div className="d-flex">
                <p className="mr-2">{publication.title}</p>
                <p>{publication.publisher}</p>
              </div>
              {HoveredIndex === index && (
                <div className="professionalitem-actions rounded-l-xl w-auto px-2 border">
                  <button className="relative group professionalitem-button">
                    <FiChevronUp
                      className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer ${
                        index == 0 ? "hidden" : ""
                      }`}
                      onClick={() => {
                        handleOnDragEnd({
                          destination: index - 1,
                          source: index,
                        });
                      }}
                    />
                    <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                      Move Up
                    </div>
                  </button>
                  <button className="relative group professionalitem-button">
                    <FiChevronDown
                      className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer  ${
                        index == publicationsState.length - 1 ? "hidden" : ""
                      }`}
                      onClick={() => {
                        handleOnDragEnd({
                          destination: index + 1,
                          source: index,
                        });
                      }}
                    />
                    <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                      Move Down
                    </div>
                  </button>
                  <button
                    className="professionalitem-button"
                    onClick={() => handleEdit(publication.id, index)}
                  >
                    <i
                      className="ri-edit-line relative group"
                      style={{ fontSize: "18px" }}
                    >
                      <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                        Edit Publication
                      </div>
                    </i>
                  </button>
                  <button
                    className="professionalitem-button"
                    onClick={() => handleRemove(publication.id)}
                  >
                    <i
                      className="ri-delete-bin-line relative group"
                      style={{ fontSize: "18px" }}
                    >
                      <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                        Delete Publication
                      </div>
                    </i>
                  </button>
                </div>
              )}
            </div>
          ))}
    </div>
  );
};

export default PublicationForm;
