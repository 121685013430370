import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { success, error } from "../../../Utilities/toast";
import {
  addResumeSoftSkills,
  getResumeSoftSkills,
  updateResumeSectionPosition,
  updateResumeSoftSkills,
} from "../../../Redux/ResumeBuilderSlice";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
const SoftSkills = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { softskills } = useSelector((state) => state.ResumeBuilder);
  const [skills, setskills] = useState([]);
  const [softskillId, setsoftskillId] = useState("");
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  useEffect(() => {
    if (softskills?.skills?.length > 0 || softskills?.id) {
      setskills(softskills?.skills);
      setsoftskillId(softskills?.id);
    }
  }, [softskills]);
  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setskills([...skills, value]);
    e.target.value = "";
    setShowPlaceholder(false);
  };

  const handleRemoveChip = (index) => {
    setskills(skills.filter((_, i) => i !== index));
  };
  const fetchInterests = () => {
    const resumeId = id;
    dispatch(getResumeSoftSkills({ resumeId, onSuccess: () => {} }));
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(skills);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...skills];

    setskills(reorderedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          skill: [{ id: softskillId, data: reorderedSections }],
        },
        onSuccess: () => {
          fetchInterests();
        },
        onError: () => {
          setskills(previousSections);
        },
      })
    );
  };
  const update = () => {
    const inputField = document.querySelector(".tags-input");
    const value = inputField.value.trim();
    if (value) {
      setskills([...skills, value]);
      inputField.value = "";
    }
    // if (skills.length === 0 && !value) {
    //   error("Please add atleast one soft skill and press enter to add");
    //   return;
    // }
    const payload = {
      skills: [...skills, value].filter(Boolean),
    };
    if (softskillId) {
      dispatch(
        updateResumeSoftSkills({
          softskillId,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchInterests();
          },
        })
      );
    } else {
      const resumeId = id;
      dispatch(
        addResumeSoftSkills({
          resumeId,
          payload,
          onSuccess: (res) => {
            if (skills.length > 0) {
              success(res);
            }
            fetchInterests();
          },
        })
      );
    }
  };
  return (
    <div className="mydetails rounded-xl px-0 pb-0">
      <div className="row mt-4 pb-4 px-2">
        <div className="col-md-12">
          <div
            className="tags-input-container px-0 py-1"
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {skills?.length > 0 &&
              skills?.map((chip, index) => (
                <div
                  key={index}
                  className="chip"
                  style={{
                    margin: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {chip}
                  <button
                    className={` remove-button  cursor-pointer  items-center justify-center ${
                      index == 0 ? "hidden" : "flex"
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: index - 1,
                        source: index,
                      });
                    }}
                  >
                    <FiChevronUp className={`text-white`} />
                  </button>
                  {/* Lower Arrow */}
                  <button
                    className={` remove-button  cursor-pointer  items-center justify-center ${
                      index == skills.length - 1 ? "hidden" : "flex"
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: index + 1,
                        source: index,
                      });
                    }}
                  >
                    <FiChevronDown className={`text-white  `} />
                  </button>
                  <button
                    className="remove-button"
                    //   style={{ marginLeft: '5px' }}
                    onClick={() => handleRemoveChip(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            <input
              type="text"
              className="tags-input"
              onKeyDown={handleKeyDown}
              placeholder={
                skills?.length === 0 ? "Type Soft Skill and press Enter" : ""
              }
              style={{ flex: "1", margin: "5px", minWidth: "150px" }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end py-2 px-2">
        <button
          onClick={() => update()}
          className="save-btn mr-1"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            width: "100px",
            height: "40px",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default SoftSkills;
