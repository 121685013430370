import React, { useEffect, useMemo, useRef, useState } from "react";
import { Bold, Italic, Underline } from "lucide-react";

import { useSelector } from "react-redux";
import PersonalInformation from "./ResumePreview/PersonalInformation";
import ProfessionalSummary from "./ResumePreview/ProfessionalSummary";
import Certificates from "./ResumePreview/Certificates";
import TopSkills from "./ResumePreview/TopSkills";
import WorkExperience from "./ResumePreview/WorkExperience";
import Educations from "./ResumePreview/Educations";
import AdditionalSections from "./ResumePreview/AdditionalSections";
import Publications from "./ResumePreview/Publications";
import Projects from "./ResumePreview/Projects";
import SoftSkills from "./ResumePreview/SoftSkills";
import Languages from "./ResumePreview/Languages";
import Interests from "./ResumePreview/Interests";
import {
  deleteResumePosition,
  getDeletedSections,
  getSingleResume,
  updateResumePosition,
} from "../../Redux/ResumeBuilderSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ReactModal from "react-modal";
import { get } from "react-hook-form";
import { MdClose, MdDone, MdOutlineModeEdit } from "react-icons/md";
const CvTemplate = ({ tempRef, blured = false }) => {
  const { id } = useParams();
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const {
    professionalSummaries,
    workExperience,
    educations,
    projects,
    certificates,
    profile,
    categories,
    publications,
    interests,
    softskills,
    languages,
    getSingleResumeDoc,
  } = useSelector((state) => state.ResumeBuilder);
  const [sectionsConfig, setSectionConfigs] = useState([]);
  console.log("sectionsConfig: ", sectionsConfig);
  const [removeSection, setRemoveSection] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [activeField, setActiveField] = useState(null);
  const [selectedSectionKey, setSelectedSectionKey] = useState("");
  const [hoveredField, setHoveredField] = useState(null);
  const editableRefs = useRef({});
  const handleFieldClick = (index, key) => {
    if (activeField !== null && activeField !== index) submitChanges();
    setSelectedSectionKey(key);
    setActiveField(index);
    setTimeout(() => editableRefs.current[index]?.focus(), 0);
  };
  const handleFieldInput = (index, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[index];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setSectionConfigs((prev) =>
        prev.map((section) =>
          section?.key === sectionsConfig[index].key
            ? { ...section, title: value }
            : section
        )
      );
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    } else {
      setSectionConfigs((prev) =>
        prev.map((section) =>
          section?.key === sectionsConfig[index].key
            ? { ...section, title: value }
            : section
        )
      );
    }
  };
  const submitChanges = () => {
    if (!selectedSectionKey) return;
    const originalSection = getSingleResumeDoc?.positions?.find(
      (c) => c.key === selectedSectionKey
    );
    const updatedSection = sectionsConfig.find(
      (c) => c.key === selectedSectionKey
    );
    const hasChanges =
      (originalSection &&
        originalSection?.title !== updatedSection?.title.trim()) ||
      originalSection?.style !== updatedSection?.style?.trim();

    if (hasChanges) {
      setActiveField(null);
      setHoveredField(null);
      const previousSections = [...sectionsConfig];
      dispatch(
        updateResumePosition({
          resumeId: id,
          payload: sectionsConfig?.map(({ component, ...rest }) => rest),
          onSuccess: () => {
            dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
          },
          onError: () => {
            setSectionConfigs(previousSections);
          },
        })
      );
    } else {
      setSelectedSectionKey("");
      setActiveField(null);
      setHoveredField(null);
    }
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const reorderedSections = Array.from(sectionsConfig);
    const [removed] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, removed);
    const previousSections = [...sectionsConfig];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setSectionConfigs(updatedSections);
    dispatch(
      updateResumePosition({
        resumeId: id,
        payload: updatedSections.map(({ component, ...rest }) => rest),
        onSuccess: () => {
          dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setSectionConfigs(previousSections);
        },
      })
    );
  };
  // Memoize the section components list
  const sectionComponents = useMemo(
    () => [
      {
        key: "details",
        component: (
          <PersonalInformation
            profile={profile}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "professionalsummary",
        component: (
          <ProfessionalSummary
            professionalSummaries={professionalSummaries}
            getSingleResumeDoc={getSingleResumeDoc}
            containerRef={containerRef}
          />
        ),
      },
      {
        key: "certification",
        component: (
          <Certificates
            certificates={certificates}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "skills",
        component: (
          <TopSkills
            categories={categories}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "workexperience",
        component: (
          <WorkExperience
            workExperience={workExperience}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "education",
        component: (
          <Educations
            educations={educations}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "project",
        component: (
          <Projects
            projects={projects}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "publications",
        component: (
          <Publications
            publications={publications}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "interests",
        component: (
          <Interests
            interests={interests}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "skill",
        component: (
          <SoftSkills
            softskills={softskills}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
      {
        key: "language",
        component: (
          <Languages
            languages={languages}
            getSingleResumeDoc={getSingleResumeDoc}
          />
        ),
      },
    ],
    [
      profile,
      professionalSummaries,
      certificates,
      workExperience,
      educations,
      projects,
      publications,
      interests,
      softskills,
      languages,
      getSingleResumeDoc,
      containerRef,
      categories,
    ]
  );

  // Derive updated sections using useMemo
  const updatedSections = useMemo(() => {
    if (!getSingleResumeDoc?.positions) return [];

    return getSingleResumeDoc.positions
      .filter((position) => !position.isDeleted)
      .map((position) => {
        if (!position.additional) {
          const matchingComponent = sectionComponents.find(
            (comp) => comp.key === position.key
          );
          return matchingComponent
            ? {
                ...position,
                component: matchingComponent.component,
                style: position.style || "",
              }
            : {
                ...position,
                style: position.style || "",
              };
        }

        const matchingAdditional = getSingleResumeDoc.additional_sections?.find(
          (item) => item.identity_key === position.key
        );

        return matchingAdditional
          ? {
              ...position,
              style: position.style || "",
              component: (
                <AdditionalSections
                  sections={matchingAdditional}
                  getSingleResumeDoc={getSingleResumeDoc}
                />
              ),
            }
          : position;
      })
      .sort((a, b) => a.position - b.position);
  }, [getSingleResumeDoc, sectionComponents]);

  // Update section configs when updatedSections changes
  useEffect(() => {
    setSectionConfigs(updatedSections);
  }, [updatedSections, setSectionConfigs]);
  // useEffect(() => {
  //   let sectionComponents = [
  //     {
  //       key: "details",
  //       component: (
  //         <PersonalInformation
  //           profile={profile}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "professionalsummary",
  //       component: (
  //         <ProfessionalSummary
  //           professionalSummaries={professionalSummaries}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //           containerRef={containerRef}
  //         />
  //       ),
  //     },
  //     {
  //       key: "certification",
  //       component: (
  //         <Certificates
  //           certificates={certificates}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "skills",
  //       component: (
  //         <TopSkills
  //           categories={categories}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "workexperience",
  //       component: (
  //         <WorkExperience
  //           workExperience={workExperience}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "education",
  //       component: (
  //         <Educations
  //           educations={educations}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "project",
  //       component: (
  //         <Projects
  //           projects={projects}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "publications",
  //       component: (
  //         <Publications
  //           publications={publications}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "interests",
  //       component: (
  //         <Interests
  //           interests={interests}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "skill",
  //       component: (
  //         <SoftSkills
  //           softskills={softskills}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //     {
  //       key: "language",
  //       component: (
  //         <Languages
  //           languages={languages}
  //           getSingleResumeDoc={getSingleResumeDoc}
  //         />
  //       ),
  //     },
  //   ];
  //   const updatedSections = getSingleResumeDoc?.positions
  //     .filter((position) => position.isDeleted === false)
  //     .map((position) => {
  //       if (!position.additional) {
  //         const matchingComponent = sectionComponents.find(
  //           (component) => component.key === position.key
  //         );
  //         if (matchingComponent) {
  //           return {
  //             ...position,
  //             icon: matchingComponent.icon,
  //             component: matchingComponent.component,
  //           };
  //         }
  //       } else {
  //         const matchingAdditional =
  //           getSingleResumeDoc?.additional_sections?.find(
  //             (additionalItem) => additionalItem.heading === position.title
  //           );
  //         if (matchingAdditional) {
  //           return {
  //             ...position,
  //             component: (
  //               <AdditionalSections
  //                 section={matchingAdditional}
  //                 getSingleResumeDoc={getSingleResumeDoc}
  //               />
  //             ),
  //           };
  //         }
  //       }
  //       return position;
  //     });
  //   const sortedSections = updatedSections?.sort(
  //     (a, b) => a.position - b.position
  //   );
  //   setSectionConfigs(sortedSections);
  // }, [
  //   getSingleResumeDoc,
  //   professionalSummaries,
  //   certificates,
  //   profile,
  //   workExperience,
  //   educations,
  //   projects,
  //   publications,
  //   interests,
  //   softskills,
  //   languages,
  // ]);
  useEffect(() => {
    if (getSingleResumeDoc?.font_style) {
      // Generate the Google Fonts link URL
      const fontName = getSingleResumeDoc.font_style.replace(" ", "+");
      const fontLinkHref = `https://fonts.googleapis.com/css2?family=${fontName}&display=swap`;

      // Check if the font link already exists to avoid duplicates
      let fontLink = document.querySelector(`link[href="${fontLinkHref}"]`);
      if (!fontLink) {
        fontLink = document.createElement("link");
        fontLink.href = fontLinkHref;
        fontLink.rel = "stylesheet";
        document.head.appendChild(fontLink);
      }

      // Apply the font style as a CSS variable
      document.documentElement.style.setProperty(
        "--dynamic-font-family",
        getSingleResumeDoc.font_style
      );

      // Cleanup: optional if you want to remove the link on unmount
      return () => {
        document.head.removeChild(fontLink);
      };
    }
  }, [getSingleResumeDoc?.font_style]);

  const [activeStates, setActiveStates] = useState({
    bold: false,
    italic: false,
    underline: false,
  });

  const handleFormat = (command) => {
    document.execCommand(command);
    setActiveStates((prev) => ({
      ...prev,
      [command]: !prev[command],
    }));
  };

  return (
    <div
      style={{
        fontFamily: `var(--dynamic-font-family, ${getSingleResumeDoc?.font_style})`,
      }}
      className={`cv-child-container hover:bg-gray-200 w-full h-auto ${
        blured ? "blured" : ""
      }`}
      ref={tempRef}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="sections" direction="vertical">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="w-full"
            >
              {sectionsConfig?.map((section, index) => {
                // if (section?.additional) return null;
                const isActive = activeField === index;
                const isHovered = hoveredField === index;

                const originalSection = getSingleResumeDoc?.positions?.find(
                  (c) => c.key === section?.key
                );
                const hasChanges =
                  (originalSection &&
                    originalSection?.title !== section?.title?.trim()) ||
                  originalSection?.style !== section?.style?.trim();

                const iconStyle = {
                  position: "absolute",
                  top: "-10px",
                  borderRadius: "50%",
                  padding: "4px",
                  fontSize: "1.4rem",
                  color: "#fff",
                  boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                  cursor: "pointer",
                };
                return (
                  <Draggable
                    key={section.key}
                    draggableId={section.key}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={section.className}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <div className="py-1 px-2 w-full">
                          <div className="flex item-center">
                            {!section.isRequired &&
                              section.isRequired != null && (
                                <div
                                  className="text-white rounded-full w-6 h-6 mt-3 flex items-center justify-center shadow cursor-pointer"
                                  style={{
                                    backgroundColor: `${
                                      getSingleResumeDoc?.heading_color
                                        ? getSingleResumeDoc?.heading_color
                                        : "#000"
                                    }`,
                                  }}
                                  onClick={() => {
                                    setRemoveSection(true);
                                    setSelectedPosition(section?.key);
                                  }}
                                >
                                  <i className="ri-close-line text-sm"></i>
                                </div>
                              )}
                            {section?.title != "Personal Information" && (
                              <span
                                className={`editable-field   cv-section-title apply-font ${
                                  isActive || isHovered ? "active mx- " : ""
                                }`}
                                onMouseEnter={() => setHoveredField(index)}
                                onMouseLeave={() => setHoveredField(null)}
                                onDoubleClick={() =>
                                  handleFieldClick(index, section?.key)
                                }
                                style={{
                                  position: "relative",
                                  border:
                                    isActive || isHovered
                                      ? "1px solid #ffff"
                                      : "1px solid transparent",
                                  backgroundColor:
                                    isActive || isHovered
                                      ? "#ffff"
                                      : "transparent",
                                  fontSize: "inherit",
                                  cursor: "text",
                                  color:
                                    getSingleResumeDoc?.heading_color || "#000",
                                  fontSize: getSingleResumeDoc?.font_size || "",
                                }}
                              >
                                <span
                                  contentEditable={isActive}
                                  ref={(el) =>
                                    (editableRefs.current[index] = el)
                                  }
                                  onInput={(e) => handleFieldInput(index, e)}
                                  onBlur={() => submitChanges()}
                                  onKeyDown={(e) =>
                                    e.key === "Enter" && e.preventDefault()
                                  }
                                  suppressContentEditableWarning={true}
                                  className={`editable-field ${section?.style}`}
                                  style={{
                                    fontSize:
                                      getSingleResumeDoc?.font_size || "",
                                    fontFamily: `${getSingleResumeDoc?.font_style}`,
                                  }}
                                >
                                  {section.title}
                                </span>
                                {isActive && (
                                  <div className="absolute  !rounded-2xl  editable-field active top-[-40px] left-0 flex items-center gap-2 bg-white p-2 border border-gray-200  shadow-lg shadow-blue-100 z-10">
                                    <button
                                      onClick={() => {
                                        setSelectedSectionKey(section?.key);
                                        setSectionConfigs((prev) =>
                                          prev.map((section) => {
                                            if (
                                              section?.key ===
                                              sectionsConfig[index].key
                                            ) {
                                              const currentStyle =
                                                section?.style || "";
                                              const isBold =
                                                currentStyle.includes(
                                                  "font-bold"
                                                );
                                              const updatedStyle = isBold
                                                ? currentStyle
                                                    .replace(
                                                      /\bfont-bold\b/,
                                                      ""
                                                    )
                                                    .trim()
                                                : `${currentStyle} font-bold`.trim();
                                              return {
                                                ...section,
                                                style: updatedStyle,
                                              };
                                            }
                                            return section;
                                          })
                                        );
                                      }}
                                      className={`w-5 h-5   flex items-center justify-center  text-black text-sm font-semibold rounded-md ${
                                        section?.style?.includes("font-bold")
                                          ? "bg-blue-500 text-white"
                                          : ""
                                      }`}
                                      title="Bold"
                                    >
                                      <Bold size={18} />
                                    </button>

                                    <button
                                      onClick={() => {
                                        setSelectedSectionKey(section?.key);
                                        setSectionConfigs((prev) =>
                                          prev.map((section, sectionIndex) => {
                                            if (
                                              section?.key ===
                                              sectionsConfig[index].key
                                            ) {
                                              const currentStyle =
                                                section?.style || "";
                                              const isItalic =
                                                currentStyle.includes("italic");
                                              const updatedStyle = isItalic
                                                ? currentStyle
                                                    .replace(/\bitalic\b/, "")
                                                    .trim()
                                                : `${currentStyle} italic`.trim();
                                              return {
                                                ...section,
                                                style: updatedStyle,
                                              };
                                            }
                                            return section;
                                          })
                                        );
                                      }}
                                      className={`w-5 h-5 flex items-center justify-center  text-black text-sm italic rounded-md ${
                                        section?.style?.includes("italic")
                                          ? "bg-blue-500 text-white"
                                          : ""
                                      }`}
                                      title="Italic"
                                    >
                                      <Italic size={18} />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setSelectedSectionKey(section?.key);
                                        setSectionConfigs((prev) =>
                                          prev.map((section, sectionIndex) => {
                                            if (
                                              section?.key ===
                                              sectionsConfig[index].key
                                            ) {
                                              const currentStyle =
                                                section?.style || "";
                                              const isUnderline =
                                                currentStyle.includes(
                                                  "underline"
                                                );
                                              const updatedStyle = isUnderline
                                                ? currentStyle
                                                    .replace(
                                                      /\bunderline\b/,
                                                      ""
                                                    )
                                                    .trim()
                                                : `${currentStyle} underline`.trim();
                                              return {
                                                ...section,
                                                style: updatedStyle,
                                              };
                                            }
                                            return section;
                                          })
                                        );
                                      }}
                                      className={`w-5 h-5 flex items-center justify-center  text-black text-sm underline rounded-md ${
                                        section?.style?.includes("underline")
                                          ? "bg-blue-500 text-white"
                                          : ""
                                      }`}
                                      title="Underline"
                                    >
                                      <Underline size={18} />
                                    </button>
                                  </div>
                                )}

                                {isActive && hasChanges && (
                                  <MdDone
                                    onClick={() => submitChanges()}
                                    style={{
                                      ...iconStyle,
                                      left: "-20px",
                                      background: "#4caf50",
                                    }}
                                  />
                                )}

                                {isActive && !hasChanges && (
                                  <MdClose
                                    onClick={() => setActiveField(null)}
                                    style={{
                                      ...iconStyle,
                                      right: "-20px",
                                      background: "#ff4d4d",
                                    }}
                                  />
                                )}

                                {/* {isHovered && !isActive && (
                                  <MdOutlineModeEdit
                                    onClick={() =>
                                      handleFieldClick(index, section?.key)
                                    }
                                    style={{
                                      ...iconStyle,
                                      right: "-20px",
                                      background: "#000",
                                    }}
                                  />
                                )} */}
                              </span>
                            )}
                          </div>
                          {section?.title != "Personal Information" && (
                            <hr
                              style={{
                                border: `1px solid ${
                                  getSingleResumeDoc?.heading_color || "#000"
                                }`,
                              }}
                            />
                          )}
                          {section.component}
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ReactModal
        isOpen={removeSection}
        onRequestClose={() => setRemoveSection(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>Confirmation Box</h2>
        <hr />
        <div className="modal-body">
          <div className="">
            <p className="text-center">
              Are you sure you want to remove this section from your resume?
            </p>
            <div className="flex align-items-center justify-content-center mt-2">
              <button
                className="bg-red-600 px-4 py-2 rounded text-white"
                onClick={() => setRemoveSection(false)}
              >
                No
              </button>
              <button
                className=" bg-green-600 px-4 py-2 rounded mx-2 text-white"
                onClick={() => {
                  let resumeId = id;
                  dispatch(
                    deleteResumePosition({
                      resumeId,
                      heading: selectedPosition,
                      onSuccess: () => {
                        setRemoveSection(false);
                        dispatch(
                          getSingleResume({ resumeId, onSuccess: () => {} })
                        );
                        dispatch(getDeletedSections(resumeId));
                      },
                      onError: () => {},
                    })
                  );
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default CvTemplate;
