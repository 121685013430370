import React from "react";

const IQShimmer = () => {
  return (
    <div className="animate-pulse">
      {/* Filters Shimmer (keeping actual filters) */}
      <div className="mb-8" />

      {/* Tab Shimmer */}
      {/* <div className="flex justify-end mb-6 border-b border-gray-200">
        <div className="w-96 h-10 bg-gray-200 rounded animate-shimmer" />
      </div> */}

      {/* Job Cards Shimmer */}
      {[1, 2, 3, 4, 5].map((index) => (
        <div key={index} className="mb-0 p-2 rounded-lg">
          <div className="flex justify-center items-start gap-4">
            <div className="w-[70%]">
              <div className="w-full h-32 bg-gray-200 px-3 pt-3 rounded-xl mb-3 animate-shimmer">
                <div className="w-1/2 h-6 bg-white rounded mb-2 animate-shimmer" />
                <div className="w-full h-6 bg-white rounded mb-3 animate-shimmer" />
                <div className="w-full h-8 bg-white rounded animate-shimmer" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IQShimmer;
