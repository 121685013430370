import React from "react";

const getStatusColor = (status) => {
  if (["qualified", "hired", "passed"].includes(status)) {
    return "bg-success";
  }
  if (
    ["waiting for interview", "applied", "interview in progress"].includes(
      status
    )
  ) {
    return "bg-warning";
  }
  return "bg-danger";
};

const getDisplayStatus = (status) => {
  if (status === "passed") return "Qualified";
  if (status === "failed") return "Disqualified";
  return status;
};

export const JobStatusBadge = ({ status, onClick }) => {
  const statusColor = getStatusColor(status);
  const displayStatus = getDisplayStatus(status);
  const isClickable = status === "failed";

  return (
    <span
      onClick={isClickable ? onClick : undefined}
      className={`question-tags5 mr-3 text-white ${statusColor} ${
        isClickable ? "cursor-pointer" : "cursor-default"
      }`}
    >
      {displayStatus}
    </span>
  );
};
