import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { error, success } from "../../../Utilities/toast";
import { getCurrentUser } from "../../../Redux/authenticate/authenticate.action";
import {
  AddExperienceHistory,
  deleteExperienceHistory,
} from "../../../Redux/UserProfileSlice";

const ExpEduHistory = ({ activeStep, setActiveStep }) => {
  const { user } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  const [initialUserProfileInfo, setInitialUserProfileInfo] = useState([]);
  const [entries, setEntries] = useState([
    {
      job_title: "",
      company: "",
      duration: "",
      role_description: "",
      id: null,
    },
  ]);
  const [totalExperience, setTotalExperience] = useState(null);

  const handleChange = (index, event) => {
    const values = [...entries];
    values[index][event.target.name] = event.target.value;
    setEntries(values);
  };

  const handleAddEntry = () => {
    setEntries([
      ...entries,
      {
        job_title: "",
        company: "",
        duration: "",
        role_description: "",
        id: null,
      },
    ]);
  };

  const handleRemoveEntry = (index, obj) => {
    const selectedData = entries.find((item) => item == obj);
    if (selectedData?.id) {
      dispatch(
        deleteExperienceHistory({
          id: selectedData?.id,
          onSuccess: () => {
            success("Work Experience History deleted successfully");
            dispatch(getCurrentUser());
          },
        })
      );
    } else {
      const newentries = [...entries];
      newentries.splice(index, 1);
      setEntries(newentries);
    }
  };

  const handleSave = () => {
    const hasChanged =
      JSON.stringify(entries) !== JSON.stringify(initialUserProfileInfo) ||
      JSON.stringify(totalExperience) !== JSON.stringify(user.total_experience);
    if (hasChanged) {
      if (
        entries.some(
          (certificate) =>
            !certificate.job_title ||
            !certificate.company ||
            !certificate.duration ||
            !certificate.role_description
        ) ||
        !totalExperience
      ) {
        return error("All fields are required");
      }

      const formattedData = {
        experience_history: entries,
        total_experience: totalExperience,
      };
      dispatch(
        AddExperienceHistory({
          payload: formattedData,
          onSuccess: () => {
            success("Work Experience History added successfully");
            dispatch(getCurrentUser());
            setActiveStep((prev) => prev + 1);
          },
        })
      );
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (user && user.experience_history && user.experience_history.length > 0) {
      setEntries(user.experience_history?.map((cert) => ({ ...cert })));
      setInitialUserProfileInfo(
        user.experience_history?.map((cert) => ({ ...cert }))
      );
      setTotalExperience(user.total_experience);
    } else {
      setEntries([
        {
          job_title: "",
          company: "",
          duration: "",
          role_description: "",
          id: null,
        },
      ]);
      setInitialUserProfileInfo([
        {
          job_title: "",
          company: "",
          duration: "",
          role_description: "",
          id: null,
        },
      ]);
    }
  }, [user]);

  return (
    <div className="py-4">
      <div className="mb-4 p-4 border rounded-lg shadow">
        <div className="mb-2">
          <label className="block mb-1">Total Years of Experience:</label>
          <input
            type="text"
            name="totalExperience"
            value={totalExperience}
            onChange={(e) =>
              setTotalExperience(e.target.value.replace(/[^0-9.]/g, ""))
            }
            className="border rounded p-2 w-full"
            placeholder="Enter experience"
            required
          />
        </div>
      </div>

      {entries?.map((entry, index) => (
        <div key={index} className="mb-4 p-4 border rounded-lg shadow">
          <div className="mb-2">
            <label className="block mb-1">Job Title:</label>
            <input
              type="text"
              name="job_title"
              value={entry.job_title}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              placeholder="Enter title"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block mb-1">Company:</label>
            <input
              type="text"
              name="company"
              value={entry.company}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              placeholder="Enter company name"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block mb-1">Duration:</label>
            <input
              type="text"
              name="duration"
              value={entry.duration}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              placeholder="Enter duration (e.g., Jan 2020 - Dec 2021)"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block mb-1">Key Achievement:</label>
            <textarea
              name="role_description"
              value={entry.role_description}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              placeholder="Enter role description"
              rows="3"
            />
          </div>
          {entries.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemoveEntry(index, entry)}
              className="text-white bg-red-600 mt-2 px-4 py-2 rounded-lg"
            >
              Remove History
            </button>
          )}
        </div>
      ))}
      <div className="w-full gap-2 flex justify-end">
        <button
          type="button"
          onClick={handleAddEntry}
          className="my-2 bg-blue-500 text-white p-2 rounded"
        >
          Add Another History
        </button>
      </div>
      <div className={`w-full mt-2 flex justify-between`}>
        <button
          className="ic-btn"
          onClick={() => {
            setActiveStep((prev) => prev - 1);
          }}
        >
          Previous
        </button>
        <button className="ic-btn" onClick={handleSave}>
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default ExpEduHistory;
