import React, { useState, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Select } from "antd";
import { Search, MapPin, X, AlertCircle } from "lucide-react";
import debounce from "lodash/debounce";
import { useSearchParams } from "react-router-dom";
import { error } from "../../../Utilities/toast";
import {
  experienceOptions,
  fromDateOtions,
  validateSelectValue,
  workplaceOptions,
} from "./IQContext";
const { Option } = Select;

const Filters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const [autocomplete, setAutocomplete] = useState(null);
  const [showError, setShowError] = useState(false);
  const [title, setTitle] = useState("");
  console.log("title: ", title);
  console.log(paramsObject, "paramsObject");

  const updateFilters = (key, value) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(key, value);
    } else {
      newParams.delete(key);
    }
    setSearchParams(newParams);
    if (key === "location" && value !== "") {
      if (title) {
        newParams.set("title", title);
      }
      setSearchParams(newParams);
      setShowError(false);
    }
  };

  const handleClearFilters = () => {
    setSearchParams({});
    setShowError(false);
    setTitle("");
    const locationInput = document.querySelector(".location-input");
    if (locationInput) locationInput.value = "";
  };

  const handleTitleChange = (value) => {
    setTitle(value);
    if (!paramsObject?.location) {
      setShowError(true);
      return;
    }
    setShowError(false);
  };

  const handleSearchFilter = () => {
    if (!title) return error("Please enter a job title");
    if (!paramsObject?.location)
      return error("Location is required for job search");
    setShowError(false);
    updateFilters("title", title);
  };

  // const handleSearchInput = debounce((value) => {
  //   if (!paramsObject?.location) {
  //     setShowError(true);
  //     return;
  //   }
  //   setShowError(false);
  //   updateFilters("title", value);
  // }, 500);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const city = place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;

      updateFilters("location", city);
    }
  };

  useEffect(() => {
    if (Object.keys(paramsObject)?.length > 0) {
      const location = paramsObject?.location;
      const title = paramsObject?.title;
      if (location) {
        const locationInput = document.querySelector(".location-input");
        if (locationInput) locationInput.value = location;
      }
      if (title) {
        setTitle(title);
      }
    }
  }, []);

  return (
    <div className="w-full px-4 py-6 bg-white shadow-sm rounded-lg">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
          {/* Location Filter */}
          <div className="relative">
            <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
              <MapPin size={18} />
            </div>
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              options={{
                types: ["(cities)"],
                componentRestrictions: { country: ["us", "gb", "ca"] },
              }}
            >
              <input
                className="location-input w-full h-12 pl-10 pr-4 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                type="text"
                placeholder="Enter a city"
              />
            </Autocomplete>
          </div>

          {/* Date Posted Filter */}
          <div className="relative">
            <Select
              value={validateSelectValue(
                paramsObject?.from_date,
                fromDateOtions
              )}
              placeholder="Date posted"
              className="w-full h-12"
              popupClassName="rounded-lg"
              onChange={(value) => updateFilters("from_date", value)}
            >
              {fromDateOtions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
              {/* <Option value="any_time">Any Time</Option>
              <Option value="past_month">Past Month</Option>
              <Option value="past_week">Past Week</Option>
              <Option value="past_3_days">Past 3 days</Option>
              <Option value="past_24_hours">Past 24 hours</Option> */}
            </Select>
          </div>

          {/* Workplace Type Filter */}
          <div className="relative">
            <Select
              value={validateSelectValue(
                paramsObject?.workplace_type,
                workplaceOptions
              )}
              placeholder="Workplace Type"
              className="w-full h-12"
              popupClassName="rounded-lg"
              onChange={(value) => updateFilters("workplace_type", value)}
            >
              {workplaceOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>

          {/* Experience Level Filter */}
          <div className="relative">
            <Select
              value={validateSelectValue(
                paramsObject?.experience_level,
                experienceOptions
              )}
              placeholder="Experience Level"
              className="w-full h-12"
              popupClassName="rounded-lg"
              onChange={(value) => updateFilters("experience_level", value)}
            >
              {experienceOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>

          {/* Search Input */}
          <div className="relative">
            <div
              className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 cursor-pointer"
              onClick={() => handleSearchFilter()}
            >
              <Search size={18} />
            </div>

            <input
              type="text"
              id="searchInput"
              className={`w-full h-12 pl-10 pr-4 border rounded-lg transition-all ${
                showError
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-200 focus:ring-blue-500"
              } focus:border-transparent`}
              placeholder="Search jobs & skills"
              value={title}
              onChange={(e) => handleTitleChange(e.target.value)}
            />
          </div>
        </div>

        {showError && (
          <div className="flex items-center p-4 mt-4 text-red-800 border border-red-300 rounded-lg bg-red-50">
            <AlertCircle className="w-5 h-5 mr-2" />
            <span>Please select a location before searching for jobs</span>
          </div>
        )}

        {Object.values(paramsObject).some((value) => value !== "") && (
          <div className="flex justify-center mt-4">
            <button
              onClick={handleClearFilters}
              className="flex items-center px-4 py-2 text-sm font-medium text-red-600 bg-red-50 rounded-lg hover:bg-red-100 transition-colors"
            >
              <X size={16} className="mr-2" />
              Clear Filters
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
