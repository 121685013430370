import { Spin } from "antd";
import ReactModal from "react-modal";

const ResumeScoreModal = ({ isOpen, onClose, onConfirm, data, loading }) => {
  const feedback = (() => {
    if (data?.score <= 5) return "You are not a fit for this job";
    if (data?.score >= 6 && data?.score <= 7) return "You are fit for this job";
    if (data?.score >= 8) return "You are a great fit for this job";
    if (data?.score === 10) return "You are a top applicant for this job";
    return "";
  })();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "20px 40px",
          maxWidth: "650px",
          width: "85%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 1002,
          maxHeight: "75vh",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
    >
      {loading ? (
        <div className="flex flex-col justify-center text-center">
          <div className="flex justify-center">
            <div className="p-2 w-fit bg-blue-400 rounded-full">
              <Spin size="large" />
            </div>
          </div>
          <h1>Analysing Resume</h1>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="pb-2 mb-[20px] border-b ">
            <h2 className="text-xl font-semibold">Resume Analysis</h2>
          </div>
          <div className="flex flex-col">
            <p className="mb-2">{data?.remarks}</p>
            <p>{feedback}, Do you want to continue?</p>
          </div>
          <div className="modal-footer pb-0">
            <button
              className="modal-button cancel-button"
              disabled={loading}
              onClick={onClose}
            >
              No
            </button>
            <button
              className="modal-button save-button"
              disabled={loading}
              onClick={onConfirm}
            >
              Yes
            </button>
          </div>
        </div>
      )}
    </ReactModal>
  );
};

export default ResumeScoreModal;
