import React, { memo } from "react";
import { JobHeader } from "./JobHeader";
import { JobStatusBadge } from "./JobStatusBadge";
import nexaLogo from "../../../../assets/icons/logo.jpeg";
import googleLogo from "../../../../assets/icons/googleIcon.webp";
import { JobDescription } from "./JobDescription";
import { JobDetails } from "./JobDetails";
import { ExpandToggle } from "./ExpandToggle";

const JobCard = ({
  job,
  activeTab,
  expandedJobId,
  setExpandedJobId,
  openSidebar,
  setIsLoading,
}) => {
  const logoSrc = job.source === "iq_jobs" ? googleLogo : nexaLogo;
  const handleToggleCard = (jobId) => {
    setExpandedJobId(expandedJobId === jobId ? null : jobId);
  };

  return (
    <>
      <div className="outside-container">
        <div className="flex flex-row items-center justify-between rounded-t-[20px] bg-white group-hover:bg-[#FAFBFF] px-4 py-3">
          <JobHeader
            id={job?.id}
            title={job?.title}
            organizationName={job.company || job?.organization?.name}
            location={job?.location}
            createdAt={job?.created_at || job?.created_on || ""}
            openSidebar={openSidebar}
            setIsLoading={setIsLoading}
          />

          <div className="flex justify-center items-center">
            {activeTab === "Applied Jobs" && job?.applied_status && (
              <JobStatusBadge
                status={
                  job?.applied_status == "interview Completed"
                    ? job?.feedback_score < 2
                      ? "interview Failed"
                      : "interview Passed"
                    : job?.applied_status
                }
              />
            )}
            <span>
              <img src={logoSrc} alt="Company Logo" height={40} width={40} />
            </span>
          </div>
        </div>

        {expandedJobId === job?.id && (
          <>
            <JobDescription
              description={job?.description || job?.job_description}
              employmentType={job?.employment_type}
            />
            <JobDetails
              jobProvider={job.source}
              logoUrl={job?.organization?.logo}
              companyName={job?.organization?.name}
              companyTagline={job?.organization?.address}
              organizationNumber={job?.organization?.phone}
              jobStatus={job?.status}
              jobTonality={job?.tonality}
              prescreeningDeadline={job?.prescreening_deadline}
              interviewDeadline={job?.interview_deadline}
              howToConduct={job?.how_to_conduct}
              companyDescription={job?.CompanyDesc}
            />
          </>
        )}

        <ExpandToggle
          isExpanded={job?.id === expandedJobId}
          onToggle={() => handleToggleCard(job?.id)}
        />
      </div>
    </>
  );
};

export default memo(JobCard);
