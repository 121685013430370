import { useSelector } from "react-redux";
import { IoWarningOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

const QuotaWarningBar = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authenticate);

  const MarqueeWarnings = [
    {
      icon: user?.subscriptionQuotas?.interview?.warning && (
        <IoWarningOutline color="yellow" size={25} />
      ),
      warning: user?.subscriptionQuotas?.interview?.warning
        ? user?.subscriptionQuotas?.interview?.warning
        : null,
    },
    {
      icon: user?.subscriptionQuotas?.resume?.warning && (
        <IoWarningOutline color="yellow" size={25} />
      ),
      warning: user?.subscriptionQuotas?.resume?.warning
        ? user?.subscriptionQuotas?.resume?.warning
        : null,
    },
    {
      icon: user?.subscriptionQuotas?.pround?.warning && (
        <IoWarningOutline color="yellow" size={25} />
      ),
      warning: user?.subscriptionQuotas?.pround?.warning
        ? user?.subscriptionQuotas?.pround?.warning
        : null,
    },
  ];
  return (
    <div className="absolute top-0 left-0 right-0 z-50">
      {(user?.subscriptionQuotas?.interview?.warning ||
        user?.subscriptionQuotas?.resume?.warning ||
        user?.subscriptionQuotas?.pround?.warning) && (
        <div className="cursor-pointer" onClick={() => navigate("/pricing")}>
          <Marquee
            delay={0}
            loop={0}
            speed={100}
            autoFill={true}
            pauseOnHover={true}
            className="bg-red-700 text-white py-2 opacity-80"
          >
            <div className="flex items-center gap-40">
              {MarqueeWarnings?.map((item) => {
                return (
                  <div className="flex items-center">
                    {item?.icon}
                    <p className="ml-1">{item?.warning}</p>
                  </div>
                );
              })}
            </div>
          </Marquee>
        </div>
      )}
    </div>
  );
};

export default QuotaWarningBar;
