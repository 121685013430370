import { io } from "socket.io-client";

export let socket = io(process.env.REACT_APP_API_URL_SOCKETS, {
  transports: ["websocket", "polling"],
  withCredentials: true,
  autoConnect: false,
});
export function addUserSocket(userId) {
  console.log("userId: ", userId);
  socket.auth = { token: userId };
  socket.connect();
  socket.emit("add_user", { user_id: userId, role: "customer" });
}

export const getMessage = (dispatch) => {
  const handleGetMessage = (message) => {
    console.log("New message received:", message);
    // dispatch(receiveMessageAction(message)); // Uncomment and use if needed
  };

  socket.on("get_message", handleGetMessage);

  return () => {
    socket.off("get_message", handleGetMessage);
  };
};

export const sendMessage = (messageData) => {
  socket.emit("send_message", messageData, () => {
    console.log("Message sent successfully");
  });
};

export function pageRefresh({ token, encrypted_user_id }) {
  socket.auth = { token: token };
  socket.connect();
  socket.emit("page_refresh", {
    user_id: token,
    encrypted_user_id: encrypted_user_id,
    role: "customer",
  });
}
// save file (mentioned in data) in the backend
export function saveFile(data, callback) {
  socket.emit("save_file", data, callback);
}

// fetch contents of a directory (depth 1)
export function fetchDir(path, callback) {
  socket.emit("get_dir", { path }, callback);
}

export function fetchFileContent(path, callback) {
  socket.emit("get_file", { path }, callback);
}
