import MonacoEditor from "@monaco-editor/react";
import {
  ChevronLeft,
  ChevronRight,
  List,
  Shuffle,
  UploadCloud,
} from "lucide-react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import "../../assets/CSS/index.css";
import {
  Button,
  CodeEditorContainer,
  LoaderOverlay,
  QuestionContainer,
  SelectContainer,
  TestContainer,
  Timer,
  TimerContainer,
  TimerIcon,
} from "./index.style";
import questionsData from "./questions_new.json";

import { AiChat, AiChatUI } from "@nlux/react";

import { useUnsafeChatAdapter } from "@nlux/openai-react";
import { Row, Tabs } from "antd";
import { saveAs } from "file-saver";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useChatLogs from "../../hook/chatStream";
import {
  setInterviewCompleted,
  setinterviewMode,
} from "../../Redux/GlobalStates";
import EndingScreen from "../EndingScreen";
import CircularLoader from "../loader";
import Modal from "../modal";
import { files, themes } from "./data";
import QuestionComponent from "./questionComponent";

import ItemLoading from "./itemLoading";
import ScrollableTabsButtonAuto from "./scrollabletab";
// Utility function to get random numbers
// import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CheckCircle } from "lucide-react";

let currentAudio = null;
function getRandomNumbersInRange(min, max, count) {
  if (max - min + 1 < count) {
    throw new Error(
      "Range too small to accommodate the count of unique numbers."
    );
  }

  const numbers = new Set();

  while (numbers.size < count) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    numbers.add(randomNumber);
  }

  return Array.from(numbers);
}

// Convert remaining seconds to mm:ss format
const formatTime = (seconds) => {
  if (seconds <= 0) {
    return "00:00";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

const NewQuestionComponent = memo(
  ({ techStack, interviewTimer, setInterviewTimer }) => {
    const playTTSRef = useRef(true);
    console.log("interviewTimer", interviewTimer);

    const [modalIsOpen, setIsOpen] = useState(false);

    const { messages, forceSetMessages, getFeedback } = useChatLogs({
      storeChats: false,
      url: "/dashboard/evaluate",
    });

    const dispatch = useDispatch();

    const [answers, setAnswers] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null); // Changed from 2 to null
    const [fileName, setFileName] = useState("javascript");
    const [theme, setTheme] = useState("vs");
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const navigate = useNavigate();
    const file = files.find((ele) => ele.language === fileName);
    const [selectedFileType, setSelectedFileType] = useState("js");
    const [fileList, setFileList] = useState([
      {
        key: "0",
        label: "question1.js",
        type: "js",
        value: ``,
        language: "javascript",
        closable: false,
      },
    ]);

    const [selectedFile, setSelectedFile] = useState(fileList[0]);

    const [feedback, setFeedback] = useState([]);
    const [storeResultScore, setStoreResultScore] = useState(null);

    const [feedbackLoading, setFeedbackloading] = useState(false);
    const [endScreen, setEndScreen] = useState(false);

    console.log("feedback>>", feedback);
    const [questions, setQuestions] = useState([]);

    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [SubmitClick, setSubmitClick] = useState(false);
    const [endingpageview, setEndingpageview] = useState(false);
    const [questionAnswer, setQuestionAnswer] = useState("");
    // setFilteredQuestions filteredQuestions
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [filteredByTopic, setFilteredByTopic] = useState([]);

    useEffect(() => {
      // Load questions from the imported JSON data
      setQuestions(questionsData);
    }, []);

    useEffect(() => {
      if (currentQuestionIndex !== null && questions.length > 0) {
        setCurrentQuestion(questions[currentQuestionIndex]);
      }
    }, [currentQuestionIndex, questions]);

    useEffect(() => {
      if (selectedFileType) {
        let isExistFile = files.find((e) => e.sign === selectedFileType);

        if (isExistFile) {
          let fileArr = [...fileList];
          fileArr[Number(selectedFile.key)].type = isExistFile.sign;
          fileArr[Number(selectedFile.key)].label = `question${
            Number(selectedFile.key) + 1
          }.${isExistFile.sign}`;
          fileArr[Number(selectedFile.key)].language = isExistFile.language;

          setFileList(fileArr);
        }
      }
    }, [selectedFileType]);

    const handleChangeTheme = (e) => {
      setTheme(e?.value);
    };

    useEffect(() => {
      dispatch(setInterviewCompleted(false));
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      };
    }, []);

    const handleBeforeUnload = (evt) => {
      evt.preventDefault();
      let msg = "You cannot exit the interview.";
      evt.returnValue = msg;
      return msg;
    };

    const handleUnload = (evt) => {
      evt.preventDefault();
    };

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        borderRadius: "8px",
        border: state.isFocused ? "2px solid #007bff" : "2px solid #ced4da",
        boxShadow: "none",
        "&:hover": {
          border: "2px solid #007bff",
        },
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#007bff" : "white",
        color: state.isFocused ? "white" : "black",
        "&:hover": {
          backgroundColor: "#007bff",
          color: "white",
        },
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "black",
      }),
    };

    const editorRef = useRef(null);
    const containerRef = useRef(null);

    const handleEditorDidMount = useCallback((editor) => {
      editorRef.current = editor;
      editor.layout(); // Ensure layout on mount
    }, []);

    useEffect(() => {
      window.addEventListener("error", (e) => {
        if (e.message.startsWith("ResizeObserver loop")) {
          const resizeObserverErrDiv = document.getElementById(
            "webpack-dev-server-client-overlay-div"
          );
          const resizeObserverErr = document.getElementById(
            "webpack-dev-server-client-overlay"
          );
          if (resizeObserverErr) {
            resizeObserverErr.setAttribute("style", "display: none");
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.removeAttribute("style");
          }
        }
      });
    }, []);

    useEffect(() => {
      const resizeObserver = new ResizeObserver(() => {
        if (editorRef.current) {
          editorRef.current.layout();
        }
      });

      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }, []);

    const onChangeTab = (key) => {
      let idx = Number(key);
      setSelectedFile(fileList[idx]);
    };

    const monoEditor = useCallback(() => {
      return (
        <>
          <Tabs
            type="editable-card"
            activeKey={selectedFile.key}
            items={fileList}
            hideAdd
            onChange={onChangeTab}
          />

          <div
            className={`editor-wrapper ${theme === "vs-dark" ? "dark" : ""}`}
            style={{ flexGrow: 1 }}
          >
            <div className={`top-bar ${theme === "vs-dark" ? "dark" : ""}`}>
              <div className="icon text-sd-green-500">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="code"
                  className="svg-inline--fa fa-code"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  style={{ width: "1em", height: "1em" }}
                >
                  <path
                    fill="#38a169"
                    d="M399.1 1.1c-12.7-3.9-26.1 3.1-30 15.8l-144 464c-3.9 12.7 3.1 26.1 15.8 30s26.1-3.1 30-15.8l144-464c3.9-12.7-3.1-26.1-15.8-30zm71.4 118.5c-9.1 9.7-8.6 24.9 1.1 33.9L580.9 256 471.6 358.5c-9.7 9.1-10.2 24.3-1.1 33.9s24.3 10.2 33.9 1.1l128-120c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-13-7.6-17.5l-128-120c-9.7-9.1-24.9-8.6-33.9 1.1zm-301 0c-9.1-9.7-24.3-10.2-33.9-1.1l-128 120C2.7 243 0 249.4 0 256s2.7 13 7.6 17.5l128 120c9.7 9.1 24.9 8.6 33.9-1.1s8.6-24.9-1.1-33.9L59.1 256 168.4 153.5c9.7-9.1 10.2-24.3 1.1-33.9z"
                  ></path>
                </svg>
              </div>
              <div className="text">Code</div>
            </div>
            <div
              style={{
                borderTop: "solid 1px gray",
                borderBottom: "solid 1px gray",
                borderRadius: "0px",
                padding: "0px",
              }}
              className={`top-bar ${theme === "vs-dark" ? "dark" : ""}`}
            >
              <div className="min-w-[130px]">
                <Select
                  className="selected-select"
                  options={files?.map((e) => ({
                    ...e,
                    label: e.language,
                    value: e.sign,
                  }))}
                  onChange={(e) => setSelectedFileType(e.value)}
                  styles={customStyles}
                  placeholder="Language"
                />
              </div>
              <div className="min-w-[130px]">
                <Select
                  className="selected-select"
                  options={themes}
                  onChange={handleChangeTheme}
                  styles={customStyles}
                  placeholder="Theme"
                />
              </div>
            </div>
            <div className="code-editor-container">
              <MonacoEditor
                height="100%"
                width="100%"
                theme={theme}
                style={{ paddingTop: "10px" }}
                path={selectedFile.language}
                defaultLanguage={selectedFile.language}
                defaultValue={selectedFile.value}
                value={
                  localStorage.getItem(currentQuestionIndex)
                    ? localStorage.getItem(currentQuestionIndex)
                    : selectedFile.value
                }
                onChange={(value) => {
                  localStorage.setItem(currentQuestionIndex, value);
                  setQuestionAnswer(value);
                }}
                options={{
                  wordWrap: "on",
                  minimap: { enabled: false },
                }}
                onMount={handleEditorDidMount}
              />
            </div>
          </div>
        </>
      );
    }, [answers, currentQuestionIndex, file.language, theme, selectedFile]);

    const renderModal = useCallback(() => {
      if (!modalIsOpen || loading) return <></>;

      const header = (
        <div className="mockInterview-modal-header">
          <button
            className="modal-close"
            onClick={() => {
              forceSetMessages([]);
              dispatch(setInterviewCompleted(true));
              setIsOpen(false);
              setEndScreen(true);
              setInterviewTimer(false);
            }}
          >
            <i className="ri-close-line"></i>
          </button>
        </div>
      );

      const content = (
        <div className="row">
          <div className="col-12 order-md-1 order-2">
            {feedback?.map((item, index) => (
              <div className="cardBody" key={index + 100}>
                <h5 className="mx-3 my-3">Question:</h5>
                <hr />
                <p className="mx-3 my-3">{item?.question}</p>
                <hr />
                <h5 className="mx-3 my-3 text-primary">Answer:</h5>
                <hr />
                <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
                <hr />

                <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
                <ReactMarkdown className="mx-3 my-3">
                  {item?.feedback}
                </ReactMarkdown>
              </div>
            ))}
          </div>
        </div>
      );

      const footer = (
        <div className="footer_scrore d-flex">
          <h5 className="my-0">Nexa Grading Score:</h5>
          <span className="score">{storeResultScore?.toFixed(2)}</span>
        </div>
      );

      if (feedbackLoading) {
        return <LoaderOverlay>Loading...</LoaderOverlay>;
      }

      return (
        <Modal
          type="feedback"
          show={modalIsOpen}
          header={header}
          content={content}
          footer={footer}
        />
      );
    }, [modalIsOpen, messages, feedback, feedbackLoading]);

    const downloadFile = (label, value, type) => {
      const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
      saveAs(blob, label);
    };

    const handleDownloadAll = () => {
      fileList.forEach((file) =>
        downloadFile(file.label, file.value, file.type)
      );

      setTimeout(() => {
        window.open("https://github.com/", "_blank"); // Replace with your desired URL
      }, 1000); // Adjust the delay as needed
    };

    // Function to handle question selection from the list
    const handleSelectQuestion = (index) => {
      console.log(index);
      setCurrentQuestionIndex(index);
    };

    useEffect(() => {
      setFilteredQuestions(questions);
      setFilteredByTopic(questions);
    }, [questions]);

    const handleTopicSelect = (selectedTopic) => {
      console.log(selectedTopic); // Log the selected topic

      if (selectedTopic === "all-topics") {
        setFilteredByTopic(questions); // Show all questions if "All Topics" is selected
        setFilteredQuestions(questions); // Reset final filtered questions
      } else {
        const filtered = questions.filter((question) =>
          question.topics.includes(selectedTopic)
        );
        setFilteredByTopic(filtered); // Save the topic-filtered questions
        setFilteredQuestions(filtered); // Update the final filtered questions
      }
    };

    const handleTopicSelectLevel = (selectedDifficulty) => {
      if (selectedDifficulty === "all-difficulties") {
        setFilteredQuestions(filteredByTopic); // Show the topic-filtered questions if "All Difficulties" is selected
      } else {
        const filtered = filteredByTopic.filter(
          (question) => question.difficulty === selectedDifficulty
        );
        setFilteredQuestions(filtered); // Update the final filtered questions
      }
    };

    const handleTopicSelectCompany = (selectedCompany) => {
      console.log(selectedCompany);
      if (selectedCompany === "all-companies") {
        setFilteredQuestions(filteredByTopic); // Show the topic-filtered questions if "All Difficulties" is selected
      } else {
        const filtered = questions.filter((question) =>
          question?.companies?.includes(selectedCompany)
        );
        setFilteredQuestions(filtered); // Update the final filtered questions
      }
    };

    const renderQuestionList = () => {
      return (
        <div className="w-3/4 mx-auto my-4">
          {/* Center the heading and add some margin for spacing */}
          <h2 className="text-2xl font-bold text-center mb-8">
            Let's Land You a Role in Meta, Amazon, Apple, Netflix, Google, or
            OpenAI!
          </h2>

          {/* Topic selection component */}
          <ScrollableTabsButtonAuto
            handleTopicSelect={handleTopicSelect}
            allquestions={questions}
            filter={"topic"}
          />
          <ScrollableTabsButtonAuto
            handleTopicSelect={handleTopicSelectLevel}
            allquestions={questions}
            filter={"level"}
          />
          <ScrollableTabsButtonAuto
            handleTopicSelect={handleTopicSelectCompany}
            allquestions={questions}
            filter={"company"}
          />
          <div
            style={{
              overflowY: "scroll",
              height: "45vh",
            }}
            className="question-list mt-12"
          >
            {/* Add margin top to the question list */}
            {filteredQuestions.map((question, index) => (
              <div
                key={question.id}
                className="flex justify-between items-center px-6 py-3 cursor-pointer bg-gray-50 hover:bg-gray-200 rounded-lg transition duration-300 mb-2"
                onClick={() => handleSelectQuestion(question.id - 1)}
              >
                {/* Left Side: Question ID and Name */}
                <div className="flex items-center space-x-3">
                  {localStorage.getItem(question.id - 1) == "Complete" ? (
                    <>
                      <CheckCircle size={24} style={{ color: "green" }} />
                    </>
                  ) : localStorage.getItem(question.id - 1) ? (
                    <>
                      <CheckCircle size={24} style={{ color: "#f6b000" }} />
                    </>
                  ) : (
                    <CheckCircle size={24} style={{ color: "#b2b2b2" }} />
                  )}
                  <span className="font-bold text-lg">{question.id}.</span>
                  <span className="text-black text-base font-medium">
                    {question.title}
                  </span>
                </div>

                {/* Right Side: Difficulty Level */}
                <div>
                  <span
                    className={`px-2 py-1 rounded-full text-sm font-bold
                      ${
                        question.difficulty.toLowerCase() === "easy"
                          ? "bg-green-200 text-green-800"
                          : question.difficulty.toLowerCase() === "medium"
                          ? "bg-yellow-200 text-yellow-800"
                          : "bg-red-200 text-red-800"
                      }`}
                  >
                    {question.difficulty}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    };

    const callEndingpage = () => {
      setEndingpageview(true);
    };
    const [showAiHelp, setShowAiHelp] = useState(false);

    const toggleAiHelpDisplay = () => {
      // console.log(currentQuestion);
      setShowAiHelp((prevState) => !prevState);
    };

    const adapter = useUnsafeChatAdapter({
      apiKey:
        "sk-PkuxB63G5Xdu71XBeq_rE-P6a0pv3yajE8BMMSgyeHT3BlbkFJ12i1BdWw8VBkr6utWGCJOSpJquPLYm1GK1borcq5oA",
      systemMessage: currentQuestion?.description,
    });

    return (
      <>
        {!endingpageview ? (
          <div style={{ marginTop: "100px" }}>
            <div className="ic-all-question flex flex-col">
              {renderModal()}
              {endScreen ? (
                <div className="mt-28">
                  <EndingScreen
                    setEndScreen={setEndScreen}
                    mainRoute={`/interview/role`}
                    action={() => dispatch(setinterviewMode("interview_exit"))}
                  />
                </div>
              ) : (
                <>
                  {currentQuestionIndex === null ? (
                    // Render the list of questions
                    <TestContainer className="w-full">
                      {renderQuestionList()}
                    </TestContainer>
                  ) : (
                    // Render the selected question and code editor
                    <TestContainer
                      style={{ backgroundColor: "#f0f0f0" }}
                      className="flex flex-col md:flex-row gap-4 w-[95%] rounded-[10px] mx-auto mt-[2%]"
                    >
                      {loading && (
                        <LoaderOverlay>
                          <CircularLoader
                            title="Nexa is hard at work to analyze your results, give us a minute"
                            icon={true}
                          />
                        </LoaderOverlay>
                      )}
                      {/* Question Section */}
                      <div className="w-full md:w-1/2 border-media">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <p
                              onClick={() => {
                                setCurrentQuestionIndex(null);
                              }}
                              style={{ color: "#000" }}
                              className="py-2 px-2 border-none text-sm flex w-[140px] items-center gap-2 justify-center problems-list"
                            >
                              <List size={20} color="black" />
                              Problem List
                            </p>
                            <p
                              onClick={() => {
                                console.log(currentQuestion.id - 1 - 1);
                                if (currentQuestion.id - 1 - 1 >= 0) {
                                  handleSelectQuestion(
                                    currentQuestion.id - 1 - 1
                                  );
                                }
                              }}
                              className="py-2 text-black px-2 border-none text-sm flex w-[40px] items-center gap-2 justify-center problems-list"
                            >
                              <ChevronLeft size={24} color="black" />
                            </p>
                            <p
                              onClick={() => {
                                if (currentQuestion.id + 1 - 1 < 500) {
                                  handleSelectQuestion(
                                    currentQuestion.id + 1 - 1
                                  );
                                }
                              }}
                              className="py-2 text-black px-2 border-none text-sm flex w-[40px] items-center gap-2 justify-center problems-list"
                            >
                              <ChevronRight size={24} color="black" />
                            </p>
                            <p
                              onClick={() => {
                                console.log(
                                  Math.floor(Math.random() * 499) + 1
                                );
                                handleSelectQuestion(
                                  Math.floor(Math.random() * 499) + 1
                                );
                              }}
                              className="py-2 text-black px-2 border-none text-sm flex w-[40px] items-center gap-2 justify-center problems-list"
                            >
                              <Shuffle size={20} color="black" />
                            </p>
                          </div>
                          <p
                            onClick={toggleAiHelpDisplay}
                            className="py-2 px-2 border-none text-[#1677ff] text-sm flex w-[180px] items-center gap-2 justify-center bg-[transparent] cursor-pointer"
                          >
                            {showAiHelp
                              ? "Hide Nexa's AI Help"
                              : "Ask Nexa For Help"}
                          </p>
                          <p
                            onClick={() => {
                              setSubmitClick(true);
                              localStorage.setItem(
                                currentQuestionIndex,
                                "Complete"
                              );
                            }}
                            className="py-2 px-2 border-none text-[green] text-sm flex w-[100px] items-center gap-2 justify-center bg-[#5252521c] mr-5 cursor-pointer"
                          >
                            <UploadCloud size={20} color="green" />
                            Submit
                          </p>
                        </div>
                        <QuestionContainer className="flex-1">
                          <div className="flex space-x-3 my-0 items-center">
                            {interviewTimer && <TimerComponent />}
                          </div>
                          {currentQuestion && (
                            <>
                              <QuestionComponent question={currentQuestion} />
                              {/* Add margin after Constraints */}
                              <div style={{ marginTop: "50px" }}>
                                {/* Additional content can go here if needed */}
                              </div>
                            </>
                          )}
                        </QuestionContainer>
                        {showAiHelp && (
                          <Row
                            style={{
                              marginTop: "30px",
                              flexGrow: 0,
                              marginRight: "20px",
                            }}
                          >
                            <AiChat adapter={adapter}>
                              <AiChatUI.Greeting>
                                <div
                                  className="custom-greeting"
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="/images/logo.png"
                                    alt="Logo"
                                    style={{
                                      width: "150px",
                                      marginBottom: "20px",
                                    }}
                                  />
                                  <h2>Welcome To Your Coding Assistant!</h2>
                                  <p>How can we help you today?</p>
                                </div>
                              </AiChatUI.Greeting>
                            </AiChat>
                          </Row>
                        )}
                      </div>

                      {/* Code Editor Section */}
                      <div className="w-full md:w-1/2">
                        <CodeEditorContainer
                          className="h-full md:h-560px"
                          style={{
                            height: "78vh", // Adjust for any header or other elements
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "scroll",
                          }}
                        >
                          <SelectContainer
                            style={{
                              justifyContent: "flex-start",
                              flexWrap: "wrap",
                            }}
                            className="flex space-x-2 mb-2 gap-3"
                          >
                            {fileList?.length === 5 && (
                              <Button
                                className="rounded-md mr-2 p-2"
                                onClick={handleDownloadAll}
                              >
                                Save & Export to GitHub
                              </Button>
                            )}

                            {/* <div className="mr-3 w-1/2 min-w-[185px]">
                              <Select
                                options={files?.map((e) => ({
                                  ...e,
                                  label: e.language,
                                  value: e.sign,
                                }))}
                                onChange={(e) => setSelectedFileType(e.value)}
                                styles={customStyles}
                                placeholder="Select Language"
                              />
                            </div>
                            <div className="w-1/2 min-w-[185px] m-0">
                              <Select
                                options={themes}
                                onChange={handleChangeTheme}
                                styles={customStyles}
                                placeholder="Select Theme"
                              />
                            </div> */}
                          </SelectContainer>
                          {localStorage.getItem(currentQuestionIndex) ==
                          "Complete" ? (
                            <>
                              <span className="font-bold text-center mt-5 text-[green] text-[32px]">
                                Complete
                              </span>
                              <p className="text-center">you did it!</p>
                            </>
                          ) : (
                            monoEditor()
                          )}
                          {SubmitClick && (
                            <ItemLoading
                              Endingpg={callEndingpage}
                              Question={currentQuestion}
                              Answer={questionAnswer}
                            />
                          )}
                        </CodeEditorContainer>
                      </div>
                    </TestContainer>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="mt-[12%]">
              <EndingScreen />
            </div>
          </>
        )}
      </>
    );
  }
);

/* Use memo to not re-render time component each time */
const TimerComponent = memo(({ submitTest }) => {
  const [remainingTime, setRemainingTime] = useState(600); // 10 minutes
  const timerRef = useRef(null);
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = () => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          timerRef.current = null;
          submitTest();
          return 0;
        }
        return prevTime - 1;
      });
    };
  });

  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => savedCallback.current(), 1000);
    }

    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <TimerContainer>
      <TimerIcon>⏰</TimerIcon>
      <Timer>Time Remaining: {formatTime(remainingTime)}</Timer>
    </TimerContainer>
  );
});

export default NewQuestionComponent;
