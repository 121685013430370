import ReactModal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";

const RecommendationModal = ({ isOpen, onClose, data }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "9999",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0px",
          maxWidth: "500px",
          width: "90%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <div className="flex items-center border-b border-gray-300 py-3 px-4 w-full justify-between">
        <h2 className="text-lg font-semibold text-[#2B6BE2]">
          Nexa Job Fit Insights
        </h2>
        <IoMdClose onClick={onClose} className="cursor-pointer" />
      </div>

      <div className="py-3 px-4 h-[500px] overflow-auto">
        <h2 className="text-sm font-extrabold mb-1">About Company</h2>
        <p className="mb-3">{data?.company_insights}</p>

        <h2 className="text-sm font-extrabold mb-1">About Job</h2>
        <p className="mb-3">{data?.job_insights}</p>

        <h2 className="text-sm font-extrabold mb-1">
          How Can I Be The Best Fit
        </h2>
        <ul className="mb-3">
          {data?.recommendations.map((item, index) => (
            <li key={index} className="list-disc ml-4 mb-2">
              {item}
            </li>
          ))}
        </ul>
      </div>
    </ReactModal>
  );
};

export default RecommendationModal;
