import React from "react";
import { formatJobDescription } from "../IQContext";

export const JobDescription = ({ description, employmentType }) => (
  <>
    <div className="flex flex-col white-back">
      <div className="py-2 overflow-hidden block h-auto">
        <div>
          <ul className="relative list-none px-4 list-item">
            <li
              className="text-[#4d4d4d] list-item"
              dangerouslySetInnerHTML={{
                __html: formatJobDescription(description),
              }}
            />
          </ul>
        </div>
      </div>
    </div>
    <div className="bg-white p-[8px]">
      <div className="text-sm text-[#000] flex-row items-center ml-3 -mt-2 mb-2 flex font-bold">
        {employmentType}
      </div>
    </div>
  </>
);
