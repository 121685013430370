import React, { useRef, useState } from "react";

const ScrollableTopics = ({
  handleTopicSelect,
  allquestions,
  filter,
  reset,
}) => {
  const scrollRef = useRef(null);
  const [activeTopic, setActiveTopic] = useState(
    filter === "topic"
      ? "all-topics"
      : filter === "company"
      ? "all-companies"
      : "all-difficulties"
  );

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 200,
      behavior: "smooth",
    });
  };

  let topicMap = [];

  // Extract unique topics, difficulties, or companies
  if (filter === "topic") {
    topicMap = [
      { original: "all-topics", display: "All Topics" },
      ...Array.from(
        new Set(
          allquestions.flatMap((question) => question.topics || []) // Ensure topics is always an array
        )
      ).map((topic) => ({
        original: topic,
        display: topic
          ? topic
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          : "Unknown Topic", // Fallback for invalid topics
      })),
    ];
  } else if (filter === "company") {
    topicMap = [
      { original: "all-companies", display: "All Companies" },
      ...Array.from(
        new Set(
          allquestions.flatMap((question) => question.companies || []) // Ensure companies is always an array
        )
      ).map((company) => ({
        original: company,
        display: company
          ? company
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          : "Unknown Company", // Fallback for invalid companies
      })),
    ];
  } else if (filter === "level") {
    topicMap = [
      { original: "all-difficulties", display: "All Difficulty Levels" },
      ...Array.from(
        new Set(
          allquestions.map(
            (question) => question.difficulty || "Unknown Difficulty"
          )
        )
      ).map((difficulty) => ({
        original: difficulty,
        display: difficulty,
      })),
    ];
  }

  return (
    <div className="scroll-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
        onClick={scrollLeft}
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
        fill="currentColor"
        className="h-3.5 w-3.5"
      >
        <path
          fillRule="evenodd"
          d="M11.707 7.707L7.414 12l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414zM14.414 12l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L14.414 12z"
          clipRule="evenodd"
        ></path>
      </svg>
      <div className="scrollable-wrapper" ref={scrollRef}>
        {topicMap.map((topic, index) => (
          <div
            key={index}
            className={`scroll-item cursor-pointer ${
              activeTopic === topic.original ? "active" : ""
            }`}
            onClick={() => {
              handleTopicSelect(topic.original);
              setActiveTopic(topic.original);
            }}
          >
            {topic.display}
          </div>
        ))}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
        onClick={scrollRight}
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
        fill="currentColor"
        className="h-3.5 w-3.5"
      >
        <path
          fillRule="evenodd"
          d="M12.293 16.293L16.586 12l-4.293-4.293a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414zM9.586 12L5.293 7.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L9.586 12z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default ScrollableTopics;
