import React from "react";
import { useNavigate } from "react-router-dom";
import "./Section4.css";

const Section4 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us");
  };
  return (
    <div className="default-container-section4">
      <div className="text-wrapper-4">
        <div className="narrow-text">
          <p className="text-default-blue-bold-4 text-center mb-8 FiraFontFamily bold-family">
            Key{" "}
            <span className="text-default-blue-bold-4 highlighted-word FiraFontFamily bold-family">
              Benefits
            </span>{" "}
            of Nexa for Bootcamps & Vocational Centers
          </p>
        </div>
        <div className="wide-text">
          <p className="text-default-black-4 text-center FiraFontFamily">
            Nexa is purpose-built to simplify and enhance career services with:
          </p>
        </div>
      </div>
      <div className="outer-container-4">
        {/* New Boxes Section with Divider */}
        <div className="main-content-4">
          <div className="new-boxes-container">
            {/* First Box */}
            <div className="new-box">
              <img
                src="/images/icons/double-arrow.svg"
                alt="Double Arrow Icon"
                className="new-box-icon"
              />
              <h3 className="new-box-title FiraFontFamily">
                Higher Placement Rates
              </h3>
              <p className="new-box-description FiraFontFamily">
                Use smart matching to increase placements by up to{" "}
                <span className="bolder-text FiraFontFamily">
                  30% within 3 months.
                </span>
              </p>
            </div>

            {/* Divider */}
            <img
              className="responsive-mob"
              src="/images/icons/vertical-divider.svg"
            />

            {/* Second Box */}
            <div className="new-box">
              <img
                src="/images/icons/globe.svg"
                alt="Globe Icon"
                className="new-box-icon"
              />
              <h3 className="new-box-title FiraFontFamily">
                Cost and Time Savings
              </h3>
              <p className="new-box-description FiraFontFamily">
                <span className="bolder-text FiraFontFamily">
                  Save 400+ staff hours/month
                </span>{" "}
                and reduce operational costs by{" "}
                <span className="bolder-text FiraFontFamily">80%</span> with
                automated workflows.
              </p>
            </div>
          </div>
          <img src="/images/icons/horizental-divider.svg" />
          <div className="new-boxes-container">
            {/* First Box */}
            <div className="new-box">
              <img
                src="/images/icons/click-icon.svg"
                alt="Double Arrow Icon"
                className="new-box-icon"
              />
              <h3 className="new-box-title FiraFontFamily">
                Enhanced Enrollment
              </h3>
              <p className="new-box-description FiraFontFamily">
                Attract more students with proven placement success—boost
                enrollments by{" "}
                <span className="bolder-text FiraFontFamily">
                  10% year-over-year.
                </span>
              </p>
            </div>

            {/* Divider */}
            <img
              className="responsive-mob"
              src="/images/icons/vertical-divider.svg"
            />

            {/* Second Box */}
            <div className="new-box">
              <img
                src="/images/icons/click-icon.svg"
                alt="Globe Icon"
                className="new-box-icon"
              />
              <h3 className="new-box-title FiraFontFamily">
                Personalized Student Experiences
              </h3>
              <p className="new-box-description FiraFontFamily">
                Tailor guidance to individual skills and goals, improving
                satisfaction.
              </p>
            </div>
          </div>
          <img src="/images/icons/horizental-divider.svg" />
          <div className="new-boxes-container">
            {/* First Box */}
            <div className="new-box">
              <img
                src="/images/icons/click-icon.svg"
                alt="Double Arrow Icon"
                className="new-box-icon"
              />
              <h3 className="new-box-title FiraFontFamily">
                Data-Driven Decisions
              </h3>
              <p className="new-box-description FiraFontFamily">
                Real-time{" "}
                <span className="bolder-text FiraFontFamily">insights </span>
                refine career services for better outcomes
              </p>
            </div>

            {/* Divider */}
            <img
              className="responsive-mob"
              src="/images/icons/vertical-divider.svg"
            />

            {/* Second Box */}
            <div className="new-box-button">
              <button
                onClick={handleClick}
                className="try-nexa-button FiraFontFamily"
              >
                Try Nexa Right Now
              </button>
            </div>
          </div>
        </div>
        <div className="zoom-container" style={{ overflow: "hidden" }}>
          <img
            className="Womensitting"
            src="/images/Bootcamp/TransformBootCampSection4.png"
            alt="Women sitting"
          />
        </div>
      </div>
    </div>
  );
};

export default Section4;
