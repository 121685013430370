import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import DatePicker from "../../Components/Resume/DatePicker";
import {
  addEducation,
  updateEducation,
  removeEducation,
  addResumeEducation,
  getResumeEducation,
  deleteResumeEducation,
  udpateResumeEducation,
  updateResumeSectionPosition,
} from "../../Redux/ResumeBuilderSlice";
import RichTextEditor from "../RichTextEditor";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const EducationForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { educations } = useSelector((state) => state.ResumeBuilder);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [education, setEducations] = useState(educations);
  const [editorContent, setEditorContent] = useState("");
  const [editorContentData, setEditorContentData] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [updateDocId, setUpdateDocId] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleAddEducation = () => {
    setIsFormVisible(true);
  };

  const handleEditingIndex = (index, id) => {
    setIsFormVisible(true);
    setSelectedIndex(index);
    setUpdateDocId(id);
    const foundObj = education.find((obj, idx) => idx === index);

    reset({
      school_name: foundObj?.school_name,
      location: foundObj?.location,
      degree: foundObj?.degree,
      gpa: foundObj?.gpa,
      field: foundObj?.field,
      started_at: foundObj?.started_at,
      ended_at: foundObj?.ended_at,
      additional_information: foundObj?.additional_information,
    });
    setEditorContentData(foundObj?.additional_information);
    setSelectedStartDate(
      foundObj.started_at ? new Date(foundObj.started_at) : new Date()
    );
    setSelectedEndDate(
      foundObj.ended_at ? new Date(foundObj.ended_at) : new Date()
    );
  };

  const onCancel = () => {
    setIsFormVisible(false);
    reset({
      school_name: "",
      location: "",
      degree: "",
      gpa: "",
      field: "",
      started_at: "",
      ended_at: "",
      additional_information: "",
    });
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
    setEditorContentData("");
  };

  const handleDelete = (payload) => {
    dispatch(
      deleteResumeEducation({
        payload,
        onSuccess: (res) => {
          success(res);
          getEducationInfo();
        },
      })
    );
  };

  const onSubmit = (data) => {
    const timeZoneOffset = selectedStartDate.getTimezoneOffset() * 60000;
    data.started_at = new Date(selectedStartDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    data.ended_at = new Date(selectedEndDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];
    data.additional_information = editorContent;

    if (selectedIndex !== -1) {
      const payload = {
        updateDocId,
        data,
      };
      const nowData = new Date();
      const endedAtDate = new Date(payload?.data?.ended_at);
      if (payload?.data?.started_at >= payload?.data?.ended_at) {
        error("Start date cannot be greater than or equal to end date");
        return;
      }
      if (endedAtDate > nowData) {
        error("End date cannot be greater than current date");
        return;
      }
      dispatch(
        udpateResumeEducation({
          payload,
          onSuccess: (res) => {
            success(res);
            getEducationInfo();
          },
        })
      );
    } else {
      const payload = data;
      const nowData = new Date();
      const endedAtDate = new Date(payload?.ended_at);
      if (payload.started_at >= payload.ended_at) {
        error("Start date cannot be greater than or equal to end date");
        return;
      }
      if (endedAtDate > nowData) {
        error("End date cannot be greater than current date");
        return;
      }
      dispatch(
        addResumeEducation({
          payload,
          id,
          onSuccess: (res) => {
            success(res);
            getEducationInfo();
          },
        })
      );
    }
    setIsFormVisible(false);
    reset({
      school_name: "",
      location: "",
      degree: "",
      gpa: "",
      field: "",
      started_at: "",
      ended_at: "",
      additional_information: "",
    });
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
    setEditorContentData("");
    setSelectedIndex(-1);
  };

  const getEducationInfo = () => {
    const resumeId = id;
    dispatch(getResumeEducation({ resumeId, onSuccess: () => {} }));
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(education);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...education];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setEducations(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          education: updatedSections,
        },
        onSuccess: () => {
          getEducationInfo();
        },
        onError: () => {
          setEducations(previousSections);
        },
      })
    );
  };
  useEffect(() => {
    if (educations?.length > 0) {
      const sortedEducations = educations
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setEducations(sortedEducations);
    }
  }, [educations]);
  return (
    <div className="mydetails rounded-xl px-0 pb-0">
      {isFormVisible && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="px-3 mb-2 pt-2 border-bottom border-primary"
        >
          <div className="d-flex flex-column mb-1">
            <div className="form-group w-100">
              <label htmlFor="school">School or Institution</label>
              <input
                type="text"
                id="school"
                className="form-control"
                placeholder="MIT"
                {...register("school_name", {
                  required: "School name is required",
                })}
              />
              {errors.school_name && (
                <span className="text-danger">
                  {errors.school_name.message}
                </span>
              )}
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50 ">
              <label htmlFor="location">Location</label>
              <input
                type="text"
                id="location"
                className="form-control"
                placeholder="New York"
                {...register("location", {
                  required: "School Location is required",
                })}
              />
              {errors.location && (
                <span className="text-danger">{errors.location.message}</span>
              )}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="gpa">GPA</label>
              <input
                type="number"
                id="gpa"
                className="form-control"
                step="0.01"
                placeholder=""
                {...register("gpa", {
                  required: "GPA is required",
                  validate: (value) => {
                    const regex = /^\d(\.\d{1,2})?$/;
                    return (
                      (regex.test(value) && value >= 0 && value <= 4) ||
                      "Please enter a valid GPA: max value is 4 with 1 digit before the decimal, up to 2 digits after, and no negative values"
                    );
                  },
                })}
                onChange={(e) => {
                  let value = e.target.value;
                  const regex = /^\d(\.\d{1,2})?$/;

                  // Prevent negative values and values greater than 4
                  if (value < 0 || value > 4) {
                    e.target.value = value > 4 ? "4" : "";
                    return;
                  }

                  // Trim to 1 digit before and 2 digits after decimal
                  if (!regex.test(value)) {
                    if (value.includes(".")) {
                      const [intPart, decPart] = value.split(".");
                      value =
                        intPart.slice(0, 1) +
                        "." +
                        (decPart ? decPart.slice(0, 2) : "");
                    } else {
                      value = value.slice(0, 1);
                    }
                    e.target.value = value;
                  }
                }}
              />

              {errors.gpa && (
                <span className="text-danger">{errors.gpa.message}</span>
              )}
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50 ">
              <label htmlFor="degree">Degree</label>
              <input
                type="text"
                id="degree"
                className="form-control"
                placeholder=""
                {...register("degree", {
                  required: "Degree is required",
                })}
              />
              {errors.degree && (
                <span className="text-danger">{errors.degree.message}</span>
              )}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="field">Field of Study</label>
              <input
                type="text"
                id="field"
                className="form-control"
                placeholder=""
                {...register("field", {
                  required: "Feild of Study is required",
                })}
              />
              {errors.field && (
                <span className="text-danger">{errors.field.message}</span>
              )}
            </div>
          </div>

          <div className="d-flex mb-1 flex-col md:flex-row">
            <div className="form-group mr-2 w-full md:w-50">
              <label htmlFor="started_at">Start Date</label>
              <DatePicker
                selectedDate={selectedStartDate}
                onSelect={setSelectedStartDate}
              />
            </div>
            <div className="form-group ml-0 md:ml-3 w-full md:w-50">
              <label htmlFor="ended_at">End Date</label>
              <DatePicker
                selectedDate={selectedEndDate}
                onSelect={setSelectedEndDate}
              />
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="form-group w-100">
              <label htmlFor="additionalInformation">
                Additional Information
              </label>
              <div className="customized-editor">
                <RichTextEditor
                  setEditorContent={setEditorContent}
                  defaultContent={editorContentData}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end py-2">
            <button
              type="button"
              className="cancel-btn mr-3"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
            >
              Save
            </button>
          </div>
        </form>
      )}
      {!isFormVisible && (
        <div className="professionalsummary-item px-2 pb-1 border-bottom border-0">
          <button
            className="professionalitem-button pl-2"
            onClick={handleAddEducation}
          >
            <i className="ri-add-circle-line" style={{ fontSize: "20px" }}></i>
            <span className="ml-2" style={{ fontSize: "18px" }}>
              Add Education
            </span>
          </button>
        </div>
      )}
      {education &&
        education?.map((values, index) => (
          <div
            key={index}
            className="professionalsummary-item professionalsummary-item-block p-2 w-auto"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="d-flex">
              <p className="mr-2">{values?.school_name}</p>
              <p>{values?.degree}</p>
            </div>
            {hoveredIndex === index && (
              <div className="professionalitem-actions rounded-l-xl w-auto px-2 border">
                <button className="relative group professionalitem-button">
                  <FiChevronUp
                    className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer ${
                      index == 0 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: index - 1,
                        source: index,
                      });
                    }}
                  />
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Move Up
                  </div>
                </button>
                <button className="relative group professionalitem-button">
                  <FiChevronDown
                    className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer  ${
                      index == educations.length - 1 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: index + 1,
                        source: index,
                      });
                    }}
                  />
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Move Down
                  </div>
                </button>
                <button
                  className="professionalitem-button"
                  onClick={() => handleEditingIndex(index, values?.id)}
                >
                  <i
                    className="ri-edit-line relative group"
                    style={{ fontSize: "18px" }}
                  >
                    <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                      Edit Education
                    </div>
                  </i>
                </button>
                <button
                  className="professionalitem-button"
                  onClick={() => handleDelete(values?.id)}
                >
                  <i
                    className="ri-delete-bin-line relative group"
                    style={{ fontSize: "18px" }}
                  >
                    <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                      Delete Education
                    </div>
                  </i>
                </button>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default EducationForm;
