import React from "react";

const MILLISECONDS_PER_SECOND = 1000;

// Function to call OpenAI API with streaming
const callOpenAIStream = async (endpoint, payload, onIncomingChunk) => {
  console.log("Final payload sent to OpenAI:", payload);

  const response = await fetch(`https://api.openai.com/v1/${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error("Error response from OpenAI:", errorText);
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  if (!response.body) {
    throw new Error("No body included in the response object");
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  let content = "";

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    const chunk = decoder.decode(value);
    const lines = chunk.split("\n");

    for (const line of lines) {
      const trimmedLine = line.trim();

      if (!trimmedLine) continue;

      if (trimmedLine.startsWith("data: ")) {
        const jsonString = trimmedLine.slice(6); // Remove "data: "
        if (jsonString === "[DONE]") break;

        try {
          const parsed = JSON.parse(jsonString);
          const messagePart = parsed.choices[0]?.delta?.content || "";
          content += messagePart;
          onIncomingChunk(messagePart);
        } catch (err) {
          console.error("Error parsing streaming chunk:", err);
        }
      }
    }
  }

  return content;
};

// IndexedDB helper functions
export const openDB = (dbName, version, upgradeCallback) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, version);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      upgradeCallback(db);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const addData = (db, storeName, data) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.put(data);

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const getData = (db, storeName, id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get(id);

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const useChatLogs = ({
  storeChats = true,
  chatDB = "chatDB",
  url = "/nexavoyce/stream-response",
}) => {
  const [messages, _setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [controller, setController] = React.useState(null);

  const submitPrompt = React.useCallback(
    async (prompt) => {
      if (loading) return;

      setLoading(true);

      const newMessage = {
        content: "",
        role: "assistant",
        meta: { loading: true },
      };
      const contextMessages = messages
        .filter(({ content, role }) => typeof content === "string" && role)
        .map(({ content, role }) => ({ content, role }));

      _setMessages((prev) => [
        ...prev,
        { content: prompt, role: "user" },
        newMessage,
      ]);

      try {
        const abortController = new AbortController();
        setController(abortController);

        const payload = {
          model: "gpt-4-turbo",
          messages: [...contextMessages, { role: "user", content: prompt }],
          stream: true,
        };

        console.log("Payload being sent:", payload);

        await callOpenAIStream("chat/completions", payload, (chunk) => {
          _setMessages((prev) =>
            prev.map((msg, i) => {
              if (i === prev.length - 1) {
                return { ...msg, content: msg.content + chunk };
              }
              return msg;
            })
          );
        });
      } catch (err) {
        console.error("Error during API call:", err);
      } finally {
        setLoading(false);
      }
    },
    [loading, messages]
  );

  const resetChat = React.useCallback(() => {
    if (!loading) {
      _setMessages([]);
    }
  }, [loading]);

  const emptyMessages = () => {
    _setMessages([]);
  };

  return {
    messages,
    loading,
    submitPrompt,
    resetChat,
    emptyMessages,
  };
};

export default useChatLogs;
