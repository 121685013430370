import React from "react";
import { buildPayload, createSearchPayload } from "./IQContext";
import { getJobListings } from "../../../Redux/NexaIqSlice";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

const IqTab = ({ activeTab, setActiveTab, isQuickApply, setIsLoading }) => {
  //Parameter Initialization
  const dispatch = useDispatch();
  const { jobsPreference } = useSelector((state) => state.UserProfile);
  const [searchParams] = useSearchParams();
  const paramsObjects = Object.fromEntries(searchParams.entries());

  //Functions
  const handleTabClick = (tab) => {
    setIsLoading((prev) => ({
      ...prev,
      isMainShimmer: true,
    }));
    const prePayload = createSearchPayload(jobsPreference, paramsObjects);
    const payload = buildPayload({
      activeTab: tab,
      filters: prePayload,
    });
    dispatch(
      getJobListings({
        payload,
        page: 1,
        jobApplied: tab !== "myJobs",
        nexa_jobs: isQuickApply,
        onSuccess: () => {
          setIsLoading((prev) => ({
            ...prev,
            isMainShimmer: false,
          }));
        },
        onError: () => {
          setIsLoading((prev) => ({
            ...prev,
            isMainShimmer: false,
          }));
        },
      })
    );
    setActiveTab(tab);
  };

  return (
    <div className="flex justify-end items-center  bg-transparent nexaIqTab-container border-b">
      <ul className="list-reset flex border-b">
        <li className="-mb-px mr-1">
          <a
            className={`border-b inline-block py-2 px-4 font-semibold ${
              activeTab === "myJobs"
                ? " border-l border-t border-r rounded-t text-primary"
                : "text-dark"
            }`}
            href="#"
            onClick={() => handleTabClick("myJobs")}
          >
            Current Open Opportunities
          </a>
        </li>

        <li className="mr-1">
          <a
            className={`inline-block py-2 px-4 font-semibold ${
              activeTab === "Applied Jobs"
                ? " border-l border-t border-r rounded-t text-primary"
                : "text-dark"
            }`}
            href="#"
            onClick={() => handleTabClick("Applied Jobs")}
          >
            Applied Jobs
          </a>
        </li>
      </ul>
    </div>
  );
};

export default IqTab;
