import React, { useState, useRef, useEffect } from "react";
import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { success } from "../../../Utilities/toast";

import { useParams } from "react-router-dom";
import DatePicker from "../DatePicker";
import {
  getResumePublication,
  updateResumePublication,
  updateResumeSectionPosition,
} from "../../../Redux/ResumeBuilderSlice";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function Publications({ publications, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeField, setActiveField] = useState({ index: null, field: null });
  const [hoveredField, setHoveredField] = useState({
    index: null,
    field: null,
  });
  const [publicationsState, setPublicationsState] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(null);
  const editableRefs = useRef({});
  const [hoveredSection, setHoveredSection] = useState(null);
  const [activeSection, setActiveSection] = useState(null);

  const iconStyle = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date();
    return date.toLocaleDateString(undefined, options);
  };
  const handleFieldClick = (index, field) => {
    if (activeField.index !== null) submitChanges();
    setActiveField({ index, field });
    if (field === "date") setDatePickerOpen(index);
    setTimeout(() => {
      editableRefs.current[`${index}-${field}`]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, field, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[`${index}-${field}`];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setPublicationsState((prev) =>
        prev.map((pub, idx) =>
          idx === index ? { ...pub, [field]: value } : pub
        )
      );
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    }
  };

  const handleDateChange = (index, field, selectedDate) => {
    const date = new Date(selectedDate); // Local date
    date.setHours(19, 0, 0, 0); // Set the time to 19:00:00
    const formattedDate = date.toISOString(); // Save as ISO 8601 string
    setPublicationsState((prev) =>
      prev.map((pub, idx) =>
        idx === index ? { ...pub, [field]: formattedDate } : pub
      )
    );
  };
  const fetchPublications = () => {
    dispatch(getResumePublication({ ID: id, onSuccess: () => {} }));
  };
  const submitChanges = () => {
    const originalPublication = publications[activeField.index];
    const updatedPublication = publicationsState[activeField.index];
    const hasChanges =
      originalPublication[activeField.field]?.trim() !==
      updatedPublication[activeField.field]?.trim();

    if (hasChanges) {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      const payload = {
        title: updatedPublication?.title,
        publisher: updatedPublication?.publisher,
        date: updatedPublication?.date,
      };
      dispatch(
        updateResumePublication({
          ID: updatedPublication?.id,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchPublications();
          },
        })
      );
    } else {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
    }
  };

  const renderField = (publication, index, field) => {
    const isActive =
      activeField?.index === index && activeField?.field === field;
    const isHovered =
      hoveredField?.index === index && hoveredField?.field === field;
    const originalPublication = publications[index];
    const updatedPublication = publicationsState[index];
    const hasChanges =
      field &&
      originalPublication &&
      updatedPublication &&
      originalPublication[field]?.trim() !== updatedPublication[field]?.trim();

    return (
      <span
        className={`editable-field ${isActive ? "active" : ""}`}
        onMouseEnter={() => setHoveredField({ index, field })}
        onMouseLeave={() => setHoveredField({ index: null, field: null })}
        onDoubleClick={() => handleFieldClick(index, field)}
        style={{
          position: "relative",
          border: isActive ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={isActive}
          ref={(el) => (editableRefs.current[`${index}-${field}`] = el)}
          onInput={(e) => handleFieldInput(index, field, e)}
          onBlur={activeField.field != "date" ? submitChanges : ""}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          className={`editable-field`}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {field === "date" && datePickerOpen === index ? (
            <input
              type="date"
              id="start"
              name="trip-start"
              value={
                publication && !isNaN(new Date(publication).getTime())
                  ? new Date(publication).toLocaleDateString("en-CA") // Ensures 'YYYY-MM-DD' format
                  : ""
              }
              onChange={(e) => handleDateChange(index, field, e.target.value)}
            />
          ) : (
            publication
          )}
        </span>
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...iconStyle, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && !hasChanges && (
          <MdClose
            onClick={() => {
              submitChanges();
            }}
            style={{ ...iconStyle, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, field)}
            style={{ ...iconStyle, right: "-20px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(publicationsState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...publicationsState];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setPublicationsState(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          publications: updatedSections,
        },
        onSuccess: () => {
          dispatch(getResumePublication({ ID: id, onSuccess: () => {} }));
        },
        onError: () => {
          setPublicationsState(previousSections);
        },
      })
    );
  };
  useEffect(() => {
    if (publications?.length > 0) {
      const sortedPublications = publications
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setPublicationsState(sortedPublications);
    }
  }, [publications]);
  return (
    <section className="cv-certifications">
      {publicationsState?.map((publication, index) => {
        const isActive = activeSection === index;
        const isHovered = hoveredSection === index;
        return (
          <>
            <div
              className={`cv-publication-item d-flex mt-2 justify-content-between align-items-center editable-field my-1 relative ${
                isActive || isHovered ? "editable-field active bg-white" : ""
              }`}
              onMouseEnter={() => setHoveredSection(index)}
              onMouseLeave={() => setHoveredSection(null)}
              onClick={() => {
                if (index !== activeSection && publicationsState.length !== 1) {
                  setActiveSection(index);
                } else {
                  setActiveSection(null);
                }
              }}
              style={{
                border:
                  isActive || isHovered
                    ? "1px solid #ffff"
                    : "1px solid transparent",
                backgroundColor: isActive ? "#ffff" : "transparent",
                cursor: "text",
              }}
              key={index}
            >
              {isActive && (
                <div className="absolute -top-6 left-1/2 -translate-x-1/2 bg-blue-500 text-white text-xs font-semibold py-2 px-4 rounded-full shadow-md flex items-center gap-2">
                  {/* Upper Arrow */}
                  <FiChevronUp
                    className={`text-white text-lg cursor-pointer ${
                      activeSection == 0 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: activeSection - 1,
                        source: activeSection,
                      });
                    }}
                  />

                  {/* Lower Arrow */}
                  <FiChevronDown
                    className={`text-white text-lg cursor-pointer  ${
                      activeSection == publicationsState.length - 1
                        ? "hidden"
                        : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: activeSection + 1,
                        source: activeSection,
                      });
                    }}
                  />
                </div>
              )}
              <div>
                <h5
                  className="cv-item-title text-md  mb-0 apply-font"
                  style={{
                    color: `${
                      getSingleResumeDoc?.subheading_color
                        ? getSingleResumeDoc?.subheading_color
                        : "#000"
                    }`,
                  }}
                >
                  {renderField(publication?.title, index, "title")}
                </h5>
                <p className="cv-item-date mt-2 apply-font">
                  {renderField(publication?.publisher, index, "publisher")}
                </p>
              </div>
              <p className="cv-item-description apply-font">
                {renderField(formatDate(publication?.date), index, "date")}
              </p>
            </div>

            {publicationsState?.length > 0 &&
              index !== publicationsState?.length - 1 && (
                <hr className="mt-2" />
              )}
          </>
        );
      })}
    </section>
  );
}
