import React, { useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const ToDateField = ({ handleInputChange, fieldValue }) => {
  const calendarRef = useRef(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const toggleCalendar = () => setIsCalendarOpen((prev) => !prev);

  const handleDateSelect = (date) => {
    console.log(date, "selectedStartDate");
    if (!date) return;
    setIsCalendarOpen(false);
    const timeZoneOffset = date.getTimezoneOffset() * 60000;
    const formattedDate = new Date(date - timeZoneOffset)
      .toISOString()
      .split("T")[0];
    handleInputChange({
      target: { name: "toDate", value: formattedDate },
    });
  };

  // Close calendar on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <label
        htmlFor="toDate"
        className="block text-sm font-medium text-gray-700"
      >
        Start Date
      </label>
      <input
        type="text"
        name="toDate"
        id="toDate"
        placeholder="Select a Start date"
        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer"
        value={fieldValue || ""}
        onClick={toggleCalendar}
        readOnly
      />
      {isCalendarOpen && (
        <div
          ref={calendarRef}
          className="absolute mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-[999]"
        >
          <DayPicker
            mode="single"
            selected={
              fieldValue
                ? new Date(fieldValue.split("/").reverse().join("-"))
                : undefined
            }
            onSelect={handleDateSelect}
          />
        </div>
      )}
    </div>
  );
};

export default ToDateField;
