import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdDone, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getResumeEducation,
  udpateResumeEducation,
  updateResumeSectionPosition,
} from "../../../Redux/ResumeBuilderSlice";
import { success } from "../../../Utilities/toast";
import RichTextEditor from "../../../features/RichTextEditor";
import { createMarkup } from "../../../Utilities/CreateMarkup";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function Educations({ educations, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeField, setActiveField] = useState({ index: null, field: null });
  const [hoveredField, setHoveredField] = useState({
    index: null,
    field: null,
  });
  const [educationsState, setEducationsState] = useState(educations || []);
  const [datePickerOpen, setDatePickerOpen] = useState(null);
  const [textEditorOpen, setTextEditorOpen] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [defaultContent, setDefaultContent] = useState("");
  const [hoveredSection, setHoveredSection] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const editableRefs = useRef({});

  const iconStyle = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date();
    return date.toLocaleDateString(undefined, options);
  };
  const handleFieldClick = (index, field) => {
    if (activeField.index !== null) submitChanges();
    setActiveField({ index, field });
    if (field === "ended_at") {
      setDatePickerOpen(index);
    } else if (field === "additional_information") {
      const content = educationsState[index]?.additional_information || "";
      setEditorContent(content);
      setDefaultContent(content);
      setTextEditorOpen(`${index}-${field}`);
    }
    setTimeout(() => {
      editableRefs.current[`${index}-${field}`]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, field, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[`${index}-${field}`];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setEducationsState((prev) =>
        prev.map((pub, idx) =>
          idx === index ? { ...pub, [field]: value } : pub
        )
      );
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    }
  };

  const handleDateChange = (index, field, selectedDate) => {
    const date = new Date(selectedDate); // Local date
    date.setHours(19, 0, 0, 0); // Set the time to 19:00:00 (local time)
    const formattedDate = date.toISOString();
    setEducationsState((prev) =>
      prev.map((pub, idx) =>
        idx === index ? { ...pub, [field]: formattedDate } : pub
      )
    );
  };
  const getEducationInfo = () => {
    dispatch(getResumeEducation({ resumeId: id, onSuccess: () => {} }));
  };
  const submitChanges = () => {
    const originalEducation = educations[activeField.index];
    const updatedEducation = educationsState[activeField.index];
    const hasChanges =
      originalEducation[activeField.field]?.trim() !==
      updatedEducation[activeField.field]?.trim();

    if (hasChanges) {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      setTextEditorOpen(null);
      const payload = {
        updateDocId: updatedEducation?.id,
        data: updatedEducation,
      };
      dispatch(
        udpateResumeEducation({
          payload,
          onSuccess: (res) => {
            success(res);
            getEducationInfo();
          },
        })
      );
    } else {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      setTextEditorOpen(null);
    }
  };
  const updateEducationsState = (index, field, value) => {
    setEducationsState((prev) =>
      prev.map((edu, idx) => (idx === index ? { ...edu, [field]: value } : edu))
    );
  };
  const renderField = (education, index, field) => {
    const isActive = activeField.index === index && activeField.field === field;
    const isHovered =
      hoveredField?.index === index && hoveredField?.field === field;

    const originalEducation = educations[index];
    const updatedEducation = educationsState[index];

    // Check if field exists and compare only if valid
    const hasChanges =
      field &&
      originalEducation &&
      updatedEducation &&
      originalEducation[field] !== updatedEducation[field];

    return (
      <span
        className={`editable-field ${isActive ? "active" : ""}`}
        onMouseEnter={() => setHoveredField({ index, field })}
        onMouseLeave={() => setHoveredField({ index: null, field: null })}
        onDoubleClick={() => handleFieldClick(index, field)}
        style={{
          position: "relative",
          border: isActive ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={
            field === "additional_information" ? false : isActive
          }
          ref={(el) => (editableRefs.current[`${index}-${field}`] = el)}
          onInput={(e) => handleFieldInput(index, field, e)}
          onBlur={activeField.field != "ended_at" ? submitChanges : ""}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          className={`editable-field`}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {field === "ended_at" && datePickerOpen === index ? (
            <input
              type="date"
              id="start"
              name="trip-start"
              value={
                education && !isNaN(new Date(education).getTime())
                  ? new Date(education).toLocaleDateString("en-CA") // Converts to 'YYYY-MM-DD' format
                  : ""
              }
              onChange={(e) => handleDateChange(index, field, e.target.value)}
            />
          ) : field === "additional_information" &&
            textEditorOpen === `${index}-${field}` ? (
            <div className=" customized-editor mt-2  ">
              <RichTextEditor
                defaultContent={defaultContent}
                setEditorContent={setEditorContent}
              />
            </div>
          ) : field === "additional_information" ? (
            <p dangerouslySetInnerHTML={createMarkup(education)} />
          ) : (
            education
          )}
        </span>
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...iconStyle, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && !hasChanges && (
          <MdClose
            onClick={() => {
              submitChanges();
            }}
            style={{ ...iconStyle, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, field)}
            style={{ ...iconStyle, right: "-20px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(educationsState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...educationsState];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setEducationsState(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          education: updatedSections,
        },
        onSuccess: () => {
          dispatch(getResumeEducation({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setEducationsState(previousSections);
        },
      })
    );
  };
  useEffect(() => {
    if (editorContent) {
      updateEducationsState(
        activeField.index,
        activeField.field,
        editorContent
      );
    }
  }, [editorContent]);
  useEffect(() => {
    if (educations?.length > 0) {
      const sortedEducations = educations
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setEducationsState(sortedEducations);
    }
  }, [educations]);
  return (
    educationsState &&
    educationsState?.length > 0 && (
      <section className="cv-projects">
        {educationsState?.map((education, index) => {
          const isActive = activeSection === index;
          const isHovered = hoveredSection === index;
          return (
            <>
              <div
                className={`editable-field my-1 relative w-full ${
                  isActive || isHovered ? "editable-field active bg-white" : ""
                }`}
                onMouseEnter={() => setHoveredSection(index)}
                onMouseLeave={() => setHoveredSection(null)}
                onClick={() => {
                  if (index !== activeSection && educationsState.length !== 1) {
                    setActiveSection(index);
                  } else {
                    setActiveSection(null);
                  }
                }}
                style={{
                  border:
                    isActive || isHovered
                      ? "1px solid #ffff"
                      : "1px solid transparent",
                  backgroundColor: isActive ? "#ffff" : "transparent",
                  cursor: "text",
                }}
                key={index}
              >
                {isActive && (
                  <div className="absolute -top-6 left-1/2 -translate-x-1/2 bg-blue-500 text-white text-xs font-semibold py-2 px-4 rounded-full shadow-md flex items-center gap-2">
                    {/* Upper Arrow */}
                    <FiChevronUp
                      className={`text-white text-lg cursor-pointer ${
                        activeSection == 0 ? "hidden" : ""
                      }`}
                      onClick={() => {
                        handleOnDragEnd({
                          destination: activeSection - 1,
                          source: activeSection,
                        });
                      }}
                    />

                    {/* Lower Arrow */}
                    <FiChevronDown
                      className={`text-white text-lg cursor-pointer  ${
                        activeSection == educationsState.length - 1
                          ? "hidden"
                          : ""
                      }`}
                      onClick={() => {
                        handleOnDragEnd({
                          destination: activeSection + 1,
                          source: activeSection,
                        });
                      }}
                    />
                  </div>
                )}

                <div className="d-flex justify-content-between mt-2 align-items-center">
                  <div>
                    <h5
                      className="cv-item-title text-md  mb-0 apply-font"
                      style={{
                        color: `${
                          getSingleResumeDoc?.subheading_color
                            ? getSingleResumeDoc?.subheading_color
                            : "#000"
                        }`,
                      }}
                    >
                      {renderField(
                        education.school_name && education?.school_name,
                        index,
                        "school_name"
                      )}
                    </h5>
                    <p className="cv-item-date apply-font mt-2">
                      {renderField(education?.degree, index, "degree")} {"|"}{" "}
                      {renderField(education?.field, index, "field")}
                    </p>
                  </div>
                  <div>
                    <p className="cv-item-date text-capitalize apply-font">
                      {renderField(
                        formatDate(education?.ended_at),
                        index,
                        "ended_at"
                      )}
                    </p>
                  </div>
                </div>

                <p
                  style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                  className={`cv-item-date apply-font px-2 ${
                    getSingleResumeDoc?.font_style &&
                    getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
                  }`}
                >
                  {renderField(
                    education?.additional_information,
                    index,
                    "additional_information"
                  )}
                </p>
              </div>
              {educationsState?.length > 0 &&
                index !== educationsState?.length - 1 && (
                  <hr className="mt-2" />
                )}
            </>
          );
        })}
      </section>
    )
  );
}
