import React from "react";
import "./Section2.css";

const CheckmarkItem = ({ text }) => {
  return (
    <div className="checkmark-item">
      <img
        src="/images/icons/bleue-checkmark.svg"
        alt="Checkmark"
        className="checkmark-icon"
      />
      <p className="checkmark-text">{text}</p>
    </div>
  );
};

const Section2 = () => {
  const items = [
    "Manual career services are labor-intensive and outdated.",
    "Tracking outcomes for graduates is challenging and inconsistent.",
    "Limited resources to provide personalized career guidance.",
    "Lack of visibility into job-matching success.",
    "Inefficient workflows increase operational strain on staff.",
  ];

  return (
    <div className="default-container-section2">
      <div className="text-wrapper-section2">
        <div className="narrow-text">
          <p className="text-default-blue-bold-section2 text-center mb-8 FiraFontFamily bold-family">
            As a Training Organization, We{" "}
            <span className="text-default-blue-bold-section2 highlighted-word FiraFontFamily bold-family">
              UNDERSTAND
            </span>{" "}
            Your Challenges
          </p>
        </div>
        <div className="wide-text">
          <p className="text-default-black-section2 text-center FiraFontFamily">
            Bootcamps and vocational centers are designed to prepare students
            for the job market, but managing placements and student success can
            be overwhelming:
          </p>
        </div>
      </div>
      <div className="content-container-section2">
        <div className="checkmark-section">
          {items.map((item, index) => (
            <CheckmarkItem key={index} text={item} />
          ))}
        </div>
        <div className="image-container zoom-container">
          <img
            src="/images/Bootcamp/TransformBootCampSection2.png"
            alt="BootCamp Section 2"
            className="section-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Section2;
