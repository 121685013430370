import React, { useEffect, useRef, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { FaRegCopy } from "react-icons/fa";
import { FaRegFile } from "react-icons/fa6";
import {
  IoChatbubbleEllipsesOutline,
  IoMicOutline,
  IoMicSharp,
  IoSendSharp,
} from "react-icons/io5";
import MDX from "../../Components/MDX";
import Header from "../../Components/header";
import { error, info } from "../../Utilities/toast";
import useChatLogs from "../../hook/chatStream";
import {
  AttachmentButton,
  InputContainer,
  MessageItem,
  MicContainer,
  SendButton,
  VoyaceStaticMsgs,
} from "./voyace.style";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();
mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

let voyace_static_messages = [
  "How do I enhance my LinkedIn Profile",
  "Create my career journey",
  "Effective Strategies for finding job",
  "What is good workplace etiquette?",
  "⁠How can I improve my resume to land an interview ?",
  "What networking strategies should I adapt to expand my professional network ?",
  "⁠What career resources are there in the United States Labor Department for professionals ?",
  "⁠What are some effective strategies for negotiating a salary ?",
  "How can I handle career setbacks, layoffs, or job changes, effectively ?",
];

const VoyceSimulation = () => {
  const [input, setInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const { messages, submitPrompt, uploadFile, loading, emptyMessages } =
    useChatLogs({
      storeChats: true,
      chatDB: "chatDB",
      url: "/nexavoyce/stream-response",
    });
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const chatContainerRef = useRef(null);

  const [options, SetOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [NewMessages, setNewMessages] = useState([]);
  const [NewMessagesselected, setNewMessagesselected] = useState(true);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scroll({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isListening) {
      startRecording();
      handleListen();
    } else {
      stopRecording();
      mic.stop();
    }
  }, [isListening]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const startRecording = async () => {
    try {
      if (mediaRecorder && mediaRecorder.state === "recording") {
        return;
      }
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (e) => {};
      setMediaRecorder(recorder);
      recorder.start();
    } catch (error) {
      console.error("Error accessing microphone: ", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setMediaRecorder(null);
    }
  };

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {};
      mic.onstart = () => {};
      mic.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        setNote(transcript);
        setInput(transcript);
        mic.onerror = (event) => {};
      };
    } else {
      mic.stop();
      mic.onend = () => {};
    }
  };

  useEffect(() => {
    const storedHistory = JSON.parse(localStorage.getItem("History")) || [];
    const historyOptions = storedHistory.map((chat) => ({
      value: chat.id,
      label: chat.label,
    }));
    SetOptions(historyOptions);
  }, []);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleIconChange = (value) => {
    setInput(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setInput(e.target.value);
      handleSendMessage();
    }
  };

  const handleSendMessage = async (value) => {
    const currentInput = value || input;
    if (!currentInput?.length) {
      error("Message cannot be blank");
      return;
    }

    if (file) {
      uploadFile(file, currentInput);
      setFile(null);
      setImagePreview(null);
    } else {
      submitPrompt(currentInput, file);
    }

    setInput("");
    setNote("");

    const storedHistory = JSON.parse(localStorage.getItem("History")) || [];

    if (storedHistory.length === 0) {
      const newChat = {
        id: "chat_1",
        label: currentInput,
        messages: [{ role: "user", content: currentInput }],
      };
      storedHistory.push(newChat);
    } else {
      if (messages.length === 0) {
        const newChatId = `chat_${storedHistory.length + 1}`;
        const newChat = {
          id: newChatId,
          label: currentInput,
          messages: [{ role: "user", content: currentInput }],
        };
        storedHistory.push(newChat);
      } else {
        const lastChat = storedHistory[storedHistory.length - 1];
        lastChat.messages.push({ role: "user", content: currentInput });
      }
    }

    localStorage.setItem("History", JSON.stringify(storedHistory));

    SetOptions(
      storedHistory.map((chat) => ({
        value: chat.id,
        label: chat.label,
      }))
    );
  };

  const handleNewChatBtn = async () => {
    console.log("new chat----------");
    try {
      // Clear localStorage
      // localStorage.removeItem("History");`

      // Clear IndexedDB
      const request = indexedDB.deleteDatabase("chatDB");
      request.onsuccess = () => {
        console.log("Database deleted successfully");
      };

      // Reset all states
      setNewMessages([]);
      emptyMessages();
      setNewMessagesselected(true);
      setInput("");
      setSelectedOption(null);
      // SetOptions([]);

      // Clear any active states
      if (isListening) {
        setIsListening(false);
        if (mediaRecorder) {
          stopRecording();
        }
      }
      setFile(null);
      setImagePreview(null);

      // Force reload the component to ensure clean state
      // window.location.reload();
    } catch (error) {
      console.error("Error clearing chat:", error);
    }
  };

  useEffect(() => {
    const storedHistory = JSON.parse(localStorage.getItem("History")) || [];
    if (storedHistory.length > 0) {
      if (messages.length > 0) {
        storedHistory[storedHistory.length - 1].messages = messages;
        localStorage.setItem("History", JSON.stringify(storedHistory));
      }
    }
  }, [messages]);

  useEffect(() => {
    const storedHistory = JSON.parse(localStorage.getItem("History")) || [];
    if (storedHistory.length > 0) {
      setNewMessages(storedHistory[storedHistory.length - 1].messages);
    }
  }, []);

  const handleAttachmentClick = () => {
    document.getElementById("file-input").click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };

  const handleChange = (event) => {
    let selected = JSON.parse(event.target.value);
    setSelectedOption(selected);

    const storedHistory = JSON.parse(localStorage.getItem("History")) || [];
    const selectedChat = storedHistory?.find(
      (chat) => chat.id === selected.value
    );
    if (selectedChat) {
      setNewMessages(selectedChat.messages || []);
      setNewMessagesselected(false);
    } else {
      setNewMessages([]);
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <Header />
      <div className="flex-1">
        <div className="ic-interviews voyce py-0 h-full">
          <div className="container pt-32">
            <h1 className="text-center text-bold flex justify-center items-center">
              <img
                src="/images/logo.png"
                alt="Nexa Logo"
                style={{ width: "150px", marginBottom: "0px" }}
              />
              <span
                style={{
                  marginLeft: "15px",
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                Voyce
              </span>
            </h1>
            <p
              className="text-center mx-auto text-gray-800"
              style={{
                fontSize: "24px",
                lineHeight: "1.5",
                fontFamily: "'Libre Baskerville', serif",
                maxWidth: "720px",
                letterSpacing: "0.3px",
                color: "#3A3A3A",
                textShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
              }}
            >
              Nexa Voyce is your dedicated career copilot. Ask her anything you
              need help with to achieve your career goals!
            </p>

            <br />
            <div className="flex flex-col items-end gap-2 mb-4">
              <button
                className="w-full sm:w-48 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-2xl text-sm px-5 py-2.5"
                onClick={toggleModal}
              >
                Voyce Tutorial
              </button>
              <select
                className="w-full sm:w-48 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-2xl text-sm py-2.5 text-center appearance-none"
                value={selectedOption ? JSON.stringify(selectedOption) : ""}
                onChange={handleChange}
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  textAlignLast: "center",
                  textAlign: "center",
                  textAlign: "-webkit-center",
                  textAlign: "-moz-center",
                }}
              >
                <option
                  value=""
                  style={{
                    color: "gray",
                    padding: "10px",
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  History
                </option>
                {options.map((opt, index) => (
                  <option
                    value={JSON.stringify(opt)}
                    key={index}
                    style={{
                      color: "#000000",
                      padding: "10px",
                      backgroundColor: index % 2 === 0 ? "#f9fafb" : "#ffffff",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    {opt.label}
                  </option>
                ))}
              </select>
            </div>

            <br />
            <InputContainer>
              <div className="flex">
                <AttachmentButton htmlFor="file-input" />
                <input
                  accept="application/pdf, application/json, text/plain, text/html, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {imagePreview && (
                  <FaRegFile size={18} className="mt-1.5 mr-1" />
                )}
                <MicContainer
                  isListening
                  onClick={(e) => {
                    e.preventDefault();
                    if (isListening) {
                      handleSendMessage(note);
                      setTimeout(() => {
                        setInput("");
                      }, 200);
                    }
                    setIsListening(!isListening);
                  }}
                  className={`${
                    isListening
                      ? "h-[30px] w-[30px] rounded-full bg-red-500 flex justify-center items-center"
                      : ""
                  } `}
                >
                  {isListening ? (
                    <IoMicSharp size={20} color="#fff" />
                  ) : (
                    <IoMicOutline size={25} />
                  )}
                </MicContainer>
                <IoChatbubbleEllipsesOutline
                  size={20}
                  onClick={() => handleNewChatBtn()}
                  className="mt-1.5 mr-1 cursor-pointer hover:text-blue-600 transition-colors"
                  title="Clear chat history"
                />
                {mediaRecorder && isListening ? (
                  <div className="flex ml-3">
                    <LiveAudioVisualizer
                      mediaRecorder={mediaRecorder}
                      width={150}
                      height={35}
                      barColor="#f76565"
                    />
                  </div>
                ) : null}
              </div>
              <input
                type="text"
                placeholder="Type your message..."
                value={input}
                onChange={handleInputChange}
                onKeyUp={handleKeyPress}
                className="bg-transparent"
                style={{ paddingRight: 10 }}
              />
              <SendButton
                disabled={loading}
                onClick={() => handleSendMessage(input)}
              >
                <IoSendSharp size={20} color={loading ? "#ccc" : "#017bfe"} />
              </SendButton>
            </InputContainer>

            {messages.length === 0 && NewMessagesselected ? (
              <VoyaceStaticMsgs className="hide_scrollbar">
                {voyace_static_messages?.map((e, i) => (
                  <MessageItem
                    key={i}
                    className="ic-interview-items aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="500"
                    onClick={() => handleIconChange(e)}
                  >
                    <div className="ic-icons">
                      <img
                        src="/images/icon.png"
                        className="img-fluid"
                        alt="Beauty Services"
                      />
                    </div>
                    <p className="voyce_P-lineheight line-clamp-2">{e}</p>
                    <i className="size-30" style={{ fontSize: "22px" }}></i>
                  </MessageItem>
                ))}
              </VoyaceStaticMsgs>
            ) : (
              <div
                ref={chatContainerRef}
                className="w-full lg:w-[900px] mx-auto overflow-y-scroll"
                style={{ height: "55vh" }}
              >
                <ChatsRenderer
                  messages={messages.length > 0 ? messages : NewMessages}
                  handleIconChange={handleIconChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out">
          <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-3xl transform transition-transform duration-300 ease-in-out scale-100">
            <div className="flex justify-between items-center p-4 border-b">
              <button
                onClick={toggleModal}
                className="text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                ✖
              </button>
            </div>
            <div className="p-4">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/2qEfxLQftPc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VoyceSimulation;

export const getData = (db, storeName, key) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get(key);
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const getAllData = (db, storeName) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.getAll();
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const ChatsRenderer = ({ messages, handleIconChange }) => {
  const chatContainerRef = useRef(null);

  const onCopy = (message, index) => {
    navigator.clipboard.writeText(
      `${message?.content}\n\n${messages?.[index + 1]?.content}`
    );
    info("copied!");
  };

  return (
    <div className="w-full justify-center mt-11" ref={chatContainerRef}>
      {!messages || messages.length === 0 ? (
        <div className="ic-interview-card-container">
          <span className="text-2xl text-center">No chats found</span>
        </div>
      ) : (
        <>
          <div className="flex flex-col w-full mx-auto">
            {messages?.map((message, index) =>
              message.role === "user" ? (
                <div
                  className="flex w-full items-center justify-between"
                  key={index}
                >
                  <div className="font-bold text-base">{message.content}</div>
                  <FaRegCopy
                    size={18}
                    className="cursor-pointer"
                    onClick={() => onCopy(message, index)}
                  />
                </div>
              ) : (
                <div key={index} className={"mt-2 justify-start"}>
                  <div className={`my-2`}>
                    <div className="flex items-start gap-4">
                      <span className="w-full">
                        <MDX>{message?.content}</MDX>
                      </span>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};
