import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdDone, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createMarkup } from "../../../Utilities/CreateMarkup";
import RichTextEditor from "../../../features/RichTextEditor";
import {
  getResumeProjects,
  getSingleResume,
  udpateResumeProjects,
  updateResumeSectionPosition,
} from "../../../Redux/ResumeBuilderSlice";
import { error, success } from "../../../Utilities/toast";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function Projects({ projects, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [activeField, setActiveField] = useState({ index: null, field: null });
  const [hoveredField, setHoveredField] = useState({
    index: null,
    field: null,
  });
  const [projectsState, setProjectsState] = useState(projects || []);
  const [datePickerOpen, setDatePickerOpen] = useState(null);
  const [textEditorOpen, setTextEditorOpen] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [defaultContent, setDefaultContent] = useState("");
  const [hoveredSection, setHoveredSection] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const editableRefs = useRef({});

  const ICON_STYLE = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date(); // Default to today if dateString is null or undefined
    return date.toLocaleDateString(undefined, options);
  };

  const updateProjectsState = (index, field, value) => {
    setProjectsState((prev) =>
      prev.map((proj, idx) =>
        idx === index ? { ...proj, [field]: value } : proj
      )
    );
  };

  const handleFieldClick = (index, field) => {
    if (activeField.index !== null) submitChanges();

    setActiveField({ index, field });

    if (field === "start_date" || field === "end_date") {
      setDatePickerOpen(`${index}-${field}`);
    } else if (field === "additional_information") {
      const content = projectsState[index]?.additional_information || "";
      setEditorContent(content);
      setDefaultContent(content);
      setTextEditorOpen(`${index}-${field}`);
    }

    setTimeout(() => {
      editableRefs.current[`${index}-${field}`]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, field, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[`${index}-${field}`];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      updateProjectsState(index, field, value);
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    }
  };

  const handleDateChange = (index, field, selectedDate) => {
    const date = new Date(selectedDate); // Parse the selected date
    const formattedDate = date.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"

    setProjectsState((prev) =>
      prev.map((pub, idx) =>
        idx === index ? { ...pub, [field]: formattedDate } : pub
      )
    );
  };
  const getProjectsInfo = () => {
    dispatch(getResumeProjects({ resumeId: id, onSuccess: () => {} }));
  };
  const submitChanges = () => {
    const originalProject = projects[activeField.index];
    const updatedProject = projectsState[activeField.index];
    const hasChanges =
      originalProject[activeField.field]?.trim() !==
      updatedProject[activeField.field]?.trim();

    if (hasChanges) {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      setTextEditorOpen(null);
      const payload = {
        updateDocId: updatedProject?.id,
        data: updatedProject,
      };
      const nowData = new Date();
      const endedAtDate = new Date(payload?.data?.end_date);
      if (payload?.data?.start_date >= payload?.data?.end_date) {
        error("Start date cannot be greater than or equal to end date");
        return;
      }
      if (endedAtDate > nowData) {
        error("End date cannot be greater than current date");
        return;
      }
      dispatch(
        udpateResumeProjects({
          payload,
          onSuccess: (res) => {
            success(res);
            getProjectsInfo();
          },
        })
      );
    } else {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      setTextEditorOpen(null);
    }
  };

  const renderEditableField = (project, index, field) => {
    const isActive = activeField.index === index && activeField.field === field;
    const isHovered =
      hoveredField.index === index && hoveredField.field === field;
    const originalProject = projects[index];
    const updatedProject = projectsState[index];

    const hasChanges =
      field &&
      originalProject &&
      updatedProject &&
      originalProject[field]?.trim() !== updatedProject[field]?.trim();

    return (
      <span
        className={`editable-field ${isActive ? "active" : ""}`}
        onMouseEnter={() => setHoveredField({ index, field })}
        onMouseLeave={() => setHoveredField({ index: null, field: null })}
        onDoubleClick={() => handleFieldClick(index, field)}
        style={{
          position: "relative",
          border: isActive ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={
            field === "additional_information" ? false : isActive
          }
          ref={(el) => (editableRefs.current[`${index}-${field}`] = el)}
          onInput={(e) => handleFieldInput(index, field, e)}
          onBlur={
            activeField.field != "start_date" && activeField.field != "end_date"
              ? submitChanges
              : ""
          }
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          className={`editable-field`}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {(field === "start_date" && datePickerOpen === `${index}-${field}`) ||
          (field === "end_date" && datePickerOpen === `${index}-${field}`) ? (
            <input
              type="date"
              id="start"
              name="trip-start"
              value={
                project && !isNaN(new Date(project).getTime())
                  ? new Date(project).toLocaleDateString("en-CA") // Converts to 'YYYY-MM-DD' format
                  : ""
              }
              onChange={(e) => handleDateChange(index, field, e.target.value)}
            />
          ) : field === "additional_information" &&
            textEditorOpen === `${index}-${field}` ? (
            <div className=" customized-editor mt-2  ">
              <RichTextEditor
                defaultContent={defaultContent}
                setEditorContent={setEditorContent}
              />
            </div>
          ) : field === "additional_information" ? (
            <p dangerouslySetInnerHTML={createMarkup(project)} />
          ) : (
            project
          )}
        </span>
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...ICON_STYLE, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && !hasChanges && (
          <MdClose
            onClick={submitChanges}
            style={{ ...ICON_STYLE, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, field)}
            style={{ ...ICON_STYLE, right: "-20px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(projectsState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...projectsState];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setProjectsState(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          project: updatedSections,
        },
        onSuccess: () => {
          dispatch(getResumeProjects({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setProjectsState(previousSections);
        },
      })
    );
  };
  useEffect(() => {
    if (editorContent) {
      updateProjectsState(activeField.index, activeField.field, editorContent);
    }
  }, [editorContent]);

  useEffect(() => {
    if (projects?.length > 0) {
      const sortedProjects = projects
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setProjectsState(sortedProjects);
    }
  }, [projects]);

  return (
    <section className="cv-projects">
      {projectsState?.map((project, index) => {
        const isActive = activeSection === index;
        const isHovered = hoveredSection === index;
        return (
          <div
            className={`editable-field my-1 relative w-full ${
              isActive || isHovered ? "editable-field active bg-white" : ""
            }`}
            onMouseEnter={() => setHoveredSection(index)}
            onMouseLeave={() => setHoveredSection(null)}
            onClick={() => {
              if (index !== activeSection && projectsState.length !== 1) {
                setActiveSection(index);
              } else {
                setActiveSection(null);
              }
            }}
            style={{
              border:
                isActive || isHovered
                  ? "1px solid #ffff"
                  : "1px solid transparent",
              backgroundColor: isActive ? "#ffff" : "transparent",
              cursor: "text",
            }}
            key={index}
          >
            {isActive && (
              <div className="absolute -top-6 left-1/2 -translate-x-1/2 bg-blue-500 text-white text-xs font-semibold py-2 px-4 rounded-full shadow-md flex items-center gap-2">
                {/* Upper Arrow */}
                <FiChevronUp
                  className={`text-white text-lg cursor-pointer ${
                    activeSection == 0 ? "hidden" : ""
                  }`}
                  onClick={() => {
                    handleOnDragEnd({
                      destination: activeSection - 1,
                      source: activeSection,
                    });
                  }}
                />

                {/* Lower Arrow */}
                <FiChevronDown
                  className={`text-white text-lg cursor-pointer  ${
                    activeSection == projectsState.length - 1 ? "hidden" : ""
                  }`}
                  onClick={() => {
                    handleOnDragEnd({
                      destination: activeSection + 1,
                      source: activeSection,
                    });
                  }}
                />
              </div>
            )}
            <div className="d-flex justify-content-between mt-3 align-items-center">
              <div>
                <h5
                  className="cv-item-title text-md mb-0 apply-font"
                  style={{
                    color: getSingleResumeDoc?.subheading_color || "#000",
                  }}
                >
                  {renderEditableField(
                    project?.project_name,
                    index,
                    "project_name"
                  )}
                </h5>
                <p className="cv-item-date apply-font mt-1">
                  {renderEditableField(
                    project?.organization,
                    index,
                    "organization"
                  )}
                </p>
              </div>
              <p className="cv-item-date apply-font">
                {renderEditableField(
                  formatDate(project?.start_date),
                  index,
                  "start_date"
                )}{" "}
                -{" "}
                {renderEditableField(
                  formatDate(project?.end_date),
                  index,
                  "end_date"
                )}{" "}
              </p>
            </div>

            <p
              style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
              className={`cv-item-date apply-font px-2 ${
                getSingleResumeDoc?.font_style &&
                getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
              }`}
            >
              {renderEditableField(
                project?.additional_information,
                index,
                "additional_information"
              )}
            </p>
            {projectsState?.length > 0 &&
              index !== projectsState?.length - 1 && <hr className="mt-2" />}
          </div>
        );
      })}
    </section>
  );
}
