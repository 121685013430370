import React, { useState } from "react";
import PreviewResume from "./PreviewResume";
import useResumeData from "../../hook/useResumeData";
import { useSelector } from "react-redux";
import {
  addStep,
  checkEnhanceStep,
  checkSkip,
  selectStep,
  skipEnhanceStep,
} from "../../features/companyinformation/StepsSlice";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  getSingleResume,
  saveAiGeneratedResume,
} from "../../Redux/ResumeBuilderSlice";
import { useNavigate, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import PdfTemplate from "./PdfTemplate";

const SelectEnhanceResume = ({
  setSelectedResume,
  selectedResume,
  setShow,
}) => {
  const navigate = useNavigate();
  const skipped = useSelector(checkSkip);
  const skipEnhance = useSelector(checkEnhanceStep);
  const dispatch = useDispatch();
  const { id } = useParams();
  const step = useSelector(selectStep);
  const { existingResume, AiResume } = useResumeData();
  const { getSingleResumeDoc } = useSelector((state) => state.ResumeBuilder);
  // const [downloadedResume, setDownloadedResume] = useState(false);

  const handleDownload = async () => {
    // setDownloadedResume(true)
    const doc = (
      <PdfTemplate
        resumeData={selectedResume === "BEFORE" ? existingResume : AiResume}
        getSingleResumeDoc={getSingleResumeDoc}
      />
    );
    const pdfBlob = await pdf(doc).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = `${getSingleResumeDoc?.resume_name}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    navigate("/resumes");
  };

  const handlePreviousStep = () => {
    if (skipEnhance) {
      dispatch(addStep(2));
      dispatch(skipEnhanceStep(false));
    }
    if (skipped) {
      setShow(false);
      setSelectedResume(null);
      return;
    }
    if (step === 3) {
      setShow(false);
      setSelectedResume(null);
    } else {
      dispatch(addStep(3));
    }
  };

  const handleNextStep = () => {
    if (selectedResume && step === 4) {
      return handleDownload();
    }
    if (selectedResume === "BEFORE") {
      return dispatch(addStep(4));
    }
    dispatch(
      saveAiGeneratedResume({
        resumeId: id,
        onSuccess: () => {
          dispatch(addStep(4));
          const resumeId = id;
          dispatch(getSingleResume({ resumeId, onSuccess: () => {} }));
        },
      })
    );
  };
  return (
    <>
      <div className="mydetails w-100 md:w-50">
        <div className="d-flex justify-content-center py-2">
          <div className="py-3">
            {selectedResume && step === 4 ? (
              <div className="w-full grid grid-cols-1 justify-center items-center gap-8 md:gap-4 lg:gap-8 px-2 lg:px-6 mb-4 h-auto">
                <div className="w-full">
                  <div className="cv-main-container-previewTemp cv-main-container-previewTempSingle">
                    <PreviewResume
                      resumeData={
                        selectedResume === "BEFORE" ? existingResume : AiResume
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-4 lg:gap-8 px-2 lg:px-6 mb-4 h-auto">
                <div className="w-full">
                  <h2 className="text-center text-2xl font-bold mb-3">
                    BEFORE
                  </h2>
                  <div
                    className={`cv-main-container-previewTemp cv-main-container_select-cover ${
                      selectedResume === "BEFORE" ? "active_page" : ""
                    }`}
                    onClick={() => {
                      setSelectedResume("BEFORE");
                    }}
                  >
                    <PreviewResume resumeData={existingResume} />
                  </div>
                </div>
                <div className="w-full">
                  <h2 className="text-center text-2xl font-bold mb-3">AFTER</h2>
                  <div
                    className={`cv-main-container-previewTemp cv-main-container_select-cover ${
                      selectedResume === "AFTER" ? "active_page" : ""
                    }`}
                    onClick={() => {
                      setSelectedResume("AFTER");
                    }}
                  >
                    <PreviewResume resumeData={AiResume} />
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-end gap-3 px-10">
              {step >= 3 && (
                <>
                  {step === 4 && selectedResume && (
                    <button
                      type="button"
                      className="cancel-btn"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        width: "auto",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "6px",
                        padding: "0px 10px",
                      }}
                      onClick={() => {
                        dispatch(addStep(1));
                        dispatch(skipEnhanceStep(false));
                        setShow(false);
                        setSelectedResume(null);
                      }}
                    >
                      <FaLongArrowAltLeft size={18} />
                      Back to editor
                    </button>
                  )}
                  <button
                    type="button"
                    className="cancel-btn"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "100px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "6px",
                    }}
                    onClick={() => {
                      handlePreviousStep();
                    }}
                  >
                    <FaLongArrowAltLeft size={18} />
                    Previous
                  </button>
                  {selectedResume && (
                    <>
                      <button
                        className="resume-header-button-active px-3 py-2 rounded"
                        onClick={() => handleNextStep()}
                      >
                        {step === 4 && selectedResume
                          ? "Download Resume"
                          : selectedResume === "BEFORE"
                          ? "Save Existing Resume"
                          : "Save AI Resume"}
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
            {/* {step === 4 && (
              <PdfTemplate
                resumeData={
                  selectedResume === "BEFORE" ? existingResume : AiResume
                }
                getSingleResumeDoc={getSingleResumeDoc}
              />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectEnhanceResume;
