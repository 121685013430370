import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const PdfAdditionalSection = ({ sections, getSingleResumeDoc }) => {
  const styles = StyleSheet.create({
    itemTitle: {
      fontSize: 13,
      marginBottom: 5,
    },
    itemDate: {
      color: "#777",
      marginBottom: 5,
      fontSize: 12,
    },
    subTitle: {
      color: "#777",
      marginBottom: 5,
      fontSize: 10,
    },
    itemDescription: {
      color: "#555",
      fontSize: 12,
      lineHeight: 1.5,
    },
    oneLine: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date(); // Default to today if dateString is null or undefined
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      {sections?.data?.map((experience, index) => {
        const hasData =
          experience?.title ||
          experience?.subtitle ||
          experience?.description ||
          experience?.toDate ||
          experience?.fromDate;

        if (!hasData) return null;

        return (
          <View key={index}>
            {experience?.title && (
              <View style={styles.oneLine}>
                <Text
                  style={{
                    color: getSingleResumeDoc?.subheading_color || "black",
                    ...styles.itemTitle,
                  }}
                >
                  {experience.title}
                </Text>
                {(experience?.toDate || experience?.fromDate) && (
                  <Text style={styles.itemDate}>
                    {experience?.toDate ? formatDate(experience?.toDate) : ""} -{" "}
                    {experience?.fromDate
                      ? formatDate(experience?.fromDate)
                      : "present"}
                  </Text>
                )}
              </View>
            )}
            {experience?.subtitle && (
              <Text style={styles.subTitle}>{experience.subtitle}</Text>
            )}
            {experience?.description && (
              <Text style={styles.itemDescription}>
                {experience.description
                  ?.replace(/<\/?p>/g, "")
                  ?.replace(/<br\s*[\/]?>/gi, "")}
              </Text>
            )}
          </View>
        );
      })}
    </>
  );
};

export default PdfAdditionalSection;
