import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import PresonalPreference from "./PresonalPreference";
import ProfilePreference from "./ProfilePreference";
import { getProfessions } from "../../Redux/UserProfileSlice";
import { useDispatch } from "react-redux";

const PreferenceModal = ({
  isOpen,
  onClose,
  formData,
  setFormData,
  step,
  setStep,
  setCanScrapJobs,
  setIsLoading,
  // resetFilters,
}) => {
  const dispatch = useDispatch();
  const { jobsPreference } = useSelector((state) => state.UserProfile);
  const profilePreferences = useSelector(
    (state) => state.authenticate.user.job_preferences
  );
  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "99",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0px",
          maxWidth: "500px",
          width: "90%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <div
        className={`flex items-center border-b border-gray-300 py-3 px-4 w-full ${
          jobsPreference?.title && Object.keys(jobsPreference).length !== 0
            ? "justify-between"
            : "justify-start"
        }`}
      >
        <h2 className="text-lg font-semibold text-[#2B6BE2]">
          {step == 1
            ? "Welcome To Nexa IQ"
            : step == 2
            ? "Extract from user profile"
            : step == 3
            ? "Personal Search Preference"
            : ""}
        </h2>
        {jobsPreference?.title && Object.keys(jobsPreference).length !== 0 && (
          <IoMdClose onClick={onClose} className="cursor-pointer" />
        )}
      </div>

      {step == 1 && (
        <div className="py-3 px-4 overflow-auto">
          {jobsPreference?.title && Object.keys(jobsPreference).length !== 0 ? (
            <p>Choose how you want to update job preference.</p>
          ) : (
            <>
              <p className="pb-2">
                Create your first personal job search tailored to your skills
                and preferences.
              </p>
              <p>
                Do you want create a personal search criteria or extract from
                user profile.
              </p>
            </>
          )}
          <div className="flex flex-col sm:flex-row gap-3 w-full pt-4">
            <button
              type="button"
              className="modal-button save-button w-full"
              onClick={() => {
                setStep(3);
                // dispatch(
                //   getProfessions({
                //     page: 1,
                //     search: " ",
                //     onSuccess: () => {
                //       setStep(3);
                //     },
                //   })
                // );
              }}
            >
              {jobsPreference?.title && Object.keys(jobsPreference).length !== 0
                ? "Update"
                : "Create"}{" "}
              a personal search
            </button>

            <button
              type="button"
              className="modal-button save-button w-full"
              onClick={() => setStep(2)}
              disabled={!profilePreferences || profilePreferences.length == 0}
            >
              Extract from user profile
            </button>
          </div>
        </div>
      )}

      {step == 2 && (
        <ProfilePreference
          onClose={onClose}
          setStep={setStep}
          jobsPreference={jobsPreference}
          profilePreferences={profilePreferences}
          setCanScrapJobs={setCanScrapJobs}
          // resetFilters={resetFilters}
        />
      )}

      {step == 3 && (
        <PresonalPreference
          onClose={onClose}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          setCanScrapJobs={setCanScrapJobs}
          setIsLoading={setIsLoading}
          // resetFilters={resetFilters}
        />
      )}
    </ReactModal>
  );
};

export default PreferenceModal;
