import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { success, error } from "../../../Utilities/toast";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../../Redux/authenticate/authenticate.action";
import AdditionSelect from "../../../Components/dropdowns/AdditionSelect";
import {
  addJobPreference,
  deleteJobPreference,
  getProfessions,
} from "../../../Redux/UserProfileSlice";

const JobPreference = ({ activeStep, setActiveStep, rolesLoader }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const { user } = useSelector((state) => state.authenticate);
  const { jobProfessions } = useSelector((state) => state.UserProfile);
  const [initialUserJob, setInitialUserJob] = useState([]);
  const [specialization, setSpecialization] = useState("");
  const [preferences, setPreferences] = useState([
    {
      job_role: "",
      industry_preference: "",
      skill_level: "",
      specialization_tags: [],
      job_type: "",
      id: null,
    },
  ]);

  const jobRolesData = jobProfessions?.professions?.map((item) => ({
    label: item?.display_name,
    value:
      item?.name +
      "#" +
      (item?.preferred_industry ? item?.preferred_industry : ""),
  }));

  const skillLevels = ["Entry", "Mid", "Senior", "Director", "VP", "C-Level"];
  const jobType = ["Full Time", "Part Time", "Internship", "Contract"];

  const handleChange = (index, event) => {
    const values = [...preferences];
    values[index][event.target.name] = event.target.value;
    setPreferences(values);
  };

  // const handleChangeRoles = (index, name, value) => {
  //   const values = [...preferences];
  //   if (name === "job_role") {
  //     const title = value?.split("#")[0]?.trim();
  //     const industry = value?.split("#")[1]?.trim();
  //     values[index].job_role = title;
  //     values[index].industry_preference = industry;
  //     setPreferences(values);
  //     return;
  //   }
  //   values[index][name] = value;
  //   setPreferences(values);
  // };

  const handleChangeRoles = (index, name, value) => {
    const values = [...preferences];

    if (name === "job_role") {
      const title = value?.split("#")[0]?.trim();
      const isDuplicate = preferences.some(
        (preference, prefIndex) =>
          preference.job_role === title && prefIndex !== index
      );

      if (isDuplicate) {
        error("This job role has already been selected in another preference.");
        return;
      }

      const industry = value?.split("#")[1]?.trim();
      values[index].job_role = title;
      values[index].industry_preference = industry;
      setPreferences(values);
      return;
    }

    values[index][name] = value;
    setPreferences(values);
  };

  const handleAddPreference = () => {
    setPreferences([
      ...preferences,
      {
        job_role: "",
        industry_preference: "",
        skill_level: "",
        specialization_tags: [],
        job_type: "",
        id: null,
      },
    ]);
  };

  const handleRemovePreference = (index, obj) => {
    const selectedData = preferences.find((item) => item == obj);
    if (selectedData?.id) {
      dispatch(
        deleteJobPreference({
          id: selectedData?.id,
          onSuccess: () => {
            success("Job Preferences deleted successfully");
            dispatch(getCurrentUser());
          },
        })
      );
    } else {
      const newpreferences = [...preferences];
      newpreferences.splice(index, 1);
      setPreferences(newpreferences);
    }
  };

  const handleAddSpecialization = (index, newSpecialization) => {
    if (newSpecialization.trim() === "") return;

    const values = [...preferences];
    values[index].specialization_tags = [
      ...(values[index].specialization_tags || []),
    ];

    if (!values[index].specialization_tags.includes(newSpecialization)) {
      values[index].specialization_tags.push(newSpecialization.trim());
      setPreferences(values);
    }
  };

  const handleRemoveSpecialization = (index, specializationToRemove) => {
    const values = [...preferences];
    values[index].specialization_tags = values[
      index
    ].specialization_tags.filter((spec) => spec !== specializationToRemove);
    setPreferences(values);
  };

  const handleSave = () => {
    const hasChanged =
      JSON.stringify(preferences) !== JSON.stringify(initialUserJob);

    if (hasChanged) {
      if (
        preferences.some(
          (preference) =>
            !preference.job_role ||
            !preference.skill_level ||
            !preference.specialization_tags.length > 0 ||
            !preference.job_type
        )
      ) {
        return error("All fields are required");
      }

      const formattedData = {
        job_preferences: preferences.map((preference) => {
          if (!preference.industry_preference) {
            return { ...preference, industry_preference: "" };
          }
          return preference;
        }),
      };
      dispatch(
        addJobPreference({
          payload: formattedData,
          onSuccess: () => {
            success("Job Preferences added successfully");
            dispatch(getCurrentUser());
            setActiveStep(activeStep + 1);
          },
        })
      );
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const fetchJobTitles = async (value, page) => {
    await dispatch(
      getProfessions({ page, search: value, onSuccess: () => {}, onError: () => {}, })
    );
  };

  useEffect(() => {
    if (user && user.job_preferences && user?.job_preferences?.length > 0) {
      setPreferences(user.job_preferences?.map((cert) => ({ ...cert })));
      setInitialUserJob(user.job_preferences?.map((cert) => ({ ...cert })));
    } else {
      setPreferences([
        {
          job_role: "",
          industry_preference: "",
          skill_level: "",
          specialization_tags: [],
          job_type: "",
          id: null,
        },
      ]);
      setInitialUserJob([
        {
          job_role: "",
          industry_preference: "",
          skill_level: "",
          specialization_tags: [],
          job_type: "",
          id: null,
        },
      ]);
    }
  }, [user]);

  return (
    <div className="py-4">
      {preferences?.map((pref, index) => (
        <div key={index} className="mb-4 p-4 border rounded-lg shadow">
          <div className="mb-2">
            <label className="block mb-1">Job Role:</label>
            <AdditionSelect
              className="w-full h-8 capitalize"
              value={pref.job_role}
              disabled={index == 0 ? true : false}
              data={jobRolesData ? jobRolesData : []}
              placeholder="Select Job Role"
              placeholderAdd="Add Your Own Job Title"
              fetchOptions={fetchJobTitles}
              onChange={(value) => handleChangeRoles(index, "job_role", value)}
              currentPage={jobProfessions?.page}
              totalPages={jobProfessions?.total_pages}
            />
          </div>

          <div className="mb-2">
            <label className="block mb-1">Skill Level:</label>
            <select
              name="skill_level"
              value={pref.skill_level}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              required
            >
              <option value="">Select Skill Level</option>
              {skillLevels?.map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2">
            <label className="block mb-1">Job Type:</label>
            <select
              name="job_type"
              value={pref.job_type}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              required
            >
              <option value="">Select Job Type</option>
              {jobType?.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-2">
            <label className="block mb-1">Specialization:</label>
            <div className="flex gap-2">
              <input
                ref={inputRef}
                type="text"
                placeholder="Enter specialization"
                className="border rounded p-2 w-full"
                onChange={(e) => setSpecialization(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAddSpecialization(index, specialization);
                    setSpecialization("");
                    inputRef.current.value = "";
                  }
                }}
              />
              <button
                type="button"
                onClick={() => {
                  handleAddSpecialization(index, specialization);
                  setSpecialization("");
                  inputRef.current.value = "";
                }}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Add
              </button>
            </div>
            <div className="mt-2 flex gap-2 flex-wrap">
              {pref?.specialization_tags?.map((spec) => (
                <span
                  key={spec}
                  className="bg-gray-200 text-gray-700 rounded-full px-3 py-1 flex items-center"
                >
                  {spec}
                  <button
                    type="button"
                    onClick={() => handleRemoveSpecialization(index, spec)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
          </div>
          {preferences?.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemovePreference(index, pref)}
              className="text-white bg-red-600 mt-3 px-4 py-2 rounded-lg"
            >
              Remove Preference
            </button>
          )}
        </div>
      ))}
      <div className="w-full gap-2 flex justify-end">
        <button
          type="button"
          onClick={handleAddPreference}
          className="mt-2 bg-blue-500 text-white p-2 rounded"
        >
          Add Another Preference
        </button>
      </div>
      <div className={`w-full mt-4 flex justify-between`}>
        <button
          className="ic-btn"
          onClick={() => {
            setActiveStep((prev) => prev - 1);
          }}
        >
          Previous
        </button>
        <button className="ic-btn" onClick={handleSave}>
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default JobPreference;
