import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import backIcon from "../../assets/icons/backIcon.svg";
import DialerAudio from "../../assets/sound/dialer.mp3";
import Header from "../../Components/header/index";
import Modal from "../../Components/modal";
import { error } from "../../Utilities/toast";
import HospitalityMeetScreen from "./sales/hospitality/hospitalitymeet";

function Hospitality() {
  const [department, setDepartment] = useState("");
  const [challenge, setChallenge] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [situation, setSituation] = useState("");
  const [progress, setProgress] = useState(0);
  const [animateCard, setAnimateCard] = useState(false);
  const [options, setOptions] = useState({});
  const [play, { stop }] = useSound(DialerAudio);
  const [showMeet, setShowMeet] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [muted, setMuted] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [respt, setRespt] = useState([]);
  const navigate = useNavigate();

  const handleMute = () => {
    if (audioStream) {
      audioStream.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setMuted(!muted);
    }
  };

  const handleCamera = () => {
    if (videoStream) {
      videoStream.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
    }
  };

  const handleGenerate = () => {
    const generatedOptions = {
      department,
      challenge,
      difficulty,
      situation,
    };
    setOptions(generatedOptions);
    if (!showMeet) {
      // play();
      setTimeout(() => {
        stop();
        setShowMeet(true);
      }, 2500);
    }
    setShowMeet(true);
    console.log("generatedOptions----------", generatedOptions);
  };

  useEffect(() => {
    let filledFields = 0;
    if (department) filledFields++;
    if (challenge) filledFields++;
    if (difficulty) filledFields++;
    if (situation) filledFields++;
    setProgress((filledFields / 4) * 100); // Dividing by 4 because you have 4 fields
  }, [department, challenge, difficulty, situation]);

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(100);
      }, 300); // Slight delay to simulate smooth loading
    }
  }, [progress]);

  const handleEndCall = () => {
    // Stop audio stream
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);
    }

    // Stop video stream
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      setVideoStream(null);
    }

    setIsModal(true);
    fetchData();

    // Reset options and states
    setOptions({});
    setShowMeet(false);
    setDepartment("");
    setChallenge("");
    setDifficulty("");
    setSituation("");
    setProgress(0);
    setMuted(false);
  };

  useEffect(() => {
    if (showMeet) {
      const initiateStreams = async () => {
        try {
          // Create audio constraints
          const constraintsAudio = { audio: true, video: false };

          // Create video constraints
          const constraintsVideo = { video: true, audio: false };

          // Get audio stream
          const audio = await navigator.mediaDevices.getUserMedia(
            constraintsAudio
          );
          setAudioStream(audio);

          // Get video stream
          const video = await navigator.mediaDevices.getUserMedia(
            constraintsVideo
          );
          setVideoStream(video);
        } catch (err) {
          console.error("Error accessing media devices.", err);
          error("Unable to access camera or microphone.");
          setShowMeet(false);
          // Cleanup any partially acquired streams
          if (audioStream) {
            audioStream.getTracks().forEach((track) => track.stop());
            setAudioStream(null);
          }
          if (videoStream) {
            videoStream.getTracks().forEach((track) => track.stop());
            setVideoStream(null);
          }
        }
      };

      initiateStreams();
    }

    return () => {
      // Cleanup on component unmount or when showMeet changes
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMeet]);

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const header = (
    <div className="mockInterview-modal-header">
      <button className="modal-close" onClick={() => handleCloseModal()}>
        <i className="ri-close-line"></i>
      </button>
    </div>
  );

  const [summary, setSummary] = useState("");
  const fetchData = async () => {
    console.log(respt);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-4-turbo",
            messages: respt,
            max_tokens: 150,
            temperature: 0.7,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setSummary(data.choices[0].message.content);
    } catch (error) {
      console.error("Error fetching summary:", error);
    }
  };

  const generateRandomScore = () => Math.floor(Math.random() * 10) + 1;

  const content = (
    <div className="col-12 order-md-1 order-2">
      <div className="cardBody">
        <h3 className="mx-3 my-3 font-bold">Generalized Report:</h3>
        <hr />
        <p className="mx-3 my-3">
          You are an expert evaluator for sales conversations. Your task is to
          grade the performance of a sales representative based on the following
          criteria. Assign a score from 1 to 10 for each criterion, where 1
          indicates "poor performance" and 10 indicates "exceptional
          performance." Provide a brief explanation for each score.
        </p>
        <h4 className="mx-3 my-3">Evaluation Criteria and Scores:</h4>
        <p className="mx-3 my-3">Clarity and Articulation:</p>
        <p className="mx-3 my-3">Score: [{generateRandomScore()}/10]</p>
        <p className="mx-3 my-3">
          Justification: [Brief explanation of the performance, e.g., how clear
          and understandable their communication was.]
        </p>
        <p className="mx-3 my-3">Professionalism:</p>
        <p className="mx-3 my-3">Score: [{generateRandomScore()}/10]</p>
        <p className="mx-3 my-3">
          Justification: [Evaluate if they maintained professionalism in tone,
          conduct, and language.]
        </p>
        <p className="mx-3 my-3">Empathy and Rapport:</p>
        <p className="mx-3 my-3">Score: [{generateRandomScore()}/10]</p>
        <p className="mx-3 my-3">
          Justification: [Explain how well the representative built a positive
          connection and showed understanding of the customer.]
        </p>
        <p className="mx-3 my-3">Needs Identification:</p>
        <p className="mx-3 my-3">Score: [{generateRandomScore()}/10]</p>
        <p className="mx-3 my-3">
          Justification: [Rate how effectively they asked questions to
          understand the customer’s pain points.]
        </p>
        <p className="mx-3 my-3">Value Proposition:</p>
        <p className="mx-3 my-3">Score: [{generateRandomScore()}/10]</p>
        <p className="mx-3 my-3">
          Justification: [Assess their ability to communicate the
          product/service's value in relation to the customer's needs.]
        </p>
        <p className="mx-3 my-3">Overcoming Objections:</p>
        <p className="mx-3 my-3">Score: [{generateRandomScore()}/10]</p>
        <p className="mx-3 my-3">
          Justification: [Describe their skill in addressing and resolving
          objections effectively.]
        </p>
        <p className="mx-3 my-3">Closing Skills:</p>
        <p className="mx-3 my-3">Score: [{generateRandomScore()}/10]</p>
        <p className="mx-3 my-3">
          Justification: [Explain how well they guided the conversation to
          achieve the intended outcome.]
        </p>
      </div>
      <div className="cardBody">
        <h3 className="mx-3 my-3 font-bold">Conversation:</h3>
        <hr />
        <div className="row mx-3 my-3">
          {respt.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              {message.role != "system" && (
                <>
                  <strong>{message.role}:</strong> {message.content}{" "}
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="cardBody">
        <h3 className="mx-3 my-3 font-bold">Final Summary:</h3>
        <hr />
        <p className="mx-3 my-3">
          {summary}
          {/* Conclude with a concise summary of the overall performance,
            combining insights from all categories. Mention specific aspects of
            excellence and the potential improvements for becoming more
            effective in sales conversations. */}
        </p>
      </div>
    </div>
  );

  const sendInterviewConfunc = (data) => {
    console.log(data);
    setRespt(data);
  };

  return (
    <>
      <Header />
      {showMeet ? (
        <HospitalityMeetScreen
          salesOptions={options}
          handleEndCall={handleEndCall}
          videoStream={videoStream}
          handleMute={handleMute}
          handleCamera={handleCamera}
          muted={muted}
          setMuted={setMuted}
          sessionId={sessionId}
          jobDescription=""
          contactDetails=""
          sendInterviewCon={sendInterviewConfunc}
        />
      ) : (
        <div style={styles.container}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              padding: "0 20px",
            }}
          >
            <button
              onClick={() => {
                navigate({ pathname: `/octagon` }, { replace: true });
              }}
              className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 mt-2"
            >
              <img src={backIcon} alt="Back" />
            </button>
          </div>
          <div style={styles.progressBarContainer}>
            <div style={{ ...styles.progressBar, width: `${progress}%` }}></div>
          </div>
          <div
            style={{
              ...styles.card,
              opacity: animateCard ? 1 : 0,
              transform: animateCard ? "translateY(0)" : "translateY(20px)",
            }}
          >
            <h2 style={styles.title}>Create your Hospitality Scenario</h2>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Choose your Field</label>
              <select
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="Front Desk/Guest Services">
                  Front Desk/Guest Services
                </option>
                <option value="Housekeeping">Housekeeping</option>
                <option value="Food & Beverage">Food & Beverage</option>
                <option value="Event Management">Event Management</option>
                <option value="Sales and Marketing">Sales and Marketing</option>
                <option value="Technology/IT">Technology/IT</option>
                <option value="Human Resources">Human Resources</option>
                <option value="Operations/Facility Management">
                  Operations/Facility Management
                </option>
              </select>
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Hospitality Scenario</label>
              <select
                value={challenge}
                onChange={(e) => setChallenge(e.target.value)}
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="Handling Guest Complaints">
                  Handling Guest Complaints
                </option>
                <option value="Managing Overbooked Reservations">
                  Managing Overbooked Reservations
                </option>
                <option value="Upselling and Cross-Selling Services">
                  Upselling and Cross-Selling Services
                </option>
                <option value="Staffing Shortages and Shift Coverage">
                  Staffing Shortages and Shift Coverage
                </option>
                <option value="Ensuring Health and Safety Compliance">
                  Ensuring Health and Safety Compliance
                </option>
                <option value="Event Planning and Coordination">
                  Event Planning and Coordination
                </option>
                <option value="Conflict Resolution Among Team Members">
                  Conflict Resolution Among Team Members
                </option>
                <option value="Handling Technical Downtime or IT Issues">
                  Handling Technical Downtime or IT Issues
                </option>
                <option value="Training New Staff Members">
                  Training New Staff Members
                </option>
              </select>
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}> Challenge Level</label>
              <select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="Easy">Easy</option>
                <option value="Medium">Medium</option>
                <option value="Hard">Hard</option>
              </select>
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>
                Any Specific Situational Parameters?
              </label>
              <input
                type="text"
                value={situation}
                onChange={(e) => setSituation(e.target.value)}
                placeholder="Personalize your Scenario"
                style={styles.input}
              />
            </div>

            <button style={styles.button} onClick={handleGenerate}>
              Start My Hospitality Simulation
            </button>
          </div>
        </div>
      )}

      <Modal show={isModal} header={header} content={content} footer="" />
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f0f2f5",
    paddingTop: "100px",
    boxSizing: "border-box",
  },
  progressBarContainer: {
    height: "8px",
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
    zIndex: 100,
    maxWidth: "600px",
    width: "90%",
    margin: "40px auto 0",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#0077cc",
    borderRadius: "4px",
    transition: "width 0.3s ease",
  },
  card: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
    padding: "20px",
    maxWidth: "600px",
    width: "90%",
    margin: "0px auto",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    boxSizing: "border-box",
    height: "560px",
    maxHeight: "85vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Ensure the content is spaced and the button stays at the bottom
  },
  title: {
    textAlign: "center",
    color: "#0077cc",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  inputGroup: {
    marginBottom: "25px", // Increase this for more space between fields
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  select: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    backgroundColor: "#0077cc",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "auto", // Ensure it sticks to the bottom
  },
};

export default Hospitality;
