import React, { useEffect, useMemo, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import PreviewAdditionalSection from "./PreviewAdditionalSection";
import PdfAdditionalSection from "./PdfAdditionalSection";
import BebasNeue from "../../assets/Font/BebasNeue-Regular.ttf";
import DmSans from "../../assets/Font/DMSans-Regular.ttf";
import Poppins from "../../assets/Font/Poppins-Regular.ttf";
import Karla from "../../assets/Font/Karla-Regular.ttf";
import Roboto from "../../assets/Font/Roboto-Regular.ttf";
import Ubuntu from "../../assets/Font/Ubuntu-Regular.ttf";
import { createMarkup } from "../../Utilities/CreateMarkup";
import DOMPurify from "dompurify";
const PdfTemplate = ({ resumeData, getSingleResumeDoc }) => {
  console.log(resumeData, getSingleResumeDoc, "resumeData, getSingleResumeDoc");
  const [sectionsConfig, setSectionConfigs] = useState([]);
  const fonts = [
    {
      name: "DM Sans",
      src: DmSans,
    },
    {
      name: "Bebas Neue",
      src: BebasNeue,
    },
    {
      name: "Poppins",
      src: Poppins,
    },
    {
      name: "Karla",
      src: Karla,
    },
    {
      name: "Roboto",
      src: Roboto,
    },
    {
      name: "Ubuntu",
      src: Ubuntu,
    },
  ];

  const styles = StyleSheet.create({
    mainContainer: {
      width: "760px",
      backgroundColor: "#F9F9F9",
      margin: "0 auto",
      padding: "20px",
      overflowY: "scroll",
      height: "510px",
      fontFamily: `${
        getSingleResumeDoc?.font_style
          ? getSingleResumeDoc?.font_style
          : "DM Sans"
      }`,
      backgroundColor: "#fff",
      borderLeft: "1px solid #52515136",
      marginBottom: "20px",
    },
    header: {
      color: "#555",
      textAlign: "center",
      marginBottom: "20px",
      lineHeight: 1.5,
      fontSize: 12,
    },
    name: {
      marginBottom: 5,
      fontWeight: 600,
    },
    email: {
      fontSize: 12,
      textAlign: "center",
    },
    sectionTitle: {
      marginTop: "10px",
      marginBottom: "7px",
      paddingBottom: "5px",
    },
    itemTitle: {
      fontSize: 13,
      marginBottom: 5,
    },
    itemDate: {
      color: "#777",
      marginBottom: 5,
      fontSize: 12,
    },
    itemDescription: {
      color: "#555",
      fontSize: 12,
      lineHeight: 1.5,
    },
    skillsList: {
      listStyleType: "none",
      padding: 0,
      fontSize: 14,
    },
    skill: {
      color: "#333",
      padding: "5px 10px",
      margin: "5px",
      borderRadius: 5,
      fontSize: 12,
    },
    List: {
      color: "#777",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 5,
    },
    listItem: {
      color: "#777",
      fontSize: 12,
      padding: "3px 8px",
      marginRight: 5,
      marginBottom: 5,
      borderRadius: 3,
    },
    oneLine: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date(); // Default to today if dateString is null or undefined
    return date.toLocaleDateString(undefined, options);
  };
  // Custom function to map HTML tags to @react-pdf/renderer components
  const parseHtml = (htmlString) => {
    // List of allowed tags
    const allowedTags = ["strong", "u", "li", "ul", "ol"];
    const allowedAttributes = []; // Define attributes to allow, if any

    // Regex to parse tags and content
    const tagRegex = /<(\/?)(\w+)([^>]*)>([^<]*)/g;

    const sanitizeAttributes = (attributesString) => {
      // Optionally, parse and sanitize attributes here
      return attributesString.trim(); // Return sanitized attributes if needed
    };

    const elements = [];
    let match;
    let currentListType = null; // Track if inside <ul> or <ol>
    let listIndex = 1; // Track list numbering for <ol>

    while ((match = tagRegex.exec(htmlString)) !== null) {
      const [, closingSlash, tagName, attributes, textContent] = match;

      if (!allowedTags.includes(tagName)) {
        // Skip tags not in the allowed list
        continue;
      }

      if (closingSlash) {
        // Handle closing tags
        if (tagName === "ul" || tagName === "ol") {
          currentListType = null;
          listIndex = 1; // Reset numbering for <ol>
        }
        continue;
      }

      const sanitizedAttributes = sanitizeAttributes(attributes);

      // Handle opening tags and content
      switch (tagName) {
        case "strong":
          elements.push(
            <Text key={elements.length} style={{ fontWeight: 700 }}>
              {textContent.trim()}
            </Text>
          );
          break;
        case "u":
          elements.push(
            <Text key={elements.length} style={{ textDecoration: "underline" }}>
              {textContent.trim()}
            </Text>
          );
          break;
        case "ul":
          currentListType = "ul";
          break;
        case "ol":
          currentListType = "ol";
          break;
        case "li":
          const prefix = currentListType === "ol" ? `${listIndex++}.` : "•";
          elements.push(
            <View>
              <Text
                key={elements.length}
                style={{ marginLeft: 20, display: "block" }}
              >
                {`${prefix} ${textContent.trim()}`}
              </Text>
            </View>
          );
          break;
        default:
          break;
      }
    }

    return elements;
  };

  const mapTailwindToStyles = (className) => {
    const style = {};

    if (className?.includes("underline")) {
      style.textDecoration = "underline";
    }
    if (className?.includes("font-bold")) {
      style.fontWeight = "bold";
    }
    if (className?.includes("text-blue-500")) {
      style.color = "#3b82f6"; // Tailwind blue-500
    }
    if (className?.includes("text-gray-500")) {
      style.color = "#6b7280"; // Tailwind gray-500
    }

    // Add more mappings as needed.
    return style;
  };
  const sectionComponents = useMemo(
    () => [
      {
        key: "details",
        component: (
          <View style={styles.header}>
            <Text
              style={{
                color: getSingleResumeDoc?.heading_color || "#000",
                fontSize: getSingleResumeDoc?.font_size || "16px",
                fontFamily: getSingleResumeDoc?.font_style,
                ...styles.name,
              }}
            >
              <Text
                style={{
                  ...mapTailwindToStyles(resumeData?.profile?.first_name_style),
                  color: getSingleResumeDoc?.heading_color || "#000",
                  fontSize: getSingleResumeDoc?.font_size || "16px",
                  fontFamily: getSingleResumeDoc?.font_style,
                  ...styles.name,
                }}
              >
                {resumeData?.profile?.first_name}{" "}
              </Text>
              <Text
                style={{
                  ...mapTailwindToStyles(resumeData?.profile?.last_name_style),
                  color: getSingleResumeDoc?.heading_color || "#000",
                  fontSize: getSingleResumeDoc?.font_size || "16px",
                  fontFamily: getSingleResumeDoc?.font_style,
                  ...styles.name,
                }}
              >
                {resumeData?.profile?.last_name}
              </Text>
            </Text>
            <Text
              style={{
                fontFamily: getSingleResumeDoc?.font_style,
                ...styles.email,
              }}
            >
              {resumeData?.profile?.introductory_title && (
                <Text
                  style={mapTailwindToStyles(
                    resumeData?.profile?.introductory_title_style
                  )}
                >
                  {resumeData.profile.introductory_title} |{" "}
                </Text>
              )}
              {resumeData?.profile?.key_attribute && (
                <Text
                  style={mapTailwindToStyles(
                    resumeData?.profile?.key_attribute_style
                  )}
                >
                  {resumeData.profile.key_attribute} |{" "}
                </Text>
              )}
              {resumeData?.profile?.email && (
                <Text
                  style={mapTailwindToStyles(resumeData?.profile?.email_style)}
                >
                  {resumeData.profile.email} |{" "}
                </Text>
              )}
              {resumeData?.profile?.address && (
                <Text
                  style={mapTailwindToStyles(
                    resumeData?.profile?.address_style
                  )}
                >
                  {resumeData.profile.address} |{" "}
                </Text>
              )}
              {resumeData?.profile?.phone && (
                <Text
                  style={mapTailwindToStyles(resumeData?.profile?.phone_style)}
                >
                  {resumeData.profile.phone} |{" "}
                </Text>
              )}
              {resumeData?.profile?.linked_in && (
                <Text
                  style={mapTailwindToStyles(
                    resumeData?.profile?.linked_in_style
                  )}
                >
                  {resumeData.profile.linked_in}
                </Text>
              )}
            </Text>
          </View>
        ),
      },
      {
        key: "professionalsummary",
        component: resumeData?.professionalSummaries?.map((item, index) => (
          <Text
            key={index}
            style={styles.itemDescription}
            className={`downloadedResume cv-item-date apply-font ${
              getSingleResumeDoc?.font_style &&
              getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
            }`}
          >
            {item?.text?.replace(/<\/?[^>]+(>|$)/g, "")}
            {/* {parseHtml(item?.text)} */}
          </Text>
        )),
      },
      {
        key: "certification",
        component: (
          <View style={styles.List}>
            {resumeData?.certificates?.map((certificate, index) => (
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  ...styles.listItem,
                }}
                key={index}
              >
                • {certificate.name}
              </Text>
            ))}
          </View>
        ),
      },
      {
        key: "skills",
        component: (
          <View style={styles.List}>
            {resumeData?.categories?.map((category, index) => {
              if (category.resume_id) {
                return category?.skills?.map((skill, skillIndex) => (
                  <Text
                    style={{
                      fontFamily: getSingleResumeDoc?.font_style,
                      ...styles.listItem,
                    }}
                    key={skillIndex}
                  >
                    • {skill?.name}
                  </Text>
                ));
              } else {
                return (
                  <Text
                    style={{
                      fontFamily: getSingleResumeDoc?.font_style,
                      ...styles.listItem,
                    }}
                    key={index}
                  >
                    • {category?.name}
                  </Text>
                );
              }
            })}
          </View>
        ),
      },
      {
        key: "workexperience",
        component: resumeData?.workExperience?.map((experience, index) => (
          <View
            key={index}
            className={`${
              index !== resumeData?.workExperience?.length - 1
                ? "mb-4 d-block"
                : ""
            }`}
          >
            <View style={styles.oneLine}>
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  color: getSingleResumeDoc?.subheading_color || "black",
                  ...styles.itemTitle,
                }}
              >
                {experience?.name}
              </Text>
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  ...styles.itemDate,
                }}
              >
                {formatDate(experience?.started_at)} -{" "}
                {experience?.ended_at == null
                  ? "present"
                  : formatDate(experience?.ended_at)}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: getSingleResumeDoc?.font_style,
                ...styles.itemDate,
              }}
            >
              {experience?.role_name}
            </Text>
            <Text
              className={`downloadedResume cv-item-date apply-font ${
                getSingleResumeDoc?.font_style &&
                getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
              }`}
              style={{
                fontFamily: getSingleResumeDoc?.font_style,
                ...styles.itemDescription,
              }}
            >
              {/* <p
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  ...styles.itemDescription,
                }}
                dangerouslySetInnerHTML={createMarkup(experience?.description)}
              /> */}
              {experience?.description
                ?.replace(/<\/?p>/g, "")
                ?.replace(/<br\s*[\/]?>/gi, "")}
            </Text>
          </View>
        )),
      },
      {
        key: "education",
        component: resumeData?.educations?.map((education, index) => (
          <View
            key={index}
            className={`${
              index !== resumeData?.educations?.length - 1 ? "mb-4 d-block" : ""
            }`}
          >
            <View style={styles.oneLine}>
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  color: getSingleResumeDoc?.subheading_color || "black",
                  ...styles.itemTitle,
                }}
              >
                {education.school_name}
              </Text>
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  ...styles.itemDate,
                }}
              >
                {education?.ended_at == null
                  ? "present"
                  : formatDate(education?.ended_at)}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: getSingleResumeDoc?.font_style,
                ...styles.itemDate,
              }}
            >
              {education.degree && `${education.degree} Degree | `}
              {education?.field}
            </Text>
            <Text
              className={`downloadedResume cv-item-date apply-font ${
                getSingleResumeDoc?.font_style &&
                getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
              }`}
              style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
            >
              {/* <p
                style={styles.itemDescription}
                dangerouslySetInnerHTML={createMarkup(
                  education.additional_information
                )}
              /> */}
              {education?.additional_information
                ?.replace(/<\/?p>/g, "")
                ?.replace(/<br\s*[\/]?>/gi, "")}
            </Text>
          </View>
        )),
      },
      {
        key: "project",
        component: resumeData?.projects?.map((pro, index) => (
          <View
            key={index}
            className={`${
              index !== resumeData?.projects?.length - 1 ? "mb-4 d-block" : ""
            }`}
          >
            <View style={styles.oneLine}>
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  color: getSingleResumeDoc?.subheading_color || "black",
                  ...styles.itemTitle,
                }}
              >
                {pro?.project_name}
              </Text>
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  ...styles.itemDate,
                }}
              >
                {formatDate(pro?.start_date)} - {formatDate(pro?.end_date)}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: getSingleResumeDoc?.font_style,
                ...styles.itemDate,
              }}
            >
              {pro?.organization}
            </Text>

            <Text
              className={`downloadedResume cv-item-date apply-font ${
                getSingleResumeDoc?.font_style &&
                getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
              }`}
              style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
            >
              {pro?.additional_information
                ?.replace(/<\/?p>/g, "")
                ?.replace(/<br\s*[\/]?>/gi, "")}
              {/* <p
                style={styles.itemDescription}
                dangerouslySetInnerHTML={createMarkup(
                  pro?.additional_information
                )}
              /> */}
            </Text>
          </View>
        )),
      },
      {
        key: "publications",
        component: resumeData?.publications?.map((publication, index) => (
          <View key={index}>
            <View style={styles.oneLine}>
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  color: getSingleResumeDoc?.subheading_color || "black",
                  ...styles.itemTitle,
                }}
              >
                {publication.title}
              </Text>
              <Text
                style={{
                  fontFamily: getSingleResumeDoc?.font_style,
                  ...styles.itemDescription,
                }}
              >
                {formatDate(publication?.date)}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: getSingleResumeDoc?.font_style,
                ...styles.itemDate,
              }}
            >
              {publication.publisher}
            </Text>
          </View>
        )),
      },
      {
        key: "interests",
        component: (
          <View style={styles.List}>
            {resumeData?.interests?.interests?.map((interest, index) => (
              <Text style={styles.listItem} key={index}>
                • {interest}
              </Text>
            ))}
          </View>
        ),
      },
      {
        key: "skill",
        component: (
          <View style={styles.List}>
            {resumeData?.softskills?.skills?.map((skill, index) => (
              <Text style={styles.listItem} key={index}>
                • {skill}
              </Text>
            ))}
          </View>
        ),
      },
      {
        key: "language",
        component: (
          <View style={styles.List}>
            {resumeData?.languages?.languages?.map((language, index) => (
              <Text style={styles.listItem} key={index}>
                • {language}
              </Text>
            ))}
          </View>
        ),
      },
    ],
    [resumeData]
  );
  // Register the selected font dynamically

  const updatedSections = useMemo(() => {
    if (!getSingleResumeDoc?.positions) return [];

    return getSingleResumeDoc.positions
      ?.filter((position) => !position.isDeleted)
      ?.map((position) => {
        if (!position.additional) {
          const matchingComponent = sectionComponents.find(
            (comp) => comp.key === position.key
          );
          return matchingComponent
            ? { ...position, component: matchingComponent.component }
            : position;
        }

        const matchingAdditional = getSingleResumeDoc.additional_sections?.find(
          (item) => item.identity_key === position.key
        );

        return matchingAdditional
          ? {
              ...position,
              component: (
                <PdfAdditionalSection
                  sections={matchingAdditional}
                  getSingleResumeDoc={getSingleResumeDoc}
                />
              ),
            }
          : position;
      })
      .sort((a, b) => a.position - b.position);
  }, [getSingleResumeDoc, sectionComponents]);

  useEffect(() => {
    setSectionConfigs(updatedSections);
  }, [updatedSections, setSectionConfigs]);
  useEffect(() => {
    if (getSingleResumeDoc?.font_style) {
      const selectedFont = fonts?.find(
        (font) =>
          font?.name?.toLowerCase() ===
          getSingleResumeDoc?.font_style?.toLowerCase()
      );

      if (selectedFont) {
        console.log("Registering font:", selectedFont.name);
        Font.register({
          family: selectedFont.name,
          src: selectedFont.src,
          fontWeight: selectedFont?.fontWeight || 700, // Default to 400 if not specified
          fontStyle: selectedFont?.fontStyle || "normal", // Default to normal
        });
      }
    } else {
      console.log("Registering default font: DM Sans");
      Font.register({
        family: "DM Sans",
        src: DmSans,
        fontWeight: 700, // Default to 400 if not specified
        fontStyle: "normal", // Default to normal
      });
    }
  }, [getSingleResumeDoc]);

  return (
    <Document>
      <Page
        size="A4"
        style={{
          ...styles.mainContainer,
        }}
      >
        {sectionsConfig?.map((section, index) => {
          return (
            <View className="py-1 px-2 w-full" key={index}>
              <View className="flex item-center">
                {section?.title !== "Personal Information" && (
                  <Text
                    className="cv-section-title apply-font"
                    style={{
                      ...mapTailwindToStyles(section?.style),
                      color: getSingleResumeDoc?.heading_color || "#000",
                      fontSize: getSingleResumeDoc?.font_size || "16px",

                      borderBottom: `1px solid ${
                        getSingleResumeDoc?.heading_color || "#000"
                      }`,
                      fontFamily: getSingleResumeDoc?.font_style,
                      ...styles.sectionTitle,
                    }}
                  >
                    {section.title}
                  </Text>
                )}
              </View>
              {section.component}
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export default PdfTemplate;
