import React, { useState } from "react";
import {
  addStep,
  skipStep,
} from "../../features/companyinformation/StepsSlice";
import { GenerateResIcon } from "../../Components/Resume/svgimages";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { generateAIResume } from "../../Redux/ResumeBuilderSlice";
import { useParams } from "react-router-dom";
import { error, success } from "../../Utilities/toast";

const EnhanceResume = ({ setSelectedResume, setShow }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getSingleResumeDoc } = useSelector((state) => state.ResumeBuilder);
  const enhanceOptions = [
    "professionalsummary",
    "skills",
    "workexperience",
    "project",
    "skill",
  ];
  const descriptions = {
    professionalsummary:
      "A brief summary highlighting your professional achievements.",
    skills: "A list of skills that showcase your expertise.",
    workexperience:
      "Details about your previous job roles and responsibilities.",
    project: "An overview of the projects you’ve worked on.",
    skill: "Key individual abilities relevant to the job.",
  };
  const [selectedKeys, setSelectedKeys] = useState(
    enhanceOptions.reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {})
  );

  const handleToggle = (key) => {
    setSelectedKeys((prev) => {
      const updated = { ...prev, [key]: !prev[key] };
      return updated;
    });
  };

  const selectedArray = Object.entries(selectedKeys)
    .filter(([_, value]) => value)
    .map(([key]) => key);

  const getResumeData = async () => {
    dispatch(skipStep(false));
    dispatch(addStep(3));
    dispatch(
      generateAIResume({
        resumeId: id,
        payload: selectedArray,
        onSuccess: () => {
          success("Resume generated successfully");
          setShow(true);
        },
        onError: (res) => {
          error(res);
          setShow(false);
        },
      })
    );
  };
  return (
    <div className="companyinformation">
      <div className="flex items-center justify-between pb-7">
        <p className="text-[18px] font-bold text-[#445164]">
          Select What Aspects Of Your Resume You Would Like Nexa To Enhance
        </p>
        <div className="flex">
          <button
            type="button"
            className="text-[14px] font-bold w-[60px] h-[40px] flex justify-center items-center gap-3 cancel-btn"
            onClick={() => {
              dispatch(addStep(2));
            }}
          >
            <FaLongArrowAltLeft size={18} />
            Previous
          </button>
          <button
            type="button"
            className="text-[14px] font-bold w-[60px] h-[40px] flex justify-center items-center gap-3 cancel-btn mx-1"
            onClick={() => {
              dispatch(addStep(4));
              setShow(true);
              setSelectedResume("BEFORE");
              dispatch(skipStep(true));
            }}
          >
            Skip <FaLongArrowAltRight size={18} />
          </button>
        </div>
      </div>
      <div className="bg-white p-2 sm:p-4 rounded-xl shadow-lg">
        {getSingleResumeDoc?.positions
          .filter((position) => !position.isDeleted)
          .filter(({ key }) =>
            enhanceOptions.some((route) => key.includes(route))
          )
          .map((position, index) => (
            <div
              key={index}
              className="flex flex-col py-2 px-4 border-b last:border-b-0"
            >
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-gray-800 capitalize text-base font-semibold">
                    {position.title}
                  </span>
                  <p className="text-gray-500 text-sm mt-1">
                    {descriptions[position.key] || "No description available."}
                  </p>
                </div>
                <label className="inline-flex items-center justify-center h-full cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedKeys[position.key]}
                    onChange={() => handleToggle(position.key)}
                    className="sr-only peer"
                  />
                  <div
                    className={`relative w-11 h-6 rounded-full transition-all ${
                      selectedKeys[position.key] ? "bg-blue-600" : "bg-gray-200"
                    } peer-focus:outline-none peer-focus:ring-4 ${
                      selectedKeys[position.key]
                        ? "peer-focus:ring-blue-300"
                        : "peer-focus:ring-gray-300"
                    }`}
                  >
                    <span
                      className={`absolute top-[2px] left-[2px] h-5 w-5 bg-white border border-gray-300 rounded-full transition-transform ${
                        selectedKeys[position.key]
                          ? "translate-x-full border-white"
                          : "translate-x-0"
                      }`}
                    />
                  </div>
                </label>
              </div>
            </div>
          ))}
        <div className="w-full flex justify-end mt-4">
          <button
            className={`${
              selectedArray.length === 0
                ? "cursor-not-allowed bg-gray-400 border-gray-400"
                : ""
            } flex items-center py-2 gap-2 px-4 rounded-lg border-2 border-[#2B6BE2] bg-[#2B6BE2] text-white cursor-pointer`}
            onClick={getResumeData}
            disabled={selectedArray.length === 0}
          >
            <GenerateResIcon size={20} />
            Generate Resume
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnhanceResume;
