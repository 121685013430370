import React, { useState } from "react";
import ReusableSection from "./ReusableSection";
import "./Section7.css";
const sectionData = [
  {
    imageUrl: "images/Bootcamp/BootCampSection71.png",
    text1: "Software Development",
    text2:
      "Equip learners with in-demand coding skills through hands-on tools like Nexa Octagon for project simulations, live coding interview prep, and real-time resume building.",
    imagePosition: "right",
  },

  {
    imageUrl: "images/Bootcamp/BootCampSection72.png",
    text1: "Cybersecurity",
    text2:
      "Empower students to master certifications (e.g., CompTIA, CISSP) and practice incident response, penetration testing, and risk assessment with Nexa’s skill practice and job simulation tools",
    imagePosition: "right",
  },

  {
    imageUrl: "images/Bootcamp/BootCampSection73.png",
    text1: "Tech Sales",
    text2:
      "Help learners ace the art of sales with personalized role-play simulations, CRM practice (e.g., Salesforce, HubSpot), and soft skill development tools.",
    imagePosition: "right",
  },

  {
    imageUrl: "images/Bootcamp/BootCampSection74.png",
    text1: "UI/UX Design",
    text2:
      "Provide your learners with industry-leading tools to excel in wireframing, prototyping, and usability testing, while building design portfolios employers love.",
    imagePosition: "right",
  },

  {
    imageUrl: "images/Bootcamp/BootCampSection75.png",
    text1: "Digital Marketing",
    text2:
      "Enable learners to excel in SEO, social media strategy, PPC campaigns, influencer marketing, and analytics tools like Google Ads and HubSpot. Nexa provides tailored practice simulations and real-world project experience.",
    imagePosition: "right",
  },

  {
    imageUrl: "images/Bootcamp/BootCampSection76.png",
    text1: "General Career Paths",
    text2:
      "From healthcare to finance, Nexa supports learners in building industry-specific skills, with job simulations, tailored resume building, and AI-driven job matching.",
    imagePosition: "right",
  },
];

const Section7 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sectionData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sectionData.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="outermost-container-section7">
      <div className="default-container-section7">
        <div className="text-wrapper-section7">
          <div style={{ marginTop: "90px" }} className="narrow-text-section7">
            <p className="text-default-blue-bold-section7 text-center mb-8 FiraFontFamily bold-family">
              Empower Any{" "}
              <span className="text-default-blue-bold-section7 highlighted-word FiraFontFamily bold-family">
                Career
              </span>{" "}
              Path with Nexa Elevate
            </p>
          </div>
          <div className="wide-text-section7">
            <p className="text-default-black-section7 text-center FiraFontFamily">
              A Perfect Fit for Every Type of Skill-Based Training help your
              learners thrive no matter what career path they’re pursuing. Nexa
              is purpose-built to support the unique needs of every career
              discipline.
            </p>
          </div>
        </div>
      </div>

      {/* Slider Section */}
      <div className="slider-section7">
        <img
          src="images/icons/left-arrow.svg"
          alt="Left Arrow"
          className="left-arrow-section-7"
          onClick={handlePrev}
        />
        <div
          className="reusable-container-section7"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {sectionData.map((data, index) => (
            <div className="slide" key={index}>
              <ReusableSection
                text1={data.text1}
                text2={data.text2}
                imageUrl={data.imageUrl}
                imagePosition={data.imagePosition}
                noPadding={true}
              />
            </div>
          ))}
        </div>
        <img
          src="images/icons/right-arrow.svg"
          alt="Right Arrow"
          className="right-arrow-section-7"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};
export default Section7;
